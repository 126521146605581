<template>
  <div class="apply">
    <div class="top">
      <div class="title">商务合作报名信息表</div>
    </div>
    <el-form ref="form" :model="form" label-width="110px" class="form">
      <el-form-item label="业务类型">
        <el-select class="item-width" v-model="form.type" placeholder="请选择">
          <el-option label="所有" value="0"></el-option>
          <el-option label="维修" value="1"></el-option>
          <el-option label="租赁" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input
          class="item-width"
          v-model="form.contact_name"
          placeholder="请输入联系人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人方式">
        <el-input
          class="item-width"
          v-model="form.mobile"
          placeholder="联系人方式"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input
          class="item-width"
          v-model="form.company_name"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司地址">
        <el-input
          class="item-width"
          v-model="form.address"
          placeholder="请输入公司地址"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="submit" type="primary" @click="onSubmit">
          提交
        </el-button>
        <el-button class="reset" @click="resetForm">重置 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "apply",
  data() {
    return {
      form: {
        type: "",
        contact_name: "",
        mobile: "",
        address: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log(`this.form`, this.form);
    },
    resetForm() {
      this.form = {
        type: "",
        engineer_num: "",
        device_num: "",
        contact_name: "",
        mobile: "",
        address: "",
      };
    },
  },
};
</script>

<style scoped>
.apply {
  width: 100%;
  margin: 120px auto;
}
.top {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.form {
  width: 700px;
  margin: 80px auto;
}
.item-width {
  width: 560px;
}
.submit {
  width: 157px;
  height: 52px;
  background: #1F5FFF;
  border-radius: 4px;
}
.reset {
  width: 157px;
  height: 52px;
  background: #f8f8f8;
  border-radius: 4px;
}

/deep/ .el-button--primary {
  border-color: #1F5FFF;
}
</style>
