<template>
    <div style="overflow-x: hidden">
        <img style="width:99vw;display: block;font-size: 0" v-for="(item,index) in imglist" :key="index" :src="item">
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return{
                imglist:[
                    require('@/assets/main/about.png'),
                    require('@/assets/main/a1.jpg'),
                    require('@/assets/main/a2.jpg'),
                    require('@/assets/main/a3.jpg'),
                    require('@/assets/main/a4.png')

                ]
            }
        }
    }
</script>

<style scoped>

</style>
