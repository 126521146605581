<template>
  <div class="teamwork">
    <div class="title">
      合作流程
    </div>
    <img class="teamwork-img" src="../../assets/serve/teamwork.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "teamwork",
};
</script>

<style scoped>
.teamwork {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.teamwork-img {
  width: 1920px;
  height: 691px;
  display: block;
  font-size: 0;
}
.title {
  position: absolute;
  top: 80px;
  left: 880px;
  width: 163px;
  height: 41px;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #333333;
}
</style>
