const area = [{
        value: 11,

        label: "北京",

        children: [{
            value: 110000,

            label: "北京市",

            children: [{
                    value: 110101,

                    label: "东城区",
                },

                {
                    value: 110102,

                    label: "西城区",
                },

                {
                    value: 110105,

                    label: "朝阳区",
                },

                {
                    value: 110106,

                    label: "丰台区",
                },

                {
                    value: 110107,

                    label: "石景山区",
                },

                {
                    value: 110108,

                    label: "海淀区",
                },

                {
                    value: 110109,

                    label: "门头沟区",
                },

                {
                    value: 110111,

                    label: "房山区",
                },

                {
                    value: 110112,

                    label: "通州区",
                },

                {
                    value: 110113,

                    label: "顺义区",
                },

                {
                    value: 110114,

                    label: "昌平区",
                },

                {
                    value: 110115,

                    label: "大兴区",
                },

                {
                    value: 110116,

                    label: "怀柔区",
                },

                {
                    value: 110117,

                    label: "平谷区",
                },

                {
                    value: 110118,

                    label: "密云区",
                },

                {
                    value: 110119,

                    label: "延庆区",
                },
            ],
        }, ],
    },

    {
        value: 12,

        label: "天津",

        children: [{
            value: 120000,

            label: "天津市",

            children: [{
                    value: 120101,

                    label: "和平区",
                },

                {
                    value: 120102,

                    label: "河东区",
                },

                {
                    value: 120103,

                    label: "河西区",
                },

                {
                    value: 120104,

                    label: "南开区",
                },

                {
                    value: 120105,

                    label: "河北区",
                },

                {
                    value: 120106,

                    label: "红桥区",
                },

                {
                    value: 120110,

                    label: "东丽区",
                },

                {
                    value: 120111,

                    label: "西青区",
                },

                {
                    value: 120112,

                    label: "津南区",
                },

                {
                    value: 120113,

                    label: "北辰区",
                },

                {
                    value: 120114,

                    label: "武清区",
                },

                {
                    value: 120115,

                    label: "宝坻区",
                },

                {
                    value: 120116,

                    label: "滨海新区",
                },

                {
                    value: 120117,

                    label: "宁河区",
                },

                {
                    value: 120118,

                    label: "静海区",
                },

                {
                    value: 120119,

                    label: "蓟州区",
                },
            ],
        }, ],
    },

    {
        value: 31,

        label: "上海",

        children: [{
            value: 310000,

            label: "上海市",

            children: [{
                    value: 310101,

                    label: "黄浦区",
                },

                {
                    value: 310104,

                    label: "徐汇区",
                },

                {
                    value: 310105,

                    label: "长宁区",
                },

                {
                    value: 310106,

                    label: "静安区",
                },

                {
                    value: 310107,

                    label: "普陀区",
                },

                {
                    value: 310109,

                    label: "虹口区",
                },

                {
                    value: 310110,

                    label: "杨浦区",
                },

                {
                    value: 310112,

                    label: "闵行区",
                },

                {
                    value: 310113,

                    label: "宝山区",
                },

                {
                    value: 310114,

                    label: "嘉定区",
                },

                {
                    value: 310115,

                    label: "浦东新区",
                },

                {
                    value: 310116,

                    label: "金山区",
                },

                {
                    value: 310117,

                    label: "松江区",
                },

                {
                    value: 310118,

                    label: "青浦区",
                },

                {
                    value: 310120,

                    label: "奉贤区",
                },

                {
                    value: 310151,

                    label: "崇明区",
                },
            ],
        }, ],
    },

    {
        value: 50,

        label: "重庆",

        children: [{
            value: 500000,

            label: "重庆市",

            children: [{
                    value: 500101,

                    label: "万州区",
                },

                {
                    value: 500102,

                    label: "涪陵区",
                },

                {
                    value: 500103,

                    label: "渝中区",
                },

                {
                    value: 500104,

                    label: "大渡口区",
                },

                {
                    value: 500105,

                    label: "江北区",
                },

                {
                    value: 500106,

                    label: "沙坪坝区",
                },

                {
                    value: 500107,

                    label: "九龙坡区",
                },

                {
                    value: 500108,

                    label: "南岸区",
                },

                {
                    value: 500109,

                    label: "北碚区",
                },

                {
                    value: 500110,

                    label: "綦江区",
                },

                {
                    value: 500111,

                    label: "大足区",
                },

                {
                    value: 500112,

                    label: "渝北区",
                },

                {
                    value: 500113,

                    label: "巴南区",
                },

                {
                    value: 500114,

                    label: "黔江区",
                },

                {
                    value: 500115,

                    label: "长寿区",
                },

                {
                    value: 500116,

                    label: "江津区",
                },

                {
                    value: 500117,

                    label: "合川区",
                },

                {
                    value: 500118,

                    label: "永川区",
                },

                {
                    value: 500119,

                    label: "南川区",
                },

                {
                    value: 500120,

                    label: "璧山区",
                },

                {
                    value: 500151,

                    label: "铜梁区",
                },

                {
                    value: 500152,

                    label: "潼南区",
                },

                {
                    value: 500153,

                    label: "荣昌区",
                },

                {
                    value: 500154,

                    label: "开州区",
                },

                {
                    value: 500155,

                    label: "梁平区",
                },

                {
                    value: 500156,

                    label: "武隆区",
                },

                {
                    value: 500229,

                    label: "城口县",
                },

                {
                    value: 500230,

                    label: "丰都县",
                },

                {
                    value: 500231,

                    label: "垫江县",
                },

                {
                    value: 500233,

                    label: "忠县",
                },

                {
                    value: 500235,

                    label: "云阳县",
                },

                {
                    value: 500236,

                    label: "奉节县",
                },

                {
                    value: 500237,

                    label: "巫山县",
                },

                {
                    value: 500238,

                    label: "巫溪县",
                },

                {
                    value: 500240,

                    label: "石柱土家族自治县",
                },

                {
                    value: 500241,

                    label: "秀山土家族苗族自治县",
                },

                {
                    value: 500242,

                    label: "酉阳土家族苗族自治县",
                },

                {
                    value: 500243,

                    label: "彭水苗族土家族自治县",
                },

                {
                    value: 500256,

                    label: "万盛区",
                },

                {
                    value: 500257,

                    label: "开州区",
                },

                {
                    value: 500258,

                    label: "北部新区",
                },

                {
                    value: 500259,

                    label: "两江新区",
                },

                {
                    value: 500260,

                    label: "高新区",
                },
            ],
        }, ],
    },

    {
        value: 130000,

        label: "河北省",

        children: [{
                value: 130100,

                label: "石家庄市",

                children: [{
                        value: 130102,

                        label: "长安区",
                    },

                    {
                        value: 130104,

                        label: "桥西区",
                    },

                    {
                        value: 130105,

                        label: "新华区",
                    },

                    {
                        value: 130107,

                        label: "井陉矿区",
                    },

                    {
                        value: 130108,

                        label: "裕华区",
                    },

                    {
                        value: 130109,

                        label: "藁城区",
                    },

                    {
                        value: 130110,

                        label: "鹿泉区",
                    },

                    {
                        value: 130111,

                        label: "栾城区",
                    },

                    {
                        value: 130121,

                        label: "井陉县",
                    },

                    {
                        value: 130123,

                        label: "正定县",
                    },

                    {
                        value: 130125,

                        label: "行唐县",
                    },

                    {
                        value: 130126,

                        label: "灵寿县",
                    },

                    {
                        value: 130127,

                        label: "高邑县",
                    },

                    {
                        value: 130128,

                        label: "深泽县",
                    },

                    {
                        value: 130129,

                        label: "赞皇县",
                    },

                    {
                        value: 130130,

                        label: "无极县",
                    },

                    {
                        value: 130131,

                        label: "平山县",
                    },

                    {
                        value: 130132,

                        label: "元氏县",
                    },

                    {
                        value: 130133,

                        label: "赵县",
                    },

                    {
                        value: 130181,

                        label: "辛集市",
                    },

                    {
                        value: 130183,

                        label: "晋州市",
                    },

                    {
                        value: 130184,

                        label: "新乐市",
                    },
                ],
            },

            {
                value: 130200,

                label: "唐山市",

                children: [{
                        value: 130202,

                        label: "路南区",
                    },

                    {
                        value: 130203,

                        label: "路北区",
                    },

                    {
                        value: 130204,

                        label: "古冶区",
                    },

                    {
                        value: 130205,

                        label: "开平区",
                    },

                    {
                        value: 130207,

                        label: "丰南区",
                    },

                    {
                        value: 130208,

                        label: "丰润区",
                    },

                    {
                        value: 130209,

                        label: "曹妃甸区",
                    },

                    {
                        value: 130224,

                        label: "滦南县",
                    },

                    {
                        value: 130225,

                        label: "乐亭县",
                    },

                    {
                        value: 130227,

                        label: "迁西县",
                    },

                    {
                        value: 130229,

                        label: "玉田县",
                    },

                    {
                        value: 130230,

                        label: "芦台区",
                    },

                    {
                        value: 130281,

                        label: "遵化市",
                    },

                    {
                        value: 130283,

                        label: "迁安市",
                    },

                    {
                        value: 130284,

                        label: "滦州市",
                    },
                ],
            },

            {
                value: 130300,

                label: "秦皇岛市",

                children: [{
                        value: 130302,

                        label: "海港区",
                    },

                    {
                        value: 130303,

                        label: "山海关区",
                    },

                    {
                        value: 130304,

                        label: "北戴河区",
                    },

                    {
                        value: 130306,

                        label: "抚宁区",
                    },

                    {
                        value: 130321,

                        label: "青龙满族自治县",
                    },

                    {
                        value: 130322,

                        label: "昌黎县",
                    },

                    {
                        value: 130324,

                        label: "卢龙县",
                    },
                ],
            },

            {
                value: 130400,

                label: "邯郸市",

                children: [{
                        value: 130402,

                        label: "邯山区",
                    },

                    {
                        value: 130403,

                        label: "丛台区",
                    },

                    {
                        value: 130404,

                        label: "复兴区",
                    },

                    {
                        value: 130406,

                        label: "峰峰矿区",
                    },

                    {
                        value: 130407,

                        label: "肥乡区",
                    },

                    {
                        value: 130408,

                        label: "永年区",
                    },

                    {
                        value: 130423,

                        label: "临漳县",
                    },

                    {
                        value: 130424,

                        label: "成安县",
                    },

                    {
                        value: 130425,

                        label: "大名县",
                    },

                    {
                        value: 130426,

                        label: "涉县",
                    },

                    {
                        value: 130427,

                        label: "磁县",
                    },

                    {
                        value: 130430,

                        label: "邱县",
                    },

                    {
                        value: 130431,

                        label: "鸡泽县",
                    },

                    {
                        value: 130432,

                        label: "广平县",
                    },

                    {
                        value: 130433,

                        label: "馆陶县",
                    },

                    {
                        value: 130434,

                        label: "魏县",
                    },

                    {
                        value: 130435,

                        label: "曲周县",
                    },

                    {
                        value: 130481,

                        label: "武安市",
                    },
                ],
            },

            {
                value: 130500,

                label: "邢台市",

                children: [{
                        value: 130502,

                        label: "桥东区",
                    },

                    {
                        value: 130503,

                        label: "桥西区",
                    },

                    {
                        value: 130521,

                        label: "邢台县",
                    },

                    {
                        value: 130522,

                        label: "临城县",
                    },

                    {
                        value: 130523,

                        label: "内丘县",
                    },

                    {
                        value: 130524,

                        label: "柏乡县",
                    },

                    {
                        value: 130525,

                        label: "隆尧县",
                    },

                    {
                        value: 130526,

                        label: "任县",
                    },

                    {
                        value: 130527,

                        label: "南和县",
                    },

                    {
                        value: 130528,

                        label: "宁晋县",
                    },

                    {
                        value: 130529,

                        label: "巨鹿县",
                    },

                    {
                        value: 130530,

                        label: "新河县",
                    },

                    {
                        value: 130531,

                        label: "广宗县",
                    },

                    {
                        value: 130532,

                        label: "平乡县",
                    },

                    {
                        value: 130533,

                        label: "威县",
                    },

                    {
                        value: 130534,

                        label: "清河县",
                    },

                    {
                        value: 130535,

                        label: "临西县",
                    },

                    {
                        value: 130581,

                        label: "南宫市",
                    },

                    {
                        value: 130582,

                        label: "沙河市",
                    },
                ],
            },

            {
                value: 130600,

                label: "保定市",

                children: [{
                        value: 130602,

                        label: "竞秀区",
                    },

                    {
                        value: 130606,

                        label: "莲池区",
                    },

                    {
                        value: 130607,

                        label: "满城区",
                    },

                    {
                        value: 130608,

                        label: "清苑区",
                    },

                    {
                        value: 130609,

                        label: "徐水区",
                    },

                    {
                        value: 130623,

                        label: "涞水县",
                    },

                    {
                        value: 130624,

                        label: "阜平县",
                    },

                    {
                        value: 130626,

                        label: "定兴县",
                    },

                    {
                        value: 130627,

                        label: "唐县",
                    },

                    {
                        value: 130628,

                        label: "高阳县",
                    },

                    {
                        value: 130629,

                        label: "容城县",
                    },

                    {
                        value: 130630,

                        label: "涞源县",
                    },

                    {
                        value: 130631,

                        label: "望都县",
                    },

                    {
                        value: 130632,

                        label: "安新县",
                    },

                    {
                        value: 130633,

                        label: "易县",
                    },

                    {
                        value: 130634,

                        label: "曲阳县",
                    },

                    {
                        value: 130635,

                        label: "蠡县",
                    },

                    {
                        value: 130636,

                        label: "顺平县",
                    },

                    {
                        value: 130637,

                        label: "博野县",
                    },

                    {
                        value: 130638,

                        label: "雄县",
                    },

                    {
                        value: 130681,

                        label: "涿州市",
                    },

                    {
                        value: 130682,

                        label: "定州市",
                    },

                    {
                        value: 130683,

                        label: "安国市",
                    },

                    {
                        value: 130684,

                        label: "高碑店市",
                    },
                ],
            },

            {
                value: 130700,

                label: "张家口市",

                children: [{
                        value: 130702,

                        label: "桥东区",
                    },

                    {
                        value: 130703,

                        label: "桥西区",
                    },

                    {
                        value: 130705,

                        label: "宣化区",
                    },

                    {
                        value: 130706,

                        label: "下花园区",
                    },

                    {
                        value: 130708,

                        label: "万全区",
                    },

                    {
                        value: 130709,

                        label: "崇礼区",
                    },

                    {
                        value: 130722,

                        label: "张北县",
                    },

                    {
                        value: 130723,

                        label: "康保县",
                    },

                    {
                        value: 130724,

                        label: "沽源县",
                    },

                    {
                        value: 130725,

                        label: "尚义县",
                    },

                    {
                        value: 130726,

                        label: "蔚县",
                    },

                    {
                        value: 130727,

                        label: "阳原县",
                    },

                    {
                        value: 130728,

                        label: "怀安县",
                    },

                    {
                        value: 130730,

                        label: "怀来县",
                    },

                    {
                        value: 130731,

                        label: "涿鹿县",
                    },

                    {
                        value: 130732,

                        label: "赤城县",
                    },
                ],
            },

            {
                value: 130800,

                label: "承德市",

                children: [{
                        value: 130802,

                        label: "双桥区",
                    },

                    {
                        value: 130803,

                        label: "双滦区",
                    },

                    {
                        value: 130804,

                        label: "鹰手营子矿区",
                    },

                    {
                        value: 130821,

                        label: "承德县",
                    },

                    {
                        value: 130822,

                        label: "兴隆县",
                    },

                    {
                        value: 130824,

                        label: "滦平县",
                    },

                    {
                        value: 130825,

                        label: "隆化县",
                    },

                    {
                        value: 130826,

                        label: "丰宁满族自治县",
                    },

                    {
                        value: 130827,

                        label: "宽城满族自治县",
                    },

                    {
                        value: 130828,

                        label: "围场满族蒙古族自治县",
                    },

                    {
                        value: 130881,

                        label: "平泉市",
                    },
                ],
            },

            {
                value: 130900,

                label: "沧州市",

                children: [{
                        value: 130902,

                        label: "新华区",
                    },

                    {
                        value: 130903,

                        label: "运河区",
                    },

                    {
                        value: 130921,

                        label: "沧县",
                    },

                    {
                        value: 130922,

                        label: "青县",
                    },

                    {
                        value: 130923,

                        label: "东光县",
                    },

                    {
                        value: 130924,

                        label: "海兴县",
                    },

                    {
                        value: 130925,

                        label: "盐山县",
                    },

                    {
                        value: 130926,

                        label: "肃宁县",
                    },

                    {
                        value: 130927,

                        label: "南皮县",
                    },

                    {
                        value: 130928,

                        label: "吴桥县",
                    },

                    {
                        value: 130929,

                        label: "献县",
                    },

                    {
                        value: 130930,

                        label: "孟村回族自治县",
                    },

                    {
                        value: 130981,

                        label: "泊头市",
                    },

                    {
                        value: 130982,

                        label: "任丘市",
                    },

                    {
                        value: 130983,

                        label: "黄骅市",
                    },

                    {
                        value: 130984,

                        label: "河间市",
                    },
                ],
            },

            {
                value: 131000,

                label: "廊坊市",

                children: [{
                        value: 131002,

                        label: "安次区",
                    },

                    {
                        value: 131003,

                        label: "广阳区",
                    },

                    {
                        value: 131022,

                        label: "固安县",
                    },

                    {
                        value: 131023,

                        label: "永清县",
                    },

                    {
                        value: 131024,

                        label: "香河县",
                    },

                    {
                        value: 131025,

                        label: "大城县",
                    },

                    {
                        value: 131026,

                        label: "文安县",
                    },

                    {
                        value: 131028,

                        label: "大厂回族自治县",
                    },

                    {
                        value: 131081,

                        label: "霸州市",
                    },

                    {
                        value: 131082,

                        label: "三河市",
                    },
                ],
            },

            {
                value: 131100,

                label: "衡水市",

                children: [{
                        value: 131102,

                        label: "桃城区",
                    },

                    {
                        value: 131103,

                        label: "冀州区",
                    },

                    {
                        value: 131121,

                        label: "枣强县",
                    },

                    {
                        value: 131122,

                        label: "武邑县",
                    },

                    {
                        value: 131123,

                        label: "武强县",
                    },

                    {
                        value: 131124,

                        label: "饶阳县",
                    },

                    {
                        value: 131125,

                        label: "安平县",
                    },

                    {
                        value: 131126,

                        label: "故城县",
                    },

                    {
                        value: 131127,

                        label: "景县",
                    },

                    {
                        value: 131128,

                        label: "阜城县",
                    },

                    {
                        value: 131182,

                        label: "深州市",
                    },
                ],
            },
        ],
    },

    {
        value: 140000,

        label: "山西省",

        children: [{
                value: 140100,

                label: "太原市",

                children: [{
                        value: 140105,

                        label: "小店区",
                    },

                    {
                        value: 140106,

                        label: "迎泽区",
                    },

                    {
                        value: 140107,

                        label: "杏花岭区",
                    },

                    {
                        value: 140108,

                        label: "尖草坪区",
                    },

                    {
                        value: 140109,

                        label: "万柏林区",
                    },

                    {
                        value: 140110,

                        label: "晋源区",
                    },

                    {
                        value: 140121,

                        label: "清徐县",
                    },

                    {
                        value: 140122,

                        label: "阳曲县",
                    },

                    {
                        value: 140123,

                        label: "娄烦县",
                    },

                    {
                        value: 140181,

                        label: "古交市",
                    },
                ],
            },

            {
                value: 140200,

                label: "大同市",

                children: [{
                        value: 140212,

                        label: "新荣区",
                    },

                    {
                        value: 140213,

                        label: "平城区",
                    },

                    {
                        value: 140214,

                        label: "云冈区",
                    },

                    {
                        value: 140215,

                        label: "云州区",
                    },

                    {
                        value: 140221,

                        label: "阳高县",
                    },

                    {
                        value: 140222,

                        label: "天镇县",
                    },

                    {
                        value: 140223,

                        label: "广灵县",
                    },

                    {
                        value: 140224,

                        label: "灵丘县",
                    },

                    {
                        value: 140225,

                        label: "浑源县",
                    },

                    {
                        value: 140226,

                        label: "左云县",
                    },
                ],
            },

            {
                value: 140300,

                label: "阳泉市",

                children: [{
                        value: 140302,

                        label: "城区",
                    },

                    {
                        value: 140303,

                        label: "矿区",
                    },

                    {
                        value: 140311,

                        label: "郊区",
                    },

                    {
                        value: 140321,

                        label: "平定县",
                    },

                    {
                        value: 140322,

                        label: "盂县",
                    },
                ],
            },

            {
                value: 140400,

                label: "长治市",

                children: [{
                        value: 140403,

                        label: "潞州区",
                    },

                    {
                        value: 140404,

                        label: "上党区",
                    },

                    {
                        value: 140405,

                        label: "屯留区",
                    },

                    {
                        value: 140406,

                        label: "潞城区",
                    },

                    {
                        value: 140423,

                        label: "襄垣县",
                    },

                    {
                        value: 140425,

                        label: "平顺县",
                    },

                    {
                        value: 140426,

                        label: "黎城县",
                    },

                    {
                        value: 140427,

                        label: "壶关县",
                    },

                    {
                        value: 140428,

                        label: "长子县",
                    },

                    {
                        value: 140429,

                        label: "武乡县",
                    },

                    {
                        value: 140430,

                        label: "沁县",
                    },

                    {
                        value: 140431,

                        label: "沁源县",
                    },
                ],
            },

            {
                value: 140500,

                label: "晋城市",

                children: [{
                        value: 140502,

                        label: "城区",
                    },

                    {
                        value: 140521,

                        label: "沁水县",
                    },

                    {
                        value: 140522,

                        label: "阳城县",
                    },

                    {
                        value: 140524,

                        label: "陵川县",
                    },

                    {
                        value: 140525,

                        label: "泽州县",
                    },

                    {
                        value: 140581,

                        label: "高平市",
                    },
                ],
            },

            {
                value: 140600,

                label: "朔州市",

                children: [{
                        value: 140602,

                        label: "朔城区",
                    },

                    {
                        value: 140603,

                        label: "平鲁区",
                    },

                    {
                        value: 140621,

                        label: "山阴县",
                    },

                    {
                        value: 140622,

                        label: "应县",
                    },

                    {
                        value: 140623,

                        label: "右玉县",
                    },

                    {
                        value: 140681,

                        label: "怀仁市",
                    },
                ],
            },

            {
                value: 140700,

                label: "晋中市",

                children: [{
                        value: 140702,

                        label: "榆次区",
                    },

                    {
                        value: 140721,

                        label: "榆社县",
                    },

                    {
                        value: 140722,

                        label: "左权县",
                    },

                    {
                        value: 140723,

                        label: "和顺县",
                    },

                    {
                        value: 140724,

                        label: "昔阳县",
                    },

                    {
                        value: 140725,

                        label: "寿阳县",
                    },

                    {
                        value: 140726,

                        label: "太谷县",
                    },

                    {
                        value: 140727,

                        label: "祁县",
                    },

                    {
                        value: 140728,

                        label: "平遥县",
                    },

                    {
                        value: 140729,

                        label: "灵石县",
                    },

                    {
                        value: 140781,

                        label: "介休市",
                    },
                ],
            },

            {
                value: 140800,

                label: "运城市",

                children: [{
                        value: 140802,

                        label: "盐湖区",
                    },

                    {
                        value: 140821,

                        label: "临猗县",
                    },

                    {
                        value: 140822,

                        label: "万荣县",
                    },

                    {
                        value: 140823,

                        label: "闻喜县",
                    },

                    {
                        value: 140824,

                        label: "稷山县",
                    },

                    {
                        value: 140825,

                        label: "新绛县",
                    },

                    {
                        value: 140826,

                        label: "绛县",
                    },

                    {
                        value: 140827,

                        label: "垣曲县",
                    },

                    {
                        value: 140828,

                        label: "夏县",
                    },

                    {
                        value: 140829,

                        label: "平陆县",
                    },

                    {
                        value: 140830,

                        label: "芮城县",
                    },

                    {
                        value: 140881,

                        label: "永济市",
                    },

                    {
                        value: 140882,

                        label: "河津市",
                    },
                ],
            },

            {
                value: 140900,

                label: "忻州市",

                children: [{
                        value: 140902,

                        label: "忻府区",
                    },

                    {
                        value: 140921,

                        label: "定襄县",
                    },

                    {
                        value: 140922,

                        label: "五台县",
                    },

                    {
                        value: 140923,

                        label: "代县",
                    },

                    {
                        value: 140924,

                        label: "繁峙县",
                    },

                    {
                        value: 140925,

                        label: "宁武县",
                    },

                    {
                        value: 140926,

                        label: "静乐县",
                    },

                    {
                        value: 140927,

                        label: "神池县",
                    },

                    {
                        value: 140928,

                        label: "五寨县",
                    },

                    {
                        value: 140929,

                        label: "岢岚县",
                    },

                    {
                        value: 140930,

                        label: "河曲县",
                    },

                    {
                        value: 140931,

                        label: "保德县",
                    },

                    {
                        value: 140932,

                        label: "偏关县",
                    },

                    {
                        value: 140981,

                        label: "原平市",
                    },
                ],
            },

            {
                value: 141000,

                label: "临汾市",

                children: [{
                        value: 141002,

                        label: "尧都区",
                    },

                    {
                        value: 141021,

                        label: "曲沃县",
                    },

                    {
                        value: 141022,

                        label: "翼城县",
                    },

                    {
                        value: 141023,

                        label: "襄汾县",
                    },

                    {
                        value: 141024,

                        label: "洪洞县",
                    },

                    {
                        value: 141025,

                        label: "古县",
                    },

                    {
                        value: 141026,

                        label: "安泽县",
                    },

                    {
                        value: 141027,

                        label: "浮山县",
                    },

                    {
                        value: 141028,

                        label: "吉县",
                    },

                    {
                        value: 141029,

                        label: "乡宁县",
                    },

                    {
                        value: 141030,

                        label: "大宁县",
                    },

                    {
                        value: 141031,

                        label: "隰县",
                    },

                    {
                        value: 141032,

                        label: "永和县",
                    },

                    {
                        value: 141033,

                        label: "蒲县",
                    },

                    {
                        value: 141034,

                        label: "汾西县",
                    },

                    {
                        value: 141081,

                        label: "侯马市",
                    },

                    {
                        value: 141082,

                        label: "霍州市",
                    },
                ],
            },

            {
                value: 141100,

                label: "吕梁市",

                children: [{
                        value: 141102,

                        label: "离石区",
                    },

                    {
                        value: 141121,

                        label: "文水县",
                    },

                    {
                        value: 141122,

                        label: "交城县",
                    },

                    {
                        value: 141123,

                        label: "兴县",
                    },

                    {
                        value: 141124,

                        label: "临县",
                    },

                    {
                        value: 141125,

                        label: "柳林县",
                    },

                    {
                        value: 141126,

                        label: "石楼县",
                    },

                    {
                        value: 141127,

                        label: "岚县",
                    },

                    {
                        value: 141128,

                        label: "方山县",
                    },

                    {
                        value: 141129,

                        label: "中阳县",
                    },

                    {
                        value: 141130,

                        label: "交口县",
                    },

                    {
                        value: 141181,

                        label: "孝义市",
                    },

                    {
                        value: 141182,

                        label: "汾阳市",
                    },
                ],
            },
        ],
    },

    {
        value: 150000,

        label: "内蒙古自治区",

        children: [{
                value: 150100,

                label: "呼和浩特市",

                children: [{
                        value: 150102,

                        label: "新城区",
                    },

                    {
                        value: 150103,

                        label: "回民区",
                    },

                    {
                        value: 150104,

                        label: "玉泉区",
                    },

                    {
                        value: 150105,

                        label: "赛罕区",
                    },

                    {
                        value: 150121,

                        label: "土默特左旗",
                    },

                    {
                        value: 150122,

                        label: "托克托县",
                    },

                    {
                        value: 150123,

                        label: "和林格尔县",
                    },

                    {
                        value: 150124,

                        label: "清水河县",
                    },

                    {
                        value: 150125,

                        label: "武川县",
                    },
                ],
            },

            {
                value: 150200,

                label: "包头市",

                children: [{
                        value: 150202,

                        label: "东河区",
                    },

                    {
                        value: 150203,

                        label: "昆都仑区",
                    },

                    {
                        value: 150204,

                        label: "青山区",
                    },

                    {
                        value: 150205,

                        label: "石拐区",
                    },

                    {
                        value: 150206,

                        label: "白云鄂博矿区",
                    },

                    {
                        value: 150207,

                        label: "九原区",
                    },

                    {
                        value: 150221,

                        label: "土默特右旗",
                    },

                    {
                        value: 150222,

                        label: "固阳县",
                    },

                    {
                        value: 150223,

                        label: "达尔罕茂明安联合旗",
                    },
                ],
            },

            {
                value: 150300,

                label: "乌海市",

                children: [{
                        value: 150302,

                        label: "海勃湾区",
                    },

                    {
                        value: 150303,

                        label: "海南区",
                    },

                    {
                        value: 150304,

                        label: "乌达区",
                    },
                ],
            },

            {
                value: 150400,

                label: "赤峰市",

                children: [{
                        value: 150402,

                        label: "红山区",
                    },

                    {
                        value: 150403,

                        label: "元宝山区",
                    },

                    {
                        value: 150404,

                        label: "松山区",
                    },

                    {
                        value: 150421,

                        label: "阿鲁科尔沁旗",
                    },

                    {
                        value: 150422,

                        label: "巴林左旗",
                    },

                    {
                        value: 150423,

                        label: "巴林右旗",
                    },

                    {
                        value: 150424,

                        label: "林西县",
                    },

                    {
                        value: 150425,

                        label: "克什克腾旗",
                    },

                    {
                        value: 150426,

                        label: "翁牛特旗",
                    },

                    {
                        value: 150428,

                        label: "喀喇沁旗",
                    },

                    {
                        value: 150429,

                        label: "宁城县",
                    },

                    {
                        value: 150430,

                        label: "敖汉旗",
                    },
                ],
            },

            {
                value: 150500,

                label: "通辽市",

                children: [{
                        value: 150502,

                        label: "科尔沁区",
                    },

                    {
                        value: 150521,

                        label: "科尔沁左翼中旗",
                    },

                    {
                        value: 150522,

                        label: "科尔沁左翼后旗",
                    },

                    {
                        value: 150523,

                        label: "开鲁县",
                    },

                    {
                        value: 150524,

                        label: "库伦旗",
                    },

                    {
                        value: 150525,

                        label: "奈曼旗",
                    },

                    {
                        value: 150526,

                        label: "扎鲁特旗",
                    },

                    {
                        value: 150581,

                        label: "霍林郭勒市",
                    },
                ],
            },

            {
                value: 150600,

                label: "鄂尔多斯市",

                children: [{
                        value: 150602,

                        label: "东胜区",
                    },

                    {
                        value: 150603,

                        label: "康巴什区",
                    },

                    {
                        value: 150621,

                        label: "达拉特旗",
                    },

                    {
                        value: 150622,

                        label: "准格尔旗",
                    },

                    {
                        value: 150623,

                        label: "鄂托克前旗",
                    },

                    {
                        value: 150624,

                        label: "鄂托克旗",
                    },

                    {
                        value: 150625,

                        label: "杭锦旗",
                    },

                    {
                        value: 150626,

                        label: "乌审旗",
                    },

                    {
                        value: 150627,

                        label: "伊金霍洛旗",
                    },
                ],
            },

            {
                value: 150700,

                label: "呼伦贝尔市",

                children: [{
                        value: 150702,

                        label: "海拉尔区",
                    },

                    {
                        value: 150703,

                        label: "扎赉诺尔区",
                    },

                    {
                        value: 150721,

                        label: "阿荣旗",
                    },

                    {
                        value: 150722,

                        label: "莫力达瓦达斡尔族自治旗",
                    },

                    {
                        value: 150723,

                        label: "鄂伦春自治旗",
                    },

                    {
                        value: 150724,

                        label: "鄂温克族自治旗",
                    },

                    {
                        value: 150725,

                        label: "陈巴尔虎旗",
                    },

                    {
                        value: 150726,

                        label: "新巴尔虎左旗",
                    },

                    {
                        value: 150727,

                        label: "新巴尔虎右旗",
                    },

                    {
                        value: 150781,

                        label: "满洲里市",
                    },

                    {
                        value: 150782,

                        label: "牙克石市",
                    },

                    {
                        value: 150783,

                        label: "扎兰屯市",
                    },

                    {
                        value: 150784,

                        label: "额尔古纳市",
                    },

                    {
                        value: 150785,

                        label: "根河市",
                    },
                ],
            },

            {
                value: 150800,

                label: "巴彦淖尔市",

                children: [{
                        value: 150802,

                        label: "临河区",
                    },

                    {
                        value: 150821,

                        label: "五原县",
                    },

                    {
                        value: 150822,

                        label: "磴口县",
                    },

                    {
                        value: 150823,

                        label: "乌拉特前旗",
                    },

                    {
                        value: 150824,

                        label: "乌拉特中旗",
                    },

                    {
                        value: 150825,

                        label: "乌拉特后旗",
                    },

                    {
                        value: 150826,

                        label: "杭锦后旗",
                    },
                ],
            },

            {
                value: 150900,

                label: "乌兰察布市",

                children: [{
                        value: 150902,

                        label: "集宁区",
                    },

                    {
                        value: 150921,

                        label: "卓资县",
                    },

                    {
                        value: 150922,

                        label: "化德县",
                    },

                    {
                        value: 150923,

                        label: "商都县",
                    },

                    {
                        value: 150924,

                        label: "兴和县",
                    },

                    {
                        value: 150925,

                        label: "凉城县",
                    },

                    {
                        value: 150926,

                        label: "察哈尔右翼前旗",
                    },

                    {
                        value: 150927,

                        label: "察哈尔右翼中旗",
                    },

                    {
                        value: 150928,

                        label: "察哈尔右翼后旗",
                    },

                    {
                        value: 150929,

                        label: "四子王旗",
                    },

                    {
                        value: 150981,

                        label: "丰镇市",
                    },
                ],
            },

            {
                value: 152200,

                label: "兴安盟",

                children: [{
                        value: 152201,

                        label: "乌兰浩特市",
                    },

                    {
                        value: 152202,

                        label: "阿尔山市",
                    },

                    {
                        value: 152221,

                        label: "科尔沁右翼前旗",
                    },

                    {
                        value: 152222,

                        label: "科尔沁右翼中旗",
                    },

                    {
                        value: 152223,

                        label: "扎赉特旗",
                    },

                    {
                        value: 152224,

                        label: "突泉县",
                    },
                ],
            },

            {
                value: 152500,

                label: "锡林郭勒盟",

                children: [{
                        value: 152501,

                        label: "二连浩特市",
                    },

                    {
                        value: 152502,

                        label: "锡林浩特市",
                    },

                    {
                        value: 152522,

                        label: "阿巴嘎旗",
                    },

                    {
                        value: 152523,

                        label: "苏尼特左旗",
                    },

                    {
                        value: 152524,

                        label: "苏尼特右旗",
                    },

                    {
                        value: 152525,

                        label: "东乌珠穆沁旗",
                    },

                    {
                        value: 152526,

                        label: "西乌珠穆沁旗",
                    },

                    {
                        value: 152527,

                        label: "太仆寺旗",
                    },

                    {
                        value: 152528,

                        label: "镶黄旗",
                    },

                    {
                        value: 152529,

                        label: "正镶白旗",
                    },

                    {
                        value: 152530,

                        label: "正蓝旗",
                    },

                    {
                        value: 152531,

                        label: "多伦县",
                    },
                ],
            },

            {
                value: 152900,

                label: "阿拉善盟",

                children: [{
                        value: 152921,

                        label: "阿拉善左旗",
                    },

                    {
                        value: 152922,

                        label: "阿拉善右旗",
                    },

                    {
                        value: 152923,

                        label: "额济纳旗",
                    },
                ],
            },
        ],
    },

    {
        value: 210000,

        label: "辽宁省",

        children: [{
                value: 210100,

                label: "沈阳市",

                children: [{
                        value: 210102,

                        label: "和平区",
                    },

                    {
                        value: 210103,

                        label: "沈河区",
                    },

                    {
                        value: 210104,

                        label: "大东区",
                    },

                    {
                        value: 210105,

                        label: "皇姑区",
                    },

                    {
                        value: 210106,

                        label: "铁西区",
                    },

                    {
                        value: 210111,

                        label: "苏家屯区",
                    },

                    {
                        value: 210112,

                        label: "浑南区",
                    },

                    {
                        value: 210113,

                        label: "沈北新区",
                    },

                    {
                        value: 210114,

                        label: "于洪区",
                    },

                    {
                        value: 210115,

                        label: "辽中区",
                    },

                    {
                        value: 210123,

                        label: "康平县",
                    },

                    {
                        value: 210124,

                        label: "法库县",
                    },

                    {
                        value: 210181,

                        label: "新民市",
                    },
                ],
            },

            {
                value: 210200,

                label: "大连市",

                children: [{
                        value: 210202,

                        label: "中山区",
                    },

                    {
                        value: 210203,

                        label: "西岗区",
                    },

                    {
                        value: 210204,

                        label: "沙河口区",
                    },

                    {
                        value: 210211,

                        label: "甘井子区",
                    },

                    {
                        value: 210212,

                        label: "旅顺口区",
                    },

                    {
                        value: 210213,

                        label: "金州区",
                    },

                    {
                        value: 210214,

                        label: "普兰店区",
                    },

                    {
                        value: 210224,

                        label: "长海县",
                    },

                    {
                        value: 210281,

                        label: "瓦房店市",
                    },

                    {
                        value: 210283,

                        label: "庄河市",
                    },
                ],
            },

            {
                value: 210300,

                label: "鞍山市",

                children: [{
                        value: 210302,

                        label: "铁东区",
                    },

                    {
                        value: 210303,

                        label: "铁西区",
                    },

                    {
                        value: 210304,

                        label: "立山区",
                    },

                    {
                        value: 210311,

                        label: "千山区",
                    },

                    {
                        value: 210321,

                        label: "台安县",
                    },

                    {
                        value: 210323,

                        label: "岫岩满族自治县",
                    },

                    {
                        value: 210381,

                        label: "海城市",
                    },
                ],
            },

            {
                value: 210400,

                label: "抚顺市",

                children: [{
                        value: 210402,

                        label: "新抚区",
                    },

                    {
                        value: 210403,

                        label: "东洲区",
                    },

                    {
                        value: 210404,

                        label: "望花区",
                    },

                    {
                        value: 210411,

                        label: "顺城区",
                    },

                    {
                        value: 210421,

                        label: "抚顺县",
                    },

                    {
                        value: 210422,

                        label: "新宾满族自治县",
                    },

                    {
                        value: 210423,

                        label: "清原满族自治县",
                    },
                ],
            },

            {
                value: 210500,

                label: "本溪市",

                children: [{
                        value: 210502,

                        label: "平山区",
                    },

                    {
                        value: 210503,

                        label: "溪湖区",
                    },

                    {
                        value: 210504,

                        label: "明山区",
                    },

                    {
                        value: 210505,

                        label: "南芬区",
                    },

                    {
                        value: 210521,

                        label: "本溪满族自治县",
                    },

                    {
                        value: 210522,

                        label: "桓仁满族自治县",
                    },
                ],
            },

            {
                value: 210600,

                label: "丹东市",

                children: [{
                        value: 210602,

                        label: "元宝区",
                    },

                    {
                        value: 210603,

                        label: "振兴区",
                    },

                    {
                        value: 210604,

                        label: "振安区",
                    },

                    {
                        value: 210624,

                        label: "宽甸满族自治县",
                    },

                    {
                        value: 210681,

                        label: "东港市",
                    },

                    {
                        value: 210682,

                        label: "凤城市",
                    },
                ],
            },

            {
                value: 210700,

                label: "锦州市",

                children: [{
                        value: 210702,

                        label: "古塔区",
                    },

                    {
                        value: 210703,

                        label: "凌河区",
                    },

                    {
                        value: 210711,

                        label: "太和区",
                    },

                    {
                        value: 210726,

                        label: "黑山县",
                    },

                    {
                        value: 210727,

                        label: "义县",
                    },

                    {
                        value: 210781,

                        label: "凌海市",
                    },

                    {
                        value: 210782,

                        label: "北镇市",
                    },
                ],
            },

            {
                value: 210800,

                label: "营口市",

                children: [{
                        value: 210802,

                        label: "站前区",
                    },

                    {
                        value: 210803,

                        label: "西市区",
                    },

                    {
                        value: 210804,

                        label: "鲅鱼圈区",
                    },

                    {
                        value: 210811,

                        label: "老边区",
                    },

                    {
                        value: 210881,

                        label: "盖州市",
                    },

                    {
                        value: 210882,

                        label: "大石桥市",
                    },
                ],
            },

            {
                value: 210900,

                label: "阜新市",

                children: [{
                        value: 210902,

                        label: "海州区",
                    },

                    {
                        value: 210903,

                        label: "新邱区",
                    },

                    {
                        value: 210904,

                        label: "太平区",
                    },

                    {
                        value: 210905,

                        label: "清河门区",
                    },

                    {
                        value: 210911,

                        label: "细河区",
                    },

                    {
                        value: 210921,

                        label: "阜新蒙古族自治县",
                    },

                    {
                        value: 210922,

                        label: "彰武县",
                    },
                ],
            },

            {
                value: 211000,

                label: "辽阳市",

                children: [{
                        value: 211002,

                        label: "白塔区",
                    },

                    {
                        value: 211003,

                        label: "文圣区",
                    },

                    {
                        value: 211004,

                        label: "宏伟区",
                    },

                    {
                        value: 211005,

                        label: "弓长岭区",
                    },

                    {
                        value: 211011,

                        label: "太子河区",
                    },

                    {
                        value: 211021,

                        label: "辽阳县",
                    },

                    {
                        value: 211081,

                        label: "灯塔市",
                    },
                ],
            },

            {
                value: 211100,

                label: "盘锦市",

                children: [{
                        value: 211102,

                        label: "双台子区",
                    },

                    {
                        value: 211103,

                        label: "兴隆台区",
                    },

                    {
                        value: 211104,

                        label: "大洼区",
                    },

                    {
                        value: 211122,

                        label: "盘山县",
                    },
                ],
            },

            {
                value: 211200,

                label: "铁岭市",

                children: [{
                        value: 211202,

                        label: "银州区",
                    },

                    {
                        value: 211204,

                        label: "清河区",
                    },

                    {
                        value: 211221,

                        label: "铁岭县",
                    },

                    {
                        value: 211223,

                        label: "西丰县",
                    },

                    {
                        value: 211224,

                        label: "昌图县",
                    },

                    {
                        value: 211281,

                        label: "调兵山市",
                    },

                    {
                        value: 211282,

                        label: "开原市",
                    },
                ],
            },

            {
                value: 211300,

                label: "朝阳市",

                children: [{
                        value: 211302,

                        label: "双塔区",
                    },

                    {
                        value: 211303,

                        label: "龙城区",
                    },

                    {
                        value: 211321,

                        label: "朝阳县",
                    },

                    {
                        value: 211322,

                        label: "建平县",
                    },

                    {
                        value: 211324,

                        label: "喀喇沁左翼蒙古族自治县",
                    },

                    {
                        value: 211381,

                        label: "北票市",
                    },

                    {
                        value: 211382,

                        label: "凌源市",
                    },
                ],
            },

            {
                value: 211400,

                label: "葫芦岛市",

                children: [{
                        value: 211402,

                        label: "连山区",
                    },

                    {
                        value: 211403,

                        label: "龙港区",
                    },

                    {
                        value: 211404,

                        label: "南票区",
                    },

                    {
                        value: 211421,

                        label: "绥中县",
                    },

                    {
                        value: 211422,

                        label: "建昌县",
                    },

                    {
                        value: 211481,

                        label: "兴城市",
                    },
                ],
            },
        ],
    },

    {
        value: 220000,

        label: "吉林省",

        children: [{
                value: 220100,

                label: "长春市",

                children: [{
                        value: 220102,

                        label: "南关区",
                    },

                    {
                        value: 220103,

                        label: "宽城区",
                    },

                    {
                        value: 220104,

                        label: "朝阳区",
                    },

                    {
                        value: 220105,

                        label: "二道区",
                    },

                    {
                        value: 220106,

                        label: "绿园区",
                    },

                    {
                        value: 220112,

                        label: "双阳区",
                    },

                    {
                        value: 220113,

                        label: "九台区",
                    },

                    {
                        value: 220122,

                        label: "农安县",
                    },

                    {
                        value: 220182,

                        label: "榆树市",
                    },

                    {
                        value: 220183,

                        label: "德惠市",
                    },
                ],
            },

            {
                value: 220200,

                label: "吉林市",

                children: [{
                        value: 220202,

                        label: "昌邑区",
                    },

                    {
                        value: 220203,

                        label: "龙潭区",
                    },

                    {
                        value: 220204,

                        label: "船营区",
                    },

                    {
                        value: 220211,

                        label: "丰满区",
                    },

                    {
                        value: 220221,

                        label: "永吉县",
                    },

                    {
                        value: 220281,

                        label: "蛟河市",
                    },

                    {
                        value: 220282,

                        label: "桦甸市",
                    },

                    {
                        value: 220283,

                        label: "舒兰市",
                    },

                    {
                        value: 220284,

                        label: "磐石市",
                    },
                ],
            },

            {
                value: 220300,

                label: "四平市",

                children: [{
                        value: 220302,

                        label: "铁西区",
                    },

                    {
                        value: 220303,

                        label: "铁东区",
                    },

                    {
                        value: 220322,

                        label: "梨树县",
                    },

                    {
                        value: 220323,

                        label: "伊通满族自治县",
                    },

                    {
                        value: 220381,

                        label: "公主岭市",
                    },

                    {
                        value: 220382,

                        label: "双辽市",
                    },
                ],
            },

            {
                value: 220400,

                label: "辽源市",

                children: [{
                        value: 220402,

                        label: "龙山区",
                    },

                    {
                        value: 220403,

                        label: "西安区",
                    },

                    {
                        value: 220421,

                        label: "东丰县",
                    },

                    {
                        value: 220422,

                        label: "东辽县",
                    },
                ],
            },

            {
                value: 220500,

                label: "通化市",

                children: [{
                        value: 220502,

                        label: "东昌区",
                    },

                    {
                        value: 220503,

                        label: "二道江区",
                    },

                    {
                        value: 220521,

                        label: "通化县",
                    },

                    {
                        value: 220523,

                        label: "辉南县",
                    },

                    {
                        value: 220524,

                        label: "柳河县",
                    },

                    {
                        value: 220581,

                        label: "梅河口市",
                    },

                    {
                        value: 220582,

                        label: "集安市",
                    },
                ],
            },

            {
                value: 220600,

                label: "白山市",

                children: [{
                        value: 220602,

                        label: "浑江区",
                    },

                    {
                        value: 220605,

                        label: "江源区",
                    },

                    {
                        value: 220621,

                        label: "抚松县",
                    },

                    {
                        value: 220622,

                        label: "靖宇县",
                    },

                    {
                        value: 220623,

                        label: "长白朝鲜族自治县",
                    },

                    {
                        value: 220681,

                        label: "临江市",
                    },
                ],
            },

            {
                value: 220700,

                label: "松原市",

                children: [{
                        value: 220702,

                        label: "宁江区",
                    },

                    {
                        value: 220721,

                        label: "前郭尔罗斯蒙古族自治县",
                    },

                    {
                        value: 220722,

                        label: "长岭县",
                    },

                    {
                        value: 220723,

                        label: "乾安县",
                    },

                    {
                        value: 220781,

                        label: "扶余市",
                    },
                ],
            },

            {
                value: 220800,

                label: "白城市",

                children: [{
                        value: 220802,

                        label: "洮北区",
                    },

                    {
                        value: 220821,

                        label: "镇赉县",
                    },

                    {
                        value: 220822,

                        label: "通榆县",
                    },

                    {
                        value: 220881,

                        label: "洮南市",
                    },

                    {
                        value: 220882,

                        label: "大安市",
                    },
                ],
            },

            {
                value: 222400,

                label: "延边朝鲜族自治州",

                children: [{
                        value: 222401,

                        label: "延吉市",
                    },

                    {
                        value: 222402,

                        label: "图们市",
                    },

                    {
                        value: 222403,

                        label: "敦化市",
                    },

                    {
                        value: 222404,

                        label: "珲春市",
                    },

                    {
                        value: 222405,

                        label: "龙井市",
                    },

                    {
                        value: 222406,

                        label: "和龙市",
                    },

                    {
                        value: 222424,

                        label: "汪清县",
                    },

                    {
                        value: 222426,

                        label: "安图县",
                    },
                ],
            },
        ],
    },

    {
        value: 230000,

        label: "黑龙江省",

        children: [{
                value: 230100,

                label: "哈尔滨市",

                children: [{
                        value: 230102,

                        label: "道里区",
                    },

                    {
                        value: 230103,

                        label: "南岗区",
                    },

                    {
                        value: 230104,

                        label: "道外区",
                    },

                    {
                        value: 230108,

                        label: "平房区",
                    },

                    {
                        value: 230109,

                        label: "松北区",
                    },

                    {
                        value: 230110,

                        label: "香坊区",
                    },

                    {
                        value: 230111,

                        label: "呼兰区",
                    },

                    {
                        value: 230112,

                        label: "阿城区",
                    },

                    {
                        value: 230113,

                        label: "双城区",
                    },

                    {
                        value: 230123,

                        label: "依兰县",
                    },

                    {
                        value: 230124,

                        label: "方正县",
                    },

                    {
                        value: 230125,

                        label: "宾县",
                    },

                    {
                        value: 230126,

                        label: "巴彦县",
                    },

                    {
                        value: 230127,

                        label: "木兰县",
                    },

                    {
                        value: 230128,

                        label: "通河县",
                    },

                    {
                        value: 230129,

                        label: "延寿县",
                    },

                    {
                        value: 230183,

                        label: "尚志市",
                    },

                    {
                        value: 230184,

                        label: "五常市",
                    },
                ],
            },

            {
                value: 230200,

                label: "齐齐哈尔市",

                children: [{
                        value: 230202,

                        label: "龙沙区",
                    },

                    {
                        value: 230203,

                        label: "建华区",
                    },

                    {
                        value: 230204,

                        label: "铁锋区",
                    },

                    {
                        value: 230205,

                        label: "昂昂溪区",
                    },

                    {
                        value: 230206,

                        label: "富拉尔基区",
                    },

                    {
                        value: 230207,

                        label: "碾子山区",
                    },

                    {
                        value: 230208,

                        label: "梅里斯达斡尔族区",
                    },

                    {
                        value: 230221,

                        label: "龙江县",
                    },

                    {
                        value: 230223,

                        label: "依安县",
                    },

                    {
                        value: 230224,

                        label: "泰来县",
                    },

                    {
                        value: 230225,

                        label: "甘南县",
                    },

                    {
                        value: 230227,

                        label: "富裕县",
                    },

                    {
                        value: 230229,

                        label: "克山县",
                    },

                    {
                        value: 230230,

                        label: "克东县",
                    },

                    {
                        value: 230231,

                        label: "拜泉县",
                    },

                    {
                        value: 230281,

                        label: "讷河市",
                    },
                ],
            },

            {
                value: 230300,

                label: "鸡西市",

                children: [{
                        value: 230302,

                        label: "鸡冠区",
                    },

                    {
                        value: 230303,

                        label: "恒山区",
                    },

                    {
                        value: 230304,

                        label: "滴道区",
                    },

                    {
                        value: 230305,

                        label: "梨树区",
                    },

                    {
                        value: 230306,

                        label: "城子河区",
                    },

                    {
                        value: 230307,

                        label: "麻山区",
                    },

                    {
                        value: 230321,

                        label: "鸡东县",
                    },

                    {
                        value: 230381,

                        label: "虎林市",
                    },

                    {
                        value: 230382,

                        label: "密山市",
                    },
                ],
            },

            {
                value: 230400,

                label: "鹤岗市",

                children: [{
                        value: 230402,

                        label: "向阳区",
                    },

                    {
                        value: 230403,

                        label: "工农区",
                    },

                    {
                        value: 230404,

                        label: "南山区",
                    },

                    {
                        value: 230405,

                        label: "兴安区",
                    },

                    {
                        value: 230406,

                        label: "东山区",
                    },

                    {
                        value: 230407,

                        label: "兴山区",
                    },

                    {
                        value: 230421,

                        label: "萝北县",
                    },

                    {
                        value: 230422,

                        label: "绥滨县",
                    },
                ],
            },

            {
                value: 230500,

                label: "双鸭山市",

                children: [{
                        value: 230502,

                        label: "尖山区",
                    },

                    {
                        value: 230503,

                        label: "岭东区",
                    },

                    {
                        value: 230505,

                        label: "四方台区",
                    },

                    {
                        value: 230506,

                        label: "宝山区",
                    },

                    {
                        value: 230521,

                        label: "集贤县",
                    },

                    {
                        value: 230522,

                        label: "友谊县",
                    },

                    {
                        value: 230523,

                        label: "宝清县",
                    },

                    {
                        value: 230524,

                        label: "饶河县",
                    },
                ],
            },

            {
                value: 230600,

                label: "大庆市",

                children: [{
                        value: 230602,

                        label: "萨尔图区",
                    },

                    {
                        value: 230603,

                        label: "龙凤区",
                    },

                    {
                        value: 230604,

                        label: "让胡路区",
                    },

                    {
                        value: 230605,

                        label: "红岗区",
                    },

                    {
                        value: 230606,

                        label: "大同区",
                    },

                    {
                        value: 230621,

                        label: "肇州县",
                    },

                    {
                        value: 230622,

                        label: "肇源县",
                    },

                    {
                        value: 230623,

                        label: "林甸县",
                    },

                    {
                        value: 230624,

                        label: "杜尔伯特蒙古族自治县",
                    },
                ],
            },

            {
                value: 230700,

                label: "伊春市",

                children: [{
                        value: 230702,

                        label: "伊春区",
                    },

                    {
                        value: 230703,

                        label: "南岔区",
                    },

                    {
                        value: 230704,

                        label: "友好区",
                    },

                    {
                        value: 230705,

                        label: "西林区",
                    },

                    {
                        value: 230706,

                        label: "翠峦区",
                    },

                    {
                        value: 230707,

                        label: "新青区",
                    },

                    {
                        value: 230708,

                        label: "美溪区",
                    },

                    {
                        value: 230709,

                        label: "金山屯区",
                    },

                    {
                        value: 230710,

                        label: "五营区",
                    },

                    {
                        value: 230711,

                        label: "乌马河区",
                    },

                    {
                        value: 230712,

                        label: "汤旺河区",
                    },

                    {
                        value: 230713,

                        label: "带岭区",
                    },

                    {
                        value: 230714,

                        label: "乌伊岭区",
                    },

                    {
                        value: 230715,

                        label: "红星区",
                    },

                    {
                        value: 230716,

                        label: "上甘岭区",
                    },

                    {
                        value: 230722,

                        label: "嘉荫县",
                    },

                    {
                        value: 230781,

                        label: "铁力市",
                    },
                ],
            },

            {
                value: 230800,

                label: "佳木斯市",

                children: [{
                        value: 230803,

                        label: "向阳区",
                    },

                    {
                        value: 230804,

                        label: "前进区",
                    },

                    {
                        value: 230805,

                        label: "东风区",
                    },

                    {
                        value: 230811,

                        label: "郊区",
                    },

                    {
                        value: 230822,

                        label: "桦南县",
                    },

                    {
                        value: 230826,

                        label: "桦川县",
                    },

                    {
                        value: 230828,

                        label: "汤原县",
                    },

                    {
                        value: 230881,

                        label: "同江市",
                    },

                    {
                        value: 230882,

                        label: "富锦市",
                    },

                    {
                        value: 230883,

                        label: "抚远市",
                    },
                ],
            },

            {
                value: 230900,

                label: "七台河市",

                children: [{
                        value: 230902,

                        label: "新兴区",
                    },

                    {
                        value: 230903,

                        label: "桃山区",
                    },

                    {
                        value: 230904,

                        label: "茄子河区",
                    },

                    {
                        value: 230921,

                        label: "勃利县",
                    },
                ],
            },

            {
                value: 231000,

                label: "牡丹江市",

                children: [{
                        value: 231002,

                        label: "东安区",
                    },

                    {
                        value: 231003,

                        label: "阳明区",
                    },

                    {
                        value: 231004,

                        label: "爱民区",
                    },

                    {
                        value: 231005,

                        label: "西安区",
                    },

                    {
                        value: 231025,

                        label: "林口县",
                    },

                    {
                        value: 231081,

                        label: "绥芬河市",
                    },

                    {
                        value: 231083,

                        label: "海林市",
                    },

                    {
                        value: 231084,

                        label: "宁安市",
                    },

                    {
                        value: 231085,

                        label: "穆棱市",
                    },

                    {
                        value: 231086,

                        label: "东宁市",
                    },
                ],
            },

            {
                value: 231100,

                label: "黑河市",

                children: [{
                        value: 231102,

                        label: "爱辉区",
                    },

                    {
                        value: 231121,

                        label: "嫩江县",
                    },

                    {
                        value: 231123,

                        label: "逊克县",
                    },

                    {
                        value: 231124,

                        label: "孙吴县",
                    },

                    {
                        value: 231181,

                        label: "北安市",
                    },

                    {
                        value: 231182,

                        label: "五大连池市",
                    },
                ],
            },

            {
                value: 231200,

                label: "绥化市",

                children: [{
                        value: 231202,

                        label: "北林区",
                    },

                    {
                        value: 231221,

                        label: "望奎县",
                    },

                    {
                        value: 231222,

                        label: "兰西县",
                    },

                    {
                        value: 231223,

                        label: "青冈县",
                    },

                    {
                        value: 231224,

                        label: "庆安县",
                    },

                    {
                        value: 231225,

                        label: "明水县",
                    },

                    {
                        value: 231226,

                        label: "绥棱县",
                    },

                    {
                        value: 231281,

                        label: "安达市",
                    },

                    {
                        value: 231282,

                        label: "肇东市",
                    },

                    {
                        value: 231283,

                        label: "海伦市",
                    },
                ],
            },

            {
                value: 232700,

                label: "大兴安岭地区",

                children: [{
                        value: 232701,

                        label: "漠河市",
                    },

                    {
                        value: 232721,

                        label: "呼玛县",
                    },

                    {
                        value: 232722,

                        label: "塔河县",
                    },
                ],
            },
        ],
    },

    {
        value: 320000,

        label: "江苏省",

        children: [{
                value: 320100,

                label: "南京市",

                children: [{
                        value: 320102,

                        label: "玄武区",
                    },

                    {
                        value: 320104,

                        label: "秦淮区",
                    },

                    {
                        value: 320105,

                        label: "建邺区",
                    },

                    {
                        value: 320106,

                        label: "鼓楼区",
                    },

                    {
                        value: 320111,

                        label: "浦口区",
                    },

                    {
                        value: 320113,

                        label: "栖霞区",
                    },

                    {
                        value: 320114,

                        label: "雨花台区",
                    },

                    {
                        value: 320115,

                        label: "江宁区",
                    },

                    {
                        value: 320116,

                        label: "六合区",
                    },

                    {
                        value: 320117,

                        label: "溧水区",
                    },

                    {
                        value: 320118,

                        label: "高淳区",
                    },
                ],
            },

            {
                value: 320200,

                label: "无锡市",

                children: [{
                        value: 320205,

                        label: "锡山区",
                    },

                    {
                        value: 320206,

                        label: "惠山区",
                    },

                    {
                        value: 320211,

                        label: "滨湖区",
                    },

                    {
                        value: 320213,

                        label: "梁溪区",
                    },

                    {
                        value: 320214,

                        label: "新吴区",
                    },

                    {
                        value: 320281,

                        label: "江阴市",
                    },

                    {
                        value: 320282,

                        label: "宜兴市",
                    },
                ],
            },

            {
                value: 320300,

                label: "徐州市",

                children: [{
                        value: 320302,

                        label: "鼓楼区",
                    },

                    {
                        value: 320303,

                        label: "云龙区",
                    },

                    {
                        value: 320305,

                        label: "贾汪区",
                    },

                    {
                        value: 320311,

                        label: "泉山区",
                    },

                    {
                        value: 320312,

                        label: "铜山区",
                    },

                    {
                        value: 320321,

                        label: "丰县",
                    },

                    {
                        value: 320322,

                        label: "沛县",
                    },

                    {
                        value: 320324,

                        label: "睢宁县",
                    },

                    {
                        value: 320381,

                        label: "新沂市",
                    },

                    {
                        value: 320382,

                        label: "邳州市",
                    },
                ],
            },

            {
                value: 320400,

                label: "常州市",

                children: [{
                        value: 320402,

                        label: "天宁区",
                    },

                    {
                        value: 320404,

                        label: "钟楼区",
                    },

                    {
                        value: 320411,

                        label: "新北区",
                    },

                    {
                        value: 320412,

                        label: "武进区",
                    },

                    {
                        value: 320413,

                        label: "金坛区",
                    },

                    {
                        value: 320481,

                        label: "溧阳市",
                    },
                ],
            },

            {
                value: 320500,

                label: "苏州市",

                children: [{
                        value: 320505,

                        label: "虎丘区",
                    },

                    {
                        value: 320506,

                        label: "吴中区",
                    },

                    {
                        value: 320507,

                        label: "相城区",
                    },

                    {
                        value: 320508,

                        label: "姑苏区",
                    },

                    {
                        value: 320509,

                        label: "吴江区",
                    },

                    {
                        value: 320581,

                        label: "常熟市",
                    },

                    {
                        value: 320582,

                        label: "张家港市",
                    },

                    {
                        value: 320583,

                        label: "昆山市",
                    },

                    {
                        value: 320585,

                        label: "太仓市",
                    },

                    {
                        value: 322001,

                        label: "苏州工业园区",
                    },
                ],
            },

            {
                value: 320600,

                label: "南通市",

                children: [{
                        value: 320602,

                        label: "崇川区",
                    },

                    {
                        value: 320611,

                        label: "港闸区",
                    },

                    {
                        value: 320612,

                        label: "通州区",
                    },

                    {
                        value: 320623,

                        label: "如东县",
                    },

                    {
                        value: 320681,

                        label: "启东市",
                    },

                    {
                        value: 320682,

                        label: "如皋市",
                    },

                    {
                        value: 320684,

                        label: "海门市",
                    },

                    {
                        value: 320685,

                        label: "海安市",
                    },
                ],
            },

            {
                value: 320700,

                label: "连云港市",

                children: [{
                        value: 320703,

                        label: "连云区",
                    },

                    {
                        value: 320706,

                        label: "海州区",
                    },

                    {
                        value: 320707,

                        label: "赣榆区",
                    },

                    {
                        value: 320722,

                        label: "东海县",
                    },

                    {
                        value: 320723,

                        label: "灌云县",
                    },

                    {
                        value: 320724,

                        label: "灌南县",
                    },
                ],
            },

            {
                value: 320800,

                label: "淮安市",

                children: [{
                        value: 320803,

                        label: "淮安区",
                    },

                    {
                        value: 320804,

                        label: "淮阴区",
                    },

                    {
                        value: 320812,

                        label: "清江浦区",
                    },

                    {
                        value: 320813,

                        label: "洪泽区",
                    },

                    {
                        value: 320826,

                        label: "涟水县",
                    },

                    {
                        value: 320830,

                        label: "盱眙县",
                    },

                    {
                        value: 320831,

                        label: "金湖县",
                    },
                ],
            },

            {
                value: 320900,

                label: "盐城市",

                children: [{
                        value: 320902,

                        label: "亭湖区",
                    },

                    {
                        value: 320903,

                        label: "盐都区",
                    },

                    {
                        value: 320904,

                        label: "大丰区",
                    },

                    {
                        value: 320921,

                        label: "响水县",
                    },

                    {
                        value: 320922,

                        label: "滨海县",
                    },

                    {
                        value: 320923,

                        label: "阜宁县",
                    },

                    {
                        value: 320924,

                        label: "射阳县",
                    },

                    {
                        value: 320925,

                        label: "建湖县",
                    },

                    {
                        value: 320981,

                        label: "东台市",
                    },
                ],
            },

            {
                value: 321000,

                label: "扬州市",

                children: [{
                        value: 321002,

                        label: "广陵区",
                    },

                    {
                        value: 321003,

                        label: "邗江区",
                    },

                    {
                        value: 321012,

                        label: "江都区",
                    },

                    {
                        value: 321023,

                        label: "宝应县",
                    },

                    {
                        value: 321081,

                        label: "仪征市",
                    },

                    {
                        value: 321084,

                        label: "高邮市",
                    },
                ],
            },

            {
                value: 321100,

                label: "镇江市",

                children: [{
                        value: 321102,

                        label: "京口区",
                    },

                    {
                        value: 321111,

                        label: "润州区",
                    },

                    {
                        value: 321112,

                        label: "丹徒区",
                    },

                    {
                        value: 321181,

                        label: "丹阳市",
                    },

                    {
                        value: 321182,

                        label: "扬中市",
                    },

                    {
                        value: 321183,

                        label: "句容市",
                    },
                ],
            },

            {
                value: 321200,

                label: "泰州市",

                children: [{
                        value: 321202,

                        label: "海陵区",
                    },

                    {
                        value: 321203,

                        label: "高港区",
                    },

                    {
                        value: 321204,

                        label: "姜堰区",
                    },

                    {
                        value: 321281,

                        label: "兴化市",
                    },

                    {
                        value: 321282,

                        label: "靖江市",
                    },

                    {
                        value: 321283,

                        label: "泰兴市",
                    },
                ],
            },

            {
                value: 321300,

                label: "宿迁市",

                children: [{
                        value: 321302,

                        label: "宿城区",
                    },

                    {
                        value: 321311,

                        label: "宿豫区",
                    },

                    {
                        value: 321322,

                        label: "沭阳县",
                    },

                    {
                        value: 321323,

                        label: "泗阳县",
                    },

                    {
                        value: 321324,

                        label: "泗洪县",
                    },
                ],
            },
        ],
    },

    {
        value: 330000,

        label: "浙江省",

        children: [{
                value: 330100,

                label: "杭州市",

                children: [{
                        value: 330102,

                        label: "上城区",
                    },

                    {
                        value: 330103,

                        label: "下城区",
                    },

                    {
                        value: 330104,

                        label: "江干区",
                    },

                    {
                        value: 330105,

                        label: "拱墅区",
                    },

                    {
                        value: 330106,

                        label: "西湖区",
                    },

                    {
                        value: 330108,

                        label: "滨江区",
                    },

                    {
                        value: 330109,

                        label: "萧山区",
                    },

                    {
                        value: 330110,

                        label: "余杭区",
                    },

                    {
                        value: 330111,

                        label: "富阳区",
                    },

                    {
                        value: 330112,

                        label: "临安区",
                    },

                    {
                        value: 330122,

                        label: "桐庐县",
                    },

                    {
                        value: 330127,

                        label: "淳安县",
                    },

                    {
                        value: 330182,

                        label: "建德市",
                    },
                ],
            },

            {
                value: 330200,

                label: "宁波市",

                children: [{
                        value: 330203,

                        label: "海曙区",
                    },

                    {
                        value: 330205,

                        label: "江北区",
                    },

                    {
                        value: 330206,

                        label: "北仑区",
                    },

                    {
                        value: 330211,

                        label: "镇海区",
                    },

                    {
                        value: 330212,

                        label: "鄞州区",
                    },

                    {
                        value: 330213,

                        label: "奉化区",
                    },

                    {
                        value: 330225,

                        label: "象山县",
                    },

                    {
                        value: 330226,

                        label: "宁海县",
                    },

                    {
                        value: 330281,

                        label: "余姚市",
                    },

                    {
                        value: 330282,

                        label: "慈溪市",
                    },
                ],
            },

            {
                value: 330300,

                label: "温州市",

                children: [{
                        value: 330302,

                        label: "鹿城区",
                    },

                    {
                        value: 330303,

                        label: "龙湾区",
                    },

                    {
                        value: 330304,

                        label: "瓯海区",
                    },

                    {
                        value: 330305,

                        label: "洞头区",
                    },

                    {
                        value: 330324,

                        label: "永嘉县",
                    },

                    {
                        value: 330326,

                        label: "平阳县",
                    },

                    {
                        value: 330327,

                        label: "苍南县",
                    },

                    {
                        value: 330328,

                        label: "文成县",
                    },

                    {
                        value: 330329,

                        label: "泰顺县",
                    },

                    {
                        value: 330381,

                        label: "瑞安市",
                    },

                    {
                        value: 330382,

                        label: "乐清市",
                    },
                ],
            },

            {
                value: 330400,

                label: "嘉兴市",

                children: [{
                        value: 330402,

                        label: "南湖区",
                    },

                    {
                        value: 330411,

                        label: "秀洲区",
                    },

                    {
                        value: 330421,

                        label: "嘉善县",
                    },

                    {
                        value: 330424,

                        label: "海盐县",
                    },

                    {
                        value: 330481,

                        label: "海宁市",
                    },

                    {
                        value: 330482,

                        label: "平湖市",
                    },

                    {
                        value: 330483,

                        label: "桐乡市",
                    },
                ],
            },

            {
                value: 330500,

                label: "湖州市",

                children: [{
                        value: 330502,

                        label: "吴兴区",
                    },

                    {
                        value: 330503,

                        label: "南浔区",
                    },

                    {
                        value: 330521,

                        label: "德清县",
                    },

                    {
                        value: 330522,

                        label: "长兴县",
                    },

                    {
                        value: 330523,

                        label: "安吉县",
                    },
                ],
            },

            {
                value: 330600,

                label: "绍兴市",

                children: [{
                        value: 330602,

                        label: "越城区",
                    },

                    {
                        value: 330603,

                        label: "柯桥区",
                    },

                    {
                        value: 330604,

                        label: "上虞区",
                    },

                    {
                        value: 330624,

                        label: "新昌县",
                    },

                    {
                        value: 330681,

                        label: "诸暨市",
                    },

                    {
                        value: 330683,

                        label: "嵊州市",
                    },
                ],
            },

            {
                value: 330700,

                label: "金华市",

                children: [{
                        value: 330702,

                        label: "婺城区",
                    },

                    {
                        value: 330703,

                        label: "金东区",
                    },

                    {
                        value: 330723,

                        label: "武义县",
                    },

                    {
                        value: 330726,

                        label: "浦江县",
                    },

                    {
                        value: 330727,

                        label: "磐安县",
                    },

                    {
                        value: 330781,

                        label: "兰溪市",
                    },

                    {
                        value: 330782,

                        label: "义乌市",
                    },

                    {
                        value: 330783,

                        label: "东阳市",
                    },

                    {
                        value: 330784,

                        label: "永康市",
                    },
                ],
            },

            {
                value: 330800,

                label: "衢州市",

                children: [{
                        value: 330802,

                        label: "柯城区",
                    },

                    {
                        value: 330803,

                        label: "衢江区",
                    },

                    {
                        value: 330822,

                        label: "常山县",
                    },

                    {
                        value: 330824,

                        label: "开化县",
                    },

                    {
                        value: 330825,

                        label: "龙游县",
                    },

                    {
                        value: 330881,

                        label: "江山市",
                    },
                ],
            },

            {
                value: 330900,

                label: "舟山市",

                children: [{
                        value: 330902,

                        label: "定海区",
                    },

                    {
                        value: 330903,

                        label: "普陀区",
                    },

                    {
                        value: 330921,

                        label: "岱山县",
                    },

                    {
                        value: 330922,

                        label: "嵊泗县",
                    },
                ],
            },

            {
                value: 331000,

                label: "台州市",

                children: [{
                        value: 331002,

                        label: "椒江区",
                    },

                    {
                        value: 331003,

                        label: "黄岩区",
                    },

                    {
                        value: 331004,

                        label: "路桥区",
                    },

                    {
                        value: 331022,

                        label: "三门县",
                    },

                    {
                        value: 331023,

                        label: "天台县",
                    },

                    {
                        value: 331024,

                        label: "仙居县",
                    },

                    {
                        value: 331081,

                        label: "温岭市",
                    },

                    {
                        value: 331082,

                        label: "临海市",
                    },

                    {
                        value: 331083,

                        label: "玉环市",
                    },
                ],
            },

            {
                value: 331100,

                label: "丽水市",

                children: [{
                        value: 331102,

                        label: "莲都区",
                    },

                    {
                        value: 331121,

                        label: "青田县",
                    },

                    {
                        value: 331122,

                        label: "缙云县",
                    },

                    {
                        value: 331123,

                        label: "遂昌县",
                    },

                    {
                        value: 331124,

                        label: "松阳县",
                    },

                    {
                        value: 331125,

                        label: "云和县",
                    },

                    {
                        value: 331126,

                        label: "庆元县",
                    },

                    {
                        value: 331127,

                        label: "景宁畲族自治县",
                    },

                    {
                        value: 331181,

                        label: "龙泉市",
                    },
                ],
            },
        ],
    },

    {
        value: 340000,

        label: "安徽省",

        children: [{
                value: 340100,

                label: "合肥市",

                children: [{
                        value: 340102,

                        label: "瑶海区",
                    },

                    {
                        value: 340103,

                        label: "庐阳区",
                    },

                    {
                        value: 340104,

                        label: "蜀山区",
                    },

                    {
                        value: 340111,

                        label: "包河区",
                    },

                    {
                        value: 340121,

                        label: "长丰县",
                    },

                    {
                        value: 340122,

                        label: "肥东县",
                    },

                    {
                        value: 340123,

                        label: "肥西县",
                    },

                    {
                        value: 340124,

                        label: "庐江县",
                    },

                    {
                        value: 340181,

                        label: "巢湖市",
                    },
                ],
            },

            {
                value: 340200,

                label: "芜湖市",

                children: [{
                        value: 340202,

                        label: "镜湖区",
                    },

                    {
                        value: 340203,

                        label: "弋江区",
                    },

                    {
                        value: 340207,

                        label: "鸠江区",
                    },

                    {
                        value: 340208,

                        label: "三山区",
                    },

                    {
                        value: 340221,

                        label: "芜湖县",
                    },

                    {
                        value: 340222,

                        label: "繁昌县",
                    },

                    {
                        value: 340223,

                        label: "南陵县",
                    },

                    {
                        value: 340225,

                        label: "无为县",
                    },
                ],
            },

            {
                value: 340300,

                label: "蚌埠市",

                children: [{
                        value: 340302,

                        label: "龙子湖区",
                    },

                    {
                        value: 340303,

                        label: "蚌山区",
                    },

                    {
                        value: 340304,

                        label: "禹会区",
                    },

                    {
                        value: 340311,

                        label: "淮上区",
                    },

                    {
                        value: 340321,

                        label: "怀远县",
                    },

                    {
                        value: 340322,

                        label: "五河县",
                    },

                    {
                        value: 340323,

                        label: "固镇县",
                    },
                ],
            },

            {
                value: 340400,

                label: "淮南市",

                children: [{
                        value: 340402,

                        label: "大通区",
                    },

                    {
                        value: 340403,

                        label: "田家庵区",
                    },

                    {
                        value: 340404,

                        label: "谢家集区",
                    },

                    {
                        value: 340405,

                        label: "八公山区",
                    },

                    {
                        value: 340406,

                        label: "潘集区",
                    },

                    {
                        value: 340421,

                        label: "凤台县",
                    },

                    {
                        value: 340422,

                        label: "寿县",
                    },
                ],
            },

            {
                value: 340500,

                label: "马鞍山市",

                children: [{
                        value: 340503,

                        label: "花山区",
                    },

                    {
                        value: 340504,

                        label: "雨山区",
                    },

                    {
                        value: 340506,

                        label: "博望区",
                    },

                    {
                        value: 340521,

                        label: "当涂县",
                    },

                    {
                        value: 340522,

                        label: "含山县",
                    },

                    {
                        value: 340523,

                        label: "和县",
                    },
                ],
            },

            {
                value: 340600,

                label: "淮北市",

                children: [{
                        value: 340602,

                        label: "杜集区",
                    },

                    {
                        value: 340603,

                        label: "相山区",
                    },

                    {
                        value: 340604,

                        label: "烈山区",
                    },

                    {
                        value: 340621,

                        label: "濉溪县",
                    },
                ],
            },

            {
                value: 340700,

                label: "铜陵市",

                children: [{
                        value: 340705,

                        label: "铜官区",
                    },

                    {
                        value: 340706,

                        label: "义安区",
                    },

                    {
                        value: 340711,

                        label: "郊区",
                    },

                    {
                        value: 340722,

                        label: "枞阳县",
                    },
                ],
            },

            {
                value: 340800,

                label: "安庆市",

                children: [{
                        value: 340802,

                        label: "迎江区",
                    },

                    {
                        value: 340803,

                        label: "大观区",
                    },

                    {
                        value: 340811,

                        label: "宜秀区",
                    },

                    {
                        value: 340822,

                        label: "怀宁县",
                    },

                    {
                        value: 340825,

                        label: "太湖县",
                    },

                    {
                        value: 340826,

                        label: "宿松县",
                    },

                    {
                        value: 340827,

                        label: "望江县",
                    },

                    {
                        value: 340828,

                        label: "岳西县",
                    },

                    {
                        value: 340881,

                        label: "桐城市",
                    },

                    {
                        value: 340882,

                        label: "潜山市",
                    },
                ],
            },

            {
                value: 341000,

                label: "黄山市",

                children: [{
                        value: 341002,

                        label: "屯溪区",
                    },

                    {
                        value: 341003,

                        label: "黄山区",
                    },

                    {
                        value: 341004,

                        label: "徽州区",
                    },

                    {
                        value: 341021,

                        label: "歙县",
                    },

                    {
                        value: 341022,

                        label: "休宁县",
                    },

                    {
                        value: 341023,

                        label: "黟县",
                    },

                    {
                        value: 341024,

                        label: "祁门县",
                    },
                ],
            },

            {
                value: 341100,

                label: "滁州市",

                children: [{
                        value: 341102,

                        label: "琅琊区",
                    },

                    {
                        value: 341103,

                        label: "南谯区",
                    },

                    {
                        value: 341122,

                        label: "来安县",
                    },

                    {
                        value: 341124,

                        label: "全椒县",
                    },

                    {
                        value: 341125,

                        label: "定远县",
                    },

                    {
                        value: 341126,

                        label: "凤阳县",
                    },

                    {
                        value: 341181,

                        label: "天长市",
                    },

                    {
                        value: 341182,

                        label: "明光市",
                    },
                ],
            },

            {
                value: 341200,

                label: "阜阳市",

                children: [{
                        value: 341202,

                        label: "颍州区",
                    },

                    {
                        value: 341203,

                        label: "颍东区",
                    },

                    {
                        value: 341204,

                        label: "颍泉区",
                    },

                    {
                        value: 341221,

                        label: "临泉县",
                    },

                    {
                        value: 341222,

                        label: "太和县",
                    },

                    {
                        value: 341225,

                        label: "阜南县",
                    },

                    {
                        value: 341226,

                        label: "颍上县",
                    },

                    {
                        value: 341282,

                        label: "界首市",
                    },
                ],
            },

            {
                value: 341300,

                label: "宿州市",

                children: [{
                        value: 341302,

                        label: "埇桥区",
                    },

                    {
                        value: 341321,

                        label: "砀山县",
                    },

                    {
                        value: 341322,

                        label: "萧县",
                    },

                    {
                        value: 341323,

                        label: "灵璧县",
                    },

                    {
                        value: 341324,

                        label: "泗县",
                    },
                ],
            },

            {
                value: 341500,

                label: "六安市",

                children: [{
                        value: 341502,

                        label: "金安区",
                    },

                    {
                        value: 341503,

                        label: "裕安区",
                    },

                    {
                        value: 341504,

                        label: "叶集区",
                    },

                    {
                        value: 341522,

                        label: "霍邱县",
                    },

                    {
                        value: 341523,

                        label: "舒城县",
                    },

                    {
                        value: 341524,

                        label: "金寨县",
                    },

                    {
                        value: 341525,

                        label: "霍山县",
                    },
                ],
            },

            {
                value: 341600,

                label: "亳州市",

                children: [{
                        value: 341602,

                        label: "谯城区",
                    },

                    {
                        value: 341621,

                        label: "涡阳县",
                    },

                    {
                        value: 341622,

                        label: "蒙城县",
                    },

                    {
                        value: 341623,

                        label: "利辛县",
                    },
                ],
            },

            {
                value: 341700,

                label: "池州市",

                children: [{
                        value: 341702,

                        label: "贵池区",
                    },

                    {
                        value: 341721,

                        label: "东至县",
                    },

                    {
                        value: 341722,

                        label: "石台县",
                    },

                    {
                        value: 341723,

                        label: "青阳县",
                    },
                ],
            },

            {
                value: 341800,

                label: "宣城市",

                children: [{
                        value: 341802,

                        label: "宣州区",
                    },

                    {
                        value: 341821,

                        label: "郎溪县",
                    },

                    {
                        value: 341822,

                        label: "广德县",
                    },

                    {
                        value: 341823,

                        label: "泾县",
                    },

                    {
                        value: 341824,

                        label: "绩溪县",
                    },

                    {
                        value: 341825,

                        label: "旌德县",
                    },

                    {
                        value: 341881,

                        label: "宁国市",
                    },
                ],
            },
        ],
    },

    {
        value: 350000,

        label: "福建省",

        children: [{
                value: 350100,

                label: "福州市",

                children: [{
                        value: 350102,

                        label: "鼓楼区",
                    },

                    {
                        value: 350103,

                        label: "台江区",
                    },

                    {
                        value: 350104,

                        label: "仓山区",
                    },

                    {
                        value: 350105,

                        label: "马尾区",
                    },

                    {
                        value: 350111,

                        label: "晋安区",
                    },

                    {
                        value: 350112,

                        label: "长乐区",
                    },

                    {
                        value: 350121,

                        label: "闽侯县",
                    },

                    {
                        value: 350122,

                        label: "连江县",
                    },

                    {
                        value: 350123,

                        label: "罗源县",
                    },

                    {
                        value: 350124,

                        label: "闽清县",
                    },

                    {
                        value: 350125,

                        label: "永泰县",
                    },

                    {
                        value: 350128,

                        label: "平潭县",
                    },

                    {
                        value: 350181,

                        label: "福清市",
                    },
                ],
            },

            {
                value: 350200,

                label: "厦门市",

                children: [{
                        value: 350203,

                        label: "思明区",
                    },

                    {
                        value: 350205,

                        label: "海沧区",
                    },

                    {
                        value: 350206,

                        label: "湖里区",
                    },

                    {
                        value: 350211,

                        label: "集美区",
                    },

                    {
                        value: 350212,

                        label: "同安区",
                    },

                    {
                        value: 350213,

                        label: "翔安区",
                    },
                ],
            },

            {
                value: 350300,

                label: "莆田市",

                children: [{
                        value: 350302,

                        label: "城厢区",
                    },

                    {
                        value: 350303,

                        label: "涵江区",
                    },

                    {
                        value: 350304,

                        label: "荔城区",
                    },

                    {
                        value: 350305,

                        label: "秀屿区",
                    },

                    {
                        value: 350322,

                        label: "仙游县",
                    },
                ],
            },

            {
                value: 350400,

                label: "三明市",

                children: [{
                        value: 350402,

                        label: "梅列区",
                    },

                    {
                        value: 350403,

                        label: "三元区",
                    },

                    {
                        value: 350421,

                        label: "明溪县",
                    },

                    {
                        value: 350423,

                        label: "清流县",
                    },

                    {
                        value: 350424,

                        label: "宁化县",
                    },

                    {
                        value: 350425,

                        label: "大田县",
                    },

                    {
                        value: 350426,

                        label: "尤溪县",
                    },

                    {
                        value: 350427,

                        label: "沙县",
                    },

                    {
                        value: 350428,

                        label: "将乐县",
                    },

                    {
                        value: 350429,

                        label: "泰宁县",
                    },

                    {
                        value: 350430,

                        label: "建宁县",
                    },

                    {
                        value: 350481,

                        label: "永安市",
                    },
                ],
            },

            {
                value: 350500,

                label: "泉州市",

                children: [{
                        value: 350502,

                        label: "鲤城区",
                    },

                    {
                        value: 350503,

                        label: "丰泽区",
                    },

                    {
                        value: 350504,

                        label: "洛江区",
                    },

                    {
                        value: 350505,

                        label: "泉港区",
                    },

                    {
                        value: 350521,

                        label: "惠安县",
                    },

                    {
                        value: 350524,

                        label: "安溪县",
                    },

                    {
                        value: 350525,

                        label: "永春县",
                    },

                    {
                        value: 350526,

                        label: "德化县",
                    },

                    {
                        value: 350527,

                        label: "金门县",
                    },

                    {
                        value: 350581,

                        label: "石狮市",
                    },

                    {
                        value: 350582,

                        label: "晋江市",
                    },

                    {
                        value: 350583,

                        label: "南安市",
                    },
                ],
            },

            {
                value: 350600,

                label: "漳州市",

                children: [{
                        value: 350602,

                        label: "芗城区",
                    },

                    {
                        value: 350603,

                        label: "龙文区",
                    },

                    {
                        value: 350622,

                        label: "云霄县",
                    },

                    {
                        value: 350623,

                        label: "漳浦县",
                    },

                    {
                        value: 350624,

                        label: "诏安县",
                    },

                    {
                        value: 350625,

                        label: "长泰县",
                    },

                    {
                        value: 350626,

                        label: "东山县",
                    },

                    {
                        value: 350627,

                        label: "南靖县",
                    },

                    {
                        value: 350628,

                        label: "平和县",
                    },

                    {
                        value: 350629,

                        label: "华安县",
                    },

                    {
                        value: 350681,

                        label: "龙海市",
                    },
                ],
            },

            {
                value: 350700,

                label: "南平市",

                children: [{
                        value: 350702,

                        label: "延平区",
                    },

                    {
                        value: 350703,

                        label: "建阳区",
                    },

                    {
                        value: 350721,

                        label: "顺昌县",
                    },

                    {
                        value: 350722,

                        label: "浦城县",
                    },

                    {
                        value: 350723,

                        label: "光泽县",
                    },

                    {
                        value: 350724,

                        label: "松溪县",
                    },

                    {
                        value: 350725,

                        label: "政和县",
                    },

                    {
                        value: 350781,

                        label: "邵武市",
                    },

                    {
                        value: 350782,

                        label: "武夷山市",
                    },

                    {
                        value: 350783,

                        label: "建瓯市",
                    },
                ],
            },

            {
                value: 350800,

                label: "龙岩市",

                children: [{
                        value: 350802,

                        label: "新罗区",
                    },

                    {
                        value: 350803,

                        label: "永定区",
                    },

                    {
                        value: 350821,

                        label: "长汀县",
                    },

                    {
                        value: 350823,

                        label: "上杭县",
                    },

                    {
                        value: 350824,

                        label: "武平县",
                    },

                    {
                        value: 350825,

                        label: "连城县",
                    },

                    {
                        value: 350881,

                        label: "漳平市",
                    },
                ],
            },

            {
                value: 350900,

                label: "宁德市",

                children: [{
                        value: 350902,

                        label: "蕉城区",
                    },

                    {
                        value: 350921,

                        label: "霞浦县",
                    },

                    {
                        value: 350922,

                        label: "古田县",
                    },

                    {
                        value: 350923,

                        label: "屏南县",
                    },

                    {
                        value: 350924,

                        label: "寿宁县",
                    },

                    {
                        value: 350925,

                        label: "周宁县",
                    },

                    {
                        value: 350926,

                        label: "柘荣县",
                    },

                    {
                        value: 350981,

                        label: "福安市",
                    },

                    {
                        value: 350982,

                        label: "福鼎市",
                    },
                ],
            },
        ],
    },

    {
        value: 360000,

        label: "江西省",

        children: [{
                value: 360100,

                label: "南昌市",

                children: [{
                        value: 360102,

                        label: "东湖区",
                    },

                    {
                        value: 360103,

                        label: "西湖区",
                    },

                    {
                        value: 360104,

                        label: "青云谱区",
                    },

                    {
                        value: 360105,

                        label: "湾里区",
                    },

                    {
                        value: 360111,

                        label: "青山湖区",
                    },

                    {
                        value: 360112,

                        label: "新建区",
                    },

                    {
                        value: 360121,

                        label: "南昌县",
                    },

                    {
                        value: 360123,

                        label: "安义县",
                    },

                    {
                        value: 360124,

                        label: "进贤县",
                    },
                ],
            },

            {
                value: 360200,

                label: "景德镇市",

                children: [{
                        value: 360202,

                        label: "昌江区",
                    },

                    {
                        value: 360203,

                        label: "珠山区",
                    },

                    {
                        value: 360222,

                        label: "浮梁县",
                    },

                    {
                        value: 360281,

                        label: "乐平市",
                    },
                ],
            },

            {
                value: 360300,

                label: "萍乡市",

                children: [{
                        value: 360302,

                        label: "安源区",
                    },

                    {
                        value: 360313,

                        label: "湘东区",
                    },

                    {
                        value: 360321,

                        label: "莲花县",
                    },

                    {
                        value: 360322,

                        label: "上栗县",
                    },

                    {
                        value: 360323,

                        label: "芦溪县",
                    },
                ],
            },

            {
                value: 360400,

                label: "九江市",

                children: [{
                        value: 360402,

                        label: "濂溪区",
                    },

                    {
                        value: 360403,

                        label: "浔阳区",
                    },

                    {
                        value: 360404,

                        label: "柴桑区",
                    },

                    {
                        value: 360423,

                        label: "武宁县",
                    },

                    {
                        value: 360424,

                        label: "修水县",
                    },

                    {
                        value: 360425,

                        label: "永修县",
                    },

                    {
                        value: 360426,

                        label: "德安县",
                    },

                    {
                        value: 360428,

                        label: "都昌县",
                    },

                    {
                        value: 360429,

                        label: "湖口县",
                    },

                    {
                        value: 360430,

                        label: "彭泽县",
                    },

                    {
                        value: 360481,

                        label: "瑞昌市",
                    },

                    {
                        value: 360482,

                        label: "共青城市",
                    },

                    {
                        value: 360483,

                        label: "庐山市",
                    },
                ],
            },

            {
                value: 360500,

                label: "新余市",

                children: [{
                        value: 360502,

                        label: "渝水区",
                    },

                    {
                        value: 360521,

                        label: "分宜县",
                    },
                ],
            },

            {
                value: 360600,

                label: "鹰潭市",

                children: [{
                        value: 360602,

                        label: "月湖区",
                    },

                    {
                        value: 360603,

                        label: "余江区",
                    },

                    {
                        value: 360681,

                        label: "贵溪市",
                    },
                ],
            },

            {
                value: 360700,

                label: "赣州市",

                children: [{
                        value: 360702,

                        label: "章贡区",
                    },

                    {
                        value: 360703,

                        label: "南康区",
                    },

                    {
                        value: 360704,

                        label: "赣县区",
                    },

                    {
                        value: 360722,

                        label: "信丰县",
                    },

                    {
                        value: 360723,

                        label: "大余县",
                    },

                    {
                        value: 360724,

                        label: "上犹县",
                    },

                    {
                        value: 360725,

                        label: "崇义县",
                    },

                    {
                        value: 360726,

                        label: "安远县",
                    },

                    {
                        value: 360727,

                        label: "龙南县",
                    },

                    {
                        value: 360728,

                        label: "定南县",
                    },

                    {
                        value: 360729,

                        label: "全南县",
                    },

                    {
                        value: 360730,

                        label: "宁都县",
                    },

                    {
                        value: 360731,

                        label: "于都县",
                    },

                    {
                        value: 360732,

                        label: "兴国县",
                    },

                    {
                        value: 360733,

                        label: "会昌县",
                    },

                    {
                        value: 360734,

                        label: "寻乌县",
                    },

                    {
                        value: 360735,

                        label: "石城县",
                    },

                    {
                        value: 360781,

                        label: "瑞金市",
                    },
                ],
            },

            {
                value: 360800,

                label: "吉安市",

                children: [{
                        value: 360802,

                        label: "吉州区",
                    },

                    {
                        value: 360803,

                        label: "青原区",
                    },

                    {
                        value: 360821,

                        label: "吉安县",
                    },

                    {
                        value: 360822,

                        label: "吉水县",
                    },

                    {
                        value: 360823,

                        label: "峡江县",
                    },

                    {
                        value: 360824,

                        label: "新干县",
                    },

                    {
                        value: 360825,

                        label: "永丰县",
                    },

                    {
                        value: 360826,

                        label: "泰和县",
                    },

                    {
                        value: 360827,

                        label: "遂川县",
                    },

                    {
                        value: 360828,

                        label: "万安县",
                    },

                    {
                        value: 360829,

                        label: "安福县",
                    },

                    {
                        value: 360830,

                        label: "永新县",
                    },

                    {
                        value: 360881,

                        label: "井冈山市",
                    },
                ],
            },

            {
                value: 360900,

                label: "宜春市",

                children: [{
                        value: 360902,

                        label: "袁州区",
                    },

                    {
                        value: 360921,

                        label: "奉新县",
                    },

                    {
                        value: 360922,

                        label: "万载县",
                    },

                    {
                        value: 360923,

                        label: "上高县",
                    },

                    {
                        value: 360924,

                        label: "宜丰县",
                    },

                    {
                        value: 360925,

                        label: "靖安县",
                    },

                    {
                        value: 360926,

                        label: "铜鼓县",
                    },

                    {
                        value: 360981,

                        label: "丰城市",
                    },

                    {
                        value: 360982,

                        label: "樟树市",
                    },

                    {
                        value: 360983,

                        label: "高安市",
                    },
                ],
            },

            {
                value: 361000,

                label: "抚州市",

                children: [{
                        value: 361002,

                        label: "临川区",
                    },

                    {
                        value: 361003,

                        label: "东乡区",
                    },

                    {
                        value: 361021,

                        label: "南城县",
                    },

                    {
                        value: 361022,

                        label: "黎川县",
                    },

                    {
                        value: 361023,

                        label: "南丰县",
                    },

                    {
                        value: 361024,

                        label: "崇仁县",
                    },

                    {
                        value: 361025,

                        label: "乐安县",
                    },

                    {
                        value: 361026,

                        label: "宜黄县",
                    },

                    {
                        value: 361027,

                        label: "金溪县",
                    },

                    {
                        value: 361028,

                        label: "资溪县",
                    },

                    {
                        value: 361030,

                        label: "广昌县",
                    },
                ],
            },

            {
                value: 361100,

                label: "上饶市",

                children: [{
                        value: 361102,

                        label: "信州区",
                    },

                    {
                        value: 361103,

                        label: "广丰区",
                    },

                    {
                        value: 361121,

                        label: "上饶县",
                    },

                    {
                        value: 361123,

                        label: "玉山县",
                    },

                    {
                        value: 361124,

                        label: "铅山县",
                    },

                    {
                        value: 361125,

                        label: "横峰县",
                    },

                    {
                        value: 361126,

                        label: "弋阳县",
                    },

                    {
                        value: 361127,

                        label: "余干县",
                    },

                    {
                        value: 361128,

                        label: "鄱阳县",
                    },

                    {
                        value: 361129,

                        label: "万年县",
                    },

                    {
                        value: 361130,

                        label: "婺源县",
                    },

                    {
                        value: 361181,

                        label: "德兴市",
                    },
                ],
            },
        ],
    },

    {
        value: 370000,

        label: "山东省",

        children: [{
                value: 370100,

                label: "济南市",

                children: [{
                        value: 370102,

                        label: "历下区",
                    },

                    {
                        value: 370103,

                        label: "市中区",
                    },

                    {
                        value: 370104,

                        label: "槐荫区",
                    },

                    {
                        value: 370105,

                        label: "天桥区",
                    },

                    {
                        value: 370112,

                        label: "历城区",
                    },

                    {
                        value: 370113,

                        label: "长清区",
                    },

                    {
                        value: 370114,

                        label: "章丘区",
                    },

                    {
                        value: 370115,

                        label: "济阳区",
                    },

                    {
                        value: 370116,

                        label: "莱芜区",
                    },

                    {
                        value: 370117,

                        label: "钢城区",
                    },

                    {
                        value: 370124,

                        label: "平阴县",
                    },

                    {
                        value: 370126,

                        label: "商河县",
                    },
                ],
            },

            {
                value: 370200,

                label: "青岛市",

                children: [{
                        value: 370202,

                        label: "市南区",
                    },

                    {
                        value: 370203,

                        label: "市北区",
                    },

                    {
                        value: 370211,

                        label: "黄岛区",
                    },

                    {
                        value: 370212,

                        label: "崂山区",
                    },

                    {
                        value: 370213,

                        label: "李沧区",
                    },

                    {
                        value: 370214,

                        label: "城阳区",
                    },

                    {
                        value: 370215,

                        label: "即墨区",
                    },

                    {
                        value: 370281,

                        label: "胶州市",
                    },

                    {
                        value: 370283,

                        label: "平度市",
                    },

                    {
                        value: 370285,

                        label: "莱西市",
                    },
                ],
            },

            {
                value: 370300,

                label: "淄博市",

                children: [{
                        value: 370302,

                        label: "淄川区",
                    },

                    {
                        value: 370303,

                        label: "张店区",
                    },

                    {
                        value: 370304,

                        label: "博山区",
                    },

                    {
                        value: 370305,

                        label: "临淄区",
                    },

                    {
                        value: 370306,

                        label: "周村区",
                    },

                    {
                        value: 370321,

                        label: "桓台县",
                    },

                    {
                        value: 370322,

                        label: "高青县",
                    },

                    {
                        value: 370323,

                        label: "沂源县",
                    },
                ],
            },

            {
                value: 370400,

                label: "枣庄市",

                children: [{
                        value: 370402,

                        label: "市中区",
                    },

                    {
                        value: 370403,

                        label: "薛城区",
                    },

                    {
                        value: 370404,

                        label: "峄城区",
                    },

                    {
                        value: 370405,

                        label: "台儿庄区",
                    },

                    {
                        value: 370406,

                        label: "山亭区",
                    },

                    {
                        value: 370481,

                        label: "滕州市",
                    },
                ],
            },

            {
                value: 370500,

                label: "东营市",

                children: [{
                        value: 370502,

                        label: "东营区",
                    },

                    {
                        value: 370503,

                        label: "河口区",
                    },

                    {
                        value: 370505,

                        label: "垦利区",
                    },

                    {
                        value: 370522,

                        label: "利津县",
                    },

                    {
                        value: 370523,

                        label: "广饶县",
                    },
                ],
            },

            {
                value: 370600,

                label: "烟台市",

                children: [{
                        value: 370602,

                        label: "芝罘区",
                    },

                    {
                        value: 370611,

                        label: "福山区",
                    },

                    {
                        value: 370612,

                        label: "牟平区",
                    },

                    {
                        value: 370613,

                        label: "莱山区",
                    },

                    {
                        value: 370634,

                        label: "长岛县",
                    },

                    {
                        value: 370681,

                        label: "龙口市",
                    },

                    {
                        value: 370682,

                        label: "莱阳市",
                    },

                    {
                        value: 370683,

                        label: "莱州市",
                    },

                    {
                        value: 370684,

                        label: "蓬莱市",
                    },

                    {
                        value: 370685,

                        label: "招远市",
                    },

                    {
                        value: 370686,

                        label: "栖霞市",
                    },

                    {
                        value: 370687,

                        label: "海阳市",
                    },
                ],
            },

            {
                value: 370700,

                label: "潍坊市",

                children: [{
                        value: 370702,

                        label: "潍城区",
                    },

                    {
                        value: 370703,

                        label: "寒亭区",
                    },

                    {
                        value: 370704,

                        label: "坊子区",
                    },

                    {
                        value: 370705,

                        label: "奎文区",
                    },

                    {
                        value: 370724,

                        label: "临朐县",
                    },

                    {
                        value: 370725,

                        label: "昌乐县",
                    },

                    {
                        value: 370781,

                        label: "青州市",
                    },

                    {
                        value: 370782,

                        label: "诸城市",
                    },

                    {
                        value: 370783,

                        label: "寿光市",
                    },

                    {
                        value: 370784,

                        label: "安丘市",
                    },

                    {
                        value: 370785,

                        label: "高密市",
                    },

                    {
                        value: 370786,

                        label: "昌邑市",
                    },
                ],
            },

            {
                value: 370800,

                label: "济宁市",

                children: [{
                        value: 370811,

                        label: "任城区",
                    },

                    {
                        value: 370812,

                        label: "兖州区",
                    },

                    {
                        value: 370826,

                        label: "微山县",
                    },

                    {
                        value: 370827,

                        label: "鱼台县",
                    },

                    {
                        value: 370828,

                        label: "金乡县",
                    },

                    {
                        value: 370829,

                        label: "嘉祥县",
                    },

                    {
                        value: 370830,

                        label: "汶上县",
                    },

                    {
                        value: 370831,

                        label: "泗水县",
                    },

                    {
                        value: 370832,

                        label: "梁山县",
                    },

                    {
                        value: 370881,

                        label: "曲阜市",
                    },

                    {
                        value: 370883,

                        label: "邹城市",
                    },
                ],
            },

            {
                value: 370900,

                label: "泰安市",

                children: [{
                        value: 370902,

                        label: "泰山区",
                    },

                    {
                        value: 370911,

                        label: "岱岳区",
                    },

                    {
                        value: 370921,

                        label: "宁阳县",
                    },

                    {
                        value: 370923,

                        label: "东平县",
                    },

                    {
                        value: 370982,

                        label: "新泰市",
                    },

                    {
                        value: 370983,

                        label: "肥城市",
                    },
                ],
            },

            {
                value: 371000,

                label: "威海市",

                children: [{
                        value: 371002,

                        label: "环翠区",
                    },

                    {
                        value: 371003,

                        label: "文登区",
                    },

                    {
                        value: 371082,

                        label: "荣成市",
                    },

                    {
                        value: 371083,

                        label: "乳山市",
                    },
                ],
            },

            {
                value: 371100,

                label: "日照市",

                children: [{
                        value: 371102,

                        label: "东港区",
                    },

                    {
                        value: 371103,

                        label: "岚山区",
                    },

                    {
                        value: 371121,

                        label: "五莲县",
                    },

                    {
                        value: 371122,

                        label: "莒县",
                    },
                ],
            },

            {
                value: 371300,

                label: "临沂市",

                children: [{
                        value: 371302,

                        label: "兰山区",
                    },

                    {
                        value: 371311,

                        label: "罗庄区",
                    },

                    {
                        value: 371312,

                        label: "河东区",
                    },

                    {
                        value: 371321,

                        label: "沂南县",
                    },

                    {
                        value: 371322,

                        label: "郯城县",
                    },

                    {
                        value: 371323,

                        label: "沂水县",
                    },

                    {
                        value: 371324,

                        label: "兰陵县",
                    },

                    {
                        value: 371325,

                        label: "费县",
                    },

                    {
                        value: 371326,

                        label: "平邑县",
                    },

                    {
                        value: 371327,

                        label: "莒南县",
                    },

                    {
                        value: 371328,

                        label: "蒙阴县",
                    },

                    {
                        value: 371329,

                        label: "临沭县",
                    },
                ],
            },

            {
                value: 371400,

                label: "德州市",

                children: [{
                        value: 371402,

                        label: "德城区",
                    },

                    {
                        value: 371403,

                        label: "陵城区",
                    },

                    {
                        value: 371422,

                        label: "宁津县",
                    },

                    {
                        value: 371423,

                        label: "庆云县",
                    },

                    {
                        value: 371424,

                        label: "临邑县",
                    },

                    {
                        value: 371425,

                        label: "齐河县",
                    },

                    {
                        value: 371426,

                        label: "平原县",
                    },

                    {
                        value: 371427,

                        label: "夏津县",
                    },

                    {
                        value: 371428,

                        label: "武城县",
                    },

                    {
                        value: 371481,

                        label: "乐陵市",
                    },

                    {
                        value: 371482,

                        label: "禹城市",
                    },
                ],
            },

            {
                value: 371500,

                label: "聊城市",

                children: [{
                        value: 371502,

                        label: "东昌府区",
                    },

                    {
                        value: 371521,

                        label: "阳谷县",
                    },

                    {
                        value: 371522,

                        label: "莘县",
                    },

                    {
                        value: 371523,

                        label: "茌平县",
                    },

                    {
                        value: 371524,

                        label: "东阿县",
                    },

                    {
                        value: 371525,

                        label: "冠县",
                    },

                    {
                        value: 371526,

                        label: "高唐县",
                    },

                    {
                        value: 371581,

                        label: "临清市",
                    },
                ],
            },

            {
                value: 371600,

                label: "滨州市",

                children: [{
                        value: 371602,

                        label: "滨城区",
                    },

                    {
                        value: 371603,

                        label: "沾化区",
                    },

                    {
                        value: 371621,

                        label: "惠民县",
                    },

                    {
                        value: 371622,

                        label: "阳信县",
                    },

                    {
                        value: 371623,

                        label: "无棣县",
                    },

                    {
                        value: 371625,

                        label: "博兴县",
                    },

                    {
                        value: 371681,

                        label: "邹平市",
                    },
                ],
            },

            {
                value: 371700,

                label: "菏泽市",

                children: [{
                        value: 371702,

                        label: "牡丹区",
                    },

                    {
                        value: 371703,

                        label: "定陶区",
                    },

                    {
                        value: 371721,

                        label: "曹县",
                    },

                    {
                        value: 371722,

                        label: "单县",
                    },

                    {
                        value: 371723,

                        label: "成武县",
                    },

                    {
                        value: 371724,

                        label: "巨野县",
                    },

                    {
                        value: 371725,

                        label: "郓城县",
                    },

                    {
                        value: 371726,

                        label: "鄄城县",
                    },

                    {
                        value: 371728,

                        label: "东明县",
                    },
                ],
            },
        ],
    },

    {
        value: 410000,

        label: "河南省",

        children: [{
                value: 410100,

                label: "郑州市",

                children: [{
                        value: 410102,

                        label: "中原区",
                    },

                    {
                        value: 410103,

                        label: "二七区",
                    },

                    {
                        value: 410104,

                        label: "管城回族区",
                    },

                    {
                        value: 410105,

                        label: "金水区",
                    },

                    {
                        value: 410106,

                        label: "上街区",
                    },

                    {
                        value: 410108,

                        label: "惠济区",
                    },

                    {
                        value: 410122,

                        label: "中牟县",
                    },

                    {
                        value: 410181,

                        label: "巩义市",
                    },

                    {
                        value: 410182,

                        label: "荥阳市",
                    },

                    {
                        value: 410183,

                        label: "新密市",
                    },

                    {
                        value: 410184,

                        label: "新郑市",
                    },

                    {
                        value: 410185,

                        label: "登封市",
                    },
                ],
            },

            {
                value: 410200,

                label: "开封市",

                children: [{
                        value: 410202,

                        label: "龙亭区",
                    },

                    {
                        value: 410203,

                        label: "顺河回族区",
                    },

                    {
                        value: 410204,

                        label: "鼓楼区",
                    },

                    {
                        value: 410205,

                        label: "禹王台区",
                    },

                    {
                        value: 410212,

                        label: "祥符区",
                    },

                    {
                        value: 410221,

                        label: "杞县",
                    },

                    {
                        value: 410222,

                        label: "通许县",
                    },

                    {
                        value: 410223,

                        label: "尉氏县",
                    },

                    {
                        value: 410225,

                        label: "兰考县",
                    },
                ],
            },

            {
                value: 410300,

                label: "洛阳市",

                children: [{
                        value: 410302,

                        label: "老城区",
                    },

                    {
                        value: 410303,

                        label: "西工区",
                    },

                    {
                        value: 410304,

                        label: "瀍河回族区",
                    },

                    {
                        value: 410305,

                        label: "涧西区",
                    },

                    {
                        value: 410306,

                        label: "吉利区",
                    },

                    {
                        value: 410311,

                        label: "洛龙区",
                    },

                    {
                        value: 410322,

                        label: "孟津县",
                    },

                    {
                        value: 410323,

                        label: "新安县",
                    },

                    {
                        value: 410324,

                        label: "栾川县",
                    },

                    {
                        value: 410325,

                        label: "嵩县",
                    },

                    {
                        value: 410326,

                        label: "汝阳县",
                    },

                    {
                        value: 410327,

                        label: "宜阳县",
                    },

                    {
                        value: 410328,

                        label: "洛宁县",
                    },

                    {
                        value: 410329,

                        label: "伊川县",
                    },

                    {
                        value: 410381,

                        label: "偃师市",
                    },
                ],
            },

            {
                value: 410400,

                label: "平顶山市",

                children: [{
                        value: 410402,

                        label: "新华区",
                    },

                    {
                        value: 410403,

                        label: "卫东区",
                    },

                    {
                        value: 410404,

                        label: "石龙区",
                    },

                    {
                        value: 410411,

                        label: "湛河区",
                    },

                    {
                        value: 410421,

                        label: "宝丰县",
                    },

                    {
                        value: 410422,

                        label: "叶县",
                    },

                    {
                        value: 410423,

                        label: "鲁山县",
                    },

                    {
                        value: 410425,

                        label: "郏县",
                    },

                    {
                        value: 410481,

                        label: "舞钢市",
                    },

                    {
                        value: 410482,

                        label: "汝州市",
                    },
                ],
            },

            {
                value: 410500,

                label: "安阳市",

                children: [{
                        value: 410502,

                        label: "文峰区",
                    },

                    {
                        value: 410503,

                        label: "北关区",
                    },

                    {
                        value: 410505,

                        label: "殷都区",
                    },

                    {
                        value: 410506,

                        label: "龙安区",
                    },

                    {
                        value: 410522,

                        label: "安阳县",
                    },

                    {
                        value: 410523,

                        label: "汤阴县",
                    },

                    {
                        value: 410526,

                        label: "滑县",
                    },

                    {
                        value: 410527,

                        label: "内黄县",
                    },

                    {
                        value: 410581,

                        label: "林州市",
                    },
                ],
            },

            {
                value: 410600,

                label: "鹤壁市",

                children: [{
                        value: 410602,

                        label: "鹤山区",
                    },

                    {
                        value: 410603,

                        label: "山城区",
                    },

                    {
                        value: 410611,

                        label: "淇滨区",
                    },

                    {
                        value: 410621,

                        label: "浚县",
                    },

                    {
                        value: 410622,

                        label: "淇县",
                    },
                ],
            },

            {
                value: 410700,

                label: "新乡市",

                children: [{
                        value: 410702,

                        label: "红旗区",
                    },

                    {
                        value: 410703,

                        label: "卫滨区",
                    },

                    {
                        value: 410704,

                        label: "凤泉区",
                    },

                    {
                        value: 410711,

                        label: "牧野区",
                    },

                    {
                        value: 410721,

                        label: "新乡县",
                    },

                    {
                        value: 410724,

                        label: "获嘉县",
                    },

                    {
                        value: 410725,

                        label: "原阳县",
                    },

                    {
                        value: 410726,

                        label: "延津县",
                    },

                    {
                        value: 410727,

                        label: "封丘县",
                    },

                    {
                        value: 410728,

                        label: "长垣县",
                    },

                    {
                        value: 410781,

                        label: "卫辉市",
                    },

                    {
                        value: 410782,

                        label: "辉县市",
                    },
                ],
            },

            {
                value: 410800,

                label: "焦作市",

                children: [{
                        value: 410802,

                        label: "解放区",
                    },

                    {
                        value: 410803,

                        label: "中站区",
                    },

                    {
                        value: 410804,

                        label: "马村区",
                    },

                    {
                        value: 410811,

                        label: "山阳区",
                    },

                    {
                        value: 410821,

                        label: "修武县",
                    },

                    {
                        value: 410822,

                        label: "博爱县",
                    },

                    {
                        value: 410823,

                        label: "武陟县",
                    },

                    {
                        value: 410825,

                        label: "温县",
                    },

                    {
                        value: 410882,

                        label: "沁阳市",
                    },

                    {
                        value: 410883,

                        label: "孟州市",
                    },
                ],
            },

            {
                value: 410900,

                label: "濮阳市",

                children: [{
                        value: 410902,

                        label: "华龙区",
                    },

                    {
                        value: 410922,

                        label: "清丰县",
                    },

                    {
                        value: 410923,

                        label: "南乐县",
                    },

                    {
                        value: 410926,

                        label: "范县",
                    },

                    {
                        value: 410927,

                        label: "台前县",
                    },

                    {
                        value: 410928,

                        label: "濮阳县",
                    },
                ],
            },

            {
                value: 411000,

                label: "许昌市",

                children: [{
                        value: 411002,

                        label: "魏都区",
                    },

                    {
                        value: 411003,

                        label: "建安区",
                    },

                    {
                        value: 411024,

                        label: "鄢陵县",
                    },

                    {
                        value: 411025,

                        label: "襄城县",
                    },

                    {
                        value: 411081,

                        label: "禹州市",
                    },

                    {
                        value: 411082,

                        label: "长葛市",
                    },
                ],
            },

            {
                value: 411100,

                label: "漯河市",

                children: [{
                        value: 411102,

                        label: "源汇区",
                    },

                    {
                        value: 411103,

                        label: "郾城区",
                    },

                    {
                        value: 411104,

                        label: "召陵区",
                    },

                    {
                        value: 411121,

                        label: "舞阳县",
                    },

                    {
                        value: 411122,

                        label: "临颍县",
                    },
                ],
            },

            {
                value: 411200,

                label: "三门峡市",

                children: [{
                        value: 411202,

                        label: "湖滨区",
                    },

                    {
                        value: 411203,

                        label: "陕州区",
                    },

                    {
                        value: 411221,

                        label: "渑池县",
                    },

                    {
                        value: 411224,

                        label: "卢氏县",
                    },

                    {
                        value: 411281,

                        label: "义马市",
                    },

                    {
                        value: 411282,

                        label: "灵宝市",
                    },
                ],
            },

            {
                value: 411300,

                label: "南阳市",

                children: [{
                        value: 411302,

                        label: "宛城区",
                    },

                    {
                        value: 411303,

                        label: "卧龙区",
                    },

                    {
                        value: 411321,

                        label: "南召县",
                    },

                    {
                        value: 411322,

                        label: "方城县",
                    },

                    {
                        value: 411323,

                        label: "西峡县",
                    },

                    {
                        value: 411324,

                        label: "镇平县",
                    },

                    {
                        value: 411325,

                        label: "内乡县",
                    },

                    {
                        value: 411326,

                        label: "淅川县",
                    },

                    {
                        value: 411327,

                        label: "社旗县",
                    },

                    {
                        value: 411328,

                        label: "唐河县",
                    },

                    {
                        value: 411329,

                        label: "新野县",
                    },

                    {
                        value: 411330,

                        label: "桐柏县",
                    },

                    {
                        value: 411381,

                        label: "邓州市",
                    },
                ],
            },

            {
                value: 411400,

                label: "商丘市",

                children: [{
                        value: 411402,

                        label: "梁园区",
                    },

                    {
                        value: 411403,

                        label: "睢阳区",
                    },

                    {
                        value: 411421,

                        label: "民权县",
                    },

                    {
                        value: 411422,

                        label: "睢县",
                    },

                    {
                        value: 411423,

                        label: "宁陵县",
                    },

                    {
                        value: 411424,

                        label: "柘城县",
                    },

                    {
                        value: 411425,

                        label: "虞城县",
                    },

                    {
                        value: 411426,

                        label: "夏邑县",
                    },

                    {
                        value: 411481,

                        label: "永城市",
                    },
                ],
            },

            {
                value: 411500,

                label: "信阳市",

                children: [{
                        value: 411502,

                        label: "浉河区",
                    },

                    {
                        value: 411503,

                        label: "平桥区",
                    },

                    {
                        value: 411521,

                        label: "罗山县",
                    },

                    {
                        value: 411522,

                        label: "光山县",
                    },

                    {
                        value: 411523,

                        label: "新县",
                    },

                    {
                        value: 411524,

                        label: "商城县",
                    },

                    {
                        value: 411525,

                        label: "固始县",
                    },

                    {
                        value: 411526,

                        label: "潢川县",
                    },

                    {
                        value: 411527,

                        label: "淮滨县",
                    },

                    {
                        value: 411528,

                        label: "息县",
                    },
                ],
            },

            {
                value: 411600,

                label: "周口市",

                children: [{
                        value: 411602,

                        label: "川汇区",
                    },

                    {
                        value: 411621,

                        label: "扶沟县",
                    },

                    {
                        value: 411622,

                        label: "西华县",
                    },

                    {
                        value: 411623,

                        label: "商水县",
                    },

                    {
                        value: 411624,

                        label: "沈丘县",
                    },

                    {
                        value: 411625,

                        label: "郸城县",
                    },

                    {
                        value: 411626,

                        label: "淮阳县",
                    },

                    {
                        value: 411627,

                        label: "太康县",
                    },

                    {
                        value: 411628,

                        label: "鹿邑县",
                    },

                    {
                        value: 411681,

                        label: "项城市",
                    },
                ],
            },

            {
                value: 411700,

                label: "驻马店市",

                children: [{
                        value: 411702,

                        label: "驿城区",
                    },

                    {
                        value: 411721,

                        label: "西平县",
                    },

                    {
                        value: 411722,

                        label: "上蔡县",
                    },

                    {
                        value: 411723,

                        label: "平舆县",
                    },

                    {
                        value: 411724,

                        label: "正阳县",
                    },

                    {
                        value: 411725,

                        label: "确山县",
                    },

                    {
                        value: 411726,

                        label: "泌阳县",
                    },

                    {
                        value: 411727,

                        label: "汝南县",
                    },

                    {
                        value: 411728,

                        label: "遂平县",
                    },

                    {
                        value: 411729,

                        label: "新蔡县",
                    },
                ],
            },

            {
                value: 419001,

                label: "济源市",

                children: [{
                    value: 4190010,

                    label: "市辖区",
                }, ],
            },
        ],
    },

    {
        value: 420000,

        label: "湖北省",

        children: [{
                value: 420100,

                label: "武汉市",

                children: [{
                        value: 420102,

                        label: "江岸区",
                    },

                    {
                        value: 420103,

                        label: "江汉区",
                    },

                    {
                        value: 420104,

                        label: "硚口区",
                    },

                    {
                        value: 420105,

                        label: "汉阳区",
                    },

                    {
                        value: 420106,

                        label: "武昌区",
                    },

                    {
                        value: 420107,

                        label: "青山区",
                    },

                    {
                        value: 420111,

                        label: "洪山区",
                    },

                    {
                        value: 420112,

                        label: "东西湖区",
                    },

                    {
                        value: 420113,

                        label: "汉南区",
                    },

                    {
                        value: 420114,

                        label: "蔡甸区",
                    },

                    {
                        value: 420115,

                        label: "江夏区",
                    },

                    {
                        value: 420116,

                        label: "黄陂区",
                    },

                    {
                        value: 420117,

                        label: "新洲区",
                    },
                ],
            },

            {
                value: 420200,

                label: "黄石市",

                children: [{
                        value: 420202,

                        label: "黄石港区",
                    },

                    {
                        value: 420203,

                        label: "西塞山区",
                    },

                    {
                        value: 420204,

                        label: "下陆区",
                    },

                    {
                        value: 420205,

                        label: "铁山区",
                    },

                    {
                        value: 420222,

                        label: "阳新县",
                    },

                    {
                        value: 420281,

                        label: "大冶市",
                    },
                ],
            },

            {
                value: 420300,

                label: "十堰市",

                children: [{
                        value: 420302,

                        label: "茅箭区",
                    },

                    {
                        value: 420303,

                        label: "张湾区",
                    },

                    {
                        value: 420304,

                        label: "郧阳区",
                    },

                    {
                        value: 420322,

                        label: "郧西县",
                    },

                    {
                        value: 420323,

                        label: "竹山县",
                    },

                    {
                        value: 420324,

                        label: "竹溪县",
                    },

                    {
                        value: 420325,

                        label: "房县",
                    },

                    {
                        value: 420381,

                        label: "丹江口市",
                    },
                ],
            },

            {
                value: 420500,

                label: "宜昌市",

                children: [{
                        value: 420502,

                        label: "西陵区",
                    },

                    {
                        value: 420503,

                        label: "伍家岗区",
                    },

                    {
                        value: 420504,

                        label: "点军区",
                    },

                    {
                        value: 420505,

                        label: "猇亭区",
                    },

                    {
                        value: 420506,

                        label: "夷陵区",
                    },

                    {
                        value: 420525,

                        label: "远安县",
                    },

                    {
                        value: 420526,

                        label: "兴山县",
                    },

                    {
                        value: 420527,

                        label: "秭归县",
                    },

                    {
                        value: 420528,

                        label: "长阳土家族自治县",
                    },

                    {
                        value: 420529,

                        label: "五峰土家族自治县",
                    },

                    {
                        value: 420581,

                        label: "宜都市",
                    },

                    {
                        value: 420582,

                        label: "当阳市",
                    },

                    {
                        value: 420583,

                        label: "枝江市",
                    },
                ],
            },

            {
                value: 420600,

                label: "襄阳市",

                children: [{
                        value: 420602,

                        label: "襄城区",
                    },

                    {
                        value: 420606,

                        label: "樊城区",
                    },

                    {
                        value: 420607,

                        label: "襄州区",
                    },

                    {
                        value: 420624,

                        label: "南漳县",
                    },

                    {
                        value: 420625,

                        label: "谷城县",
                    },

                    {
                        value: 420626,

                        label: "保康县",
                    },

                    {
                        value: 420682,

                        label: "老河口市",
                    },

                    {
                        value: 420683,

                        label: "枣阳市",
                    },

                    {
                        value: 420684,

                        label: "宜城市",
                    },
                ],
            },

            {
                value: 420700,

                label: "鄂州市",

                children: [{
                        value: 420702,

                        label: "梁子湖区",
                    },

                    {
                        value: 420703,

                        label: "华容区",
                    },

                    {
                        value: 420704,

                        label: "鄂城区",
                    },
                ],
            },

            {
                value: 420800,

                label: "荆门市",

                children: [{
                        value: 420802,

                        label: "东宝区",
                    },

                    {
                        value: 420804,

                        label: "掇刀区",
                    },

                    {
                        value: 420822,

                        label: "沙洋县",
                    },

                    {
                        value: 420881,

                        label: "钟祥市",
                    },

                    {
                        value: 420882,

                        label: "京山市",
                    },
                ],
            },

            {
                value: 420900,

                label: "孝感市",

                children: [{
                        value: 420902,

                        label: "孝南区",
                    },

                    {
                        value: 420921,

                        label: "孝昌县",
                    },

                    {
                        value: 420922,

                        label: "大悟县",
                    },

                    {
                        value: 420923,

                        label: "云梦县",
                    },

                    {
                        value: 420981,

                        label: "应城市",
                    },

                    {
                        value: 420982,

                        label: "安陆市",
                    },

                    {
                        value: 420984,

                        label: "汉川市",
                    },
                ],
            },

            {
                value: 421000,

                label: "荆州市",

                children: [{
                        value: 421002,

                        label: "沙市区",
                    },

                    {
                        value: 421003,

                        label: "荆州区",
                    },

                    {
                        value: 421022,

                        label: "公安县",
                    },

                    {
                        value: 421023,

                        label: "监利县",
                    },

                    {
                        value: 421024,

                        label: "江陵县",
                    },

                    {
                        value: 421081,

                        label: "石首市",
                    },

                    {
                        value: 421083,

                        label: "洪湖市",
                    },

                    {
                        value: 421087,

                        label: "松滋市",
                    },
                ],
            },

            {
                value: 421100,

                label: "黄冈市",

                children: [{
                        value: 421102,

                        label: "黄州区",
                    },

                    {
                        value: 421121,

                        label: "团风县",
                    },

                    {
                        value: 421122,

                        label: "红安县",
                    },

                    {
                        value: 421123,

                        label: "罗田县",
                    },

                    {
                        value: 421124,

                        label: "英山县",
                    },

                    {
                        value: 421125,

                        label: "浠水县",
                    },

                    {
                        value: 421126,

                        label: "蕲春县",
                    },

                    {
                        value: 421127,

                        label: "黄梅县",
                    },

                    {
                        value: 421181,

                        label: "麻城市",
                    },

                    {
                        value: 421182,

                        label: "武穴市",
                    },
                ],
            },

            {
                value: 421200,

                label: "咸宁市",

                children: [{
                        value: 421202,

                        label: "咸安区",
                    },

                    {
                        value: 421221,

                        label: "嘉鱼县",
                    },

                    {
                        value: 421222,

                        label: "通城县",
                    },

                    {
                        value: 421223,

                        label: "崇阳县",
                    },

                    {
                        value: 421224,

                        label: "通山县",
                    },

                    {
                        value: 421281,

                        label: "赤壁市",
                    },
                ],
            },

            {
                value: 421300,

                label: "随州市",

                children: [{
                        value: 421303,

                        label: "曾都区",
                    },

                    {
                        value: 421321,

                        label: "随县",
                    },

                    {
                        value: 421381,

                        label: "广水市",
                    },
                ],
            },

            {
                value: 422800,

                label: "恩施土家族苗族自治州",

                children: [{
                        value: 422801,

                        label: "恩施市",
                    },

                    {
                        value: 422802,

                        label: "利川市",
                    },

                    {
                        value: 422822,

                        label: "建始县",
                    },

                    {
                        value: 422823,

                        label: "巴东县",
                    },

                    {
                        value: 422825,

                        label: "宣恩县",
                    },

                    {
                        value: 422826,

                        label: "咸丰县",
                    },

                    {
                        value: 422827,

                        label: "来凤县",
                    },

                    {
                        value: 422828,

                        label: "鹤峰县",
                    },
                ],
            },

            {
                value: 429004,

                label: "仙桃市",

                children: [{
                    value: 4290040,

                    label: "市辖区",
                }, ],
            },

            {
                value: 429005,

                label: "潜江市",

                children: [{
                    value: 4290050,

                    label: "市辖区",
                }, ],
            },

            {
                value: 429006,

                label: "天门市",

                children: [{
                    value: 4290060,

                    label: "市辖区",
                }, ],
            },

            {
                value: 429021,

                label: "神农架林区",

                children: [{
                    value: 4290210,

                    label: "市辖区",
                }, ],
            },
        ],
    },

    {
        value: 430000,

        label: "湖南省",

        children: [{
                value: 430100,

                label: "长沙市",

                children: [{
                        value: 430102,

                        label: "芙蓉区",
                    },

                    {
                        value: 430103,

                        label: "天心区",
                    },

                    {
                        value: 430104,

                        label: "岳麓区",
                    },

                    {
                        value: 430105,

                        label: "开福区",
                    },

                    {
                        value: 430111,

                        label: "雨花区",
                    },

                    {
                        value: 430112,

                        label: "望城区",
                    },

                    {
                        value: 430121,

                        label: "长沙县",
                    },

                    {
                        value: 430181,

                        label: "浏阳市",
                    },

                    {
                        value: 430182,

                        label: "宁乡市",
                    },
                ],
            },

            {
                value: 430200,

                label: "株洲市",

                children: [{
                        value: 430202,

                        label: "荷塘区",
                    },

                    {
                        value: 430203,

                        label: "芦淞区",
                    },

                    {
                        value: 430204,

                        label: "石峰区",
                    },

                    {
                        value: 430211,

                        label: "天元区",
                    },

                    {
                        value: 430212,

                        label: "渌口区",
                    },

                    {
                        value: 430223,

                        label: "攸县",
                    },

                    {
                        value: 430224,

                        label: "茶陵县",
                    },

                    {
                        value: 430225,

                        label: "炎陵县",
                    },

                    {
                        value: 430281,

                        label: "醴陵市",
                    },
                ],
            },

            {
                value: 430300,

                label: "湘潭市",

                children: [{
                        value: 430302,

                        label: "雨湖区",
                    },

                    {
                        value: 430304,

                        label: "岳塘区",
                    },

                    {
                        value: 430321,

                        label: "湘潭县",
                    },

                    {
                        value: 430381,

                        label: "湘乡市",
                    },

                    {
                        value: 430382,

                        label: "韶山市",
                    },
                ],
            },

            {
                value: 430400,

                label: "衡阳市",

                children: [{
                        value: 430405,

                        label: "珠晖区",
                    },

                    {
                        value: 430406,

                        label: "雁峰区",
                    },

                    {
                        value: 430407,

                        label: "石鼓区",
                    },

                    {
                        value: 430408,

                        label: "蒸湘区",
                    },

                    {
                        value: 430412,

                        label: "南岳区",
                    },

                    {
                        value: 430421,

                        label: "衡阳县",
                    },

                    {
                        value: 430422,

                        label: "衡南县",
                    },

                    {
                        value: 430423,

                        label: "衡山县",
                    },

                    {
                        value: 430424,

                        label: "衡东县",
                    },

                    {
                        value: 430426,

                        label: "祁东县",
                    },

                    {
                        value: 430481,

                        label: "耒阳市",
                    },

                    {
                        value: 430482,

                        label: "常宁市",
                    },
                ],
            },

            {
                value: 430500,

                label: "邵阳市",

                children: [{
                        value: 430502,

                        label: "双清区",
                    },

                    {
                        value: 430503,

                        label: "大祥区",
                    },

                    {
                        value: 430511,

                        label: "北塔区",
                    },

                    {
                        value: 430521,

                        label: "邵东县",
                    },

                    {
                        value: 430522,

                        label: "新邵县",
                    },

                    {
                        value: 430523,

                        label: "邵阳县",
                    },

                    {
                        value: 430524,

                        label: "隆回县",
                    },

                    {
                        value: 430525,

                        label: "洞口县",
                    },

                    {
                        value: 430527,

                        label: "绥宁县",
                    },

                    {
                        value: 430528,

                        label: "新宁县",
                    },

                    {
                        value: 430529,

                        label: "城步苗族自治县",
                    },

                    {
                        value: 430581,

                        label: "武冈市",
                    },
                ],
            },

            {
                value: 430600,

                label: "岳阳市",

                children: [{
                        value: 430602,

                        label: "岳阳楼区",
                    },

                    {
                        value: 430603,

                        label: "云溪区",
                    },

                    {
                        value: 430611,

                        label: "君山区",
                    },

                    {
                        value: 430621,

                        label: "岳阳县",
                    },

                    {
                        value: 430623,

                        label: "华容县",
                    },

                    {
                        value: 430624,

                        label: "湘阴县",
                    },

                    {
                        value: 430626,

                        label: "平江县",
                    },

                    {
                        value: 430681,

                        label: "汨罗市",
                    },

                    {
                        value: 430682,

                        label: "临湘市",
                    },
                ],
            },

            {
                value: 430700,

                label: "常德市",

                children: [{
                        value: 430702,

                        label: "武陵区",
                    },

                    {
                        value: 430703,

                        label: "鼎城区",
                    },

                    {
                        value: 430721,

                        label: "安乡县",
                    },

                    {
                        value: 430722,

                        label: "汉寿县",
                    },

                    {
                        value: 430723,

                        label: "澧县",
                    },

                    {
                        value: 430724,

                        label: "临澧县",
                    },

                    {
                        value: 430725,

                        label: "桃源县",
                    },

                    {
                        value: 430726,

                        label: "石门县",
                    },

                    {
                        value: 430781,

                        label: "津市市",
                    },
                ],
            },

            {
                value: 430800,

                label: "张家界市",

                children: [{
                        value: 430802,

                        label: "永定区",
                    },

                    {
                        value: 430811,

                        label: "武陵源区",
                    },

                    {
                        value: 430821,

                        label: "慈利县",
                    },

                    {
                        value: 430822,

                        label: "桑植县",
                    },
                ],
            },

            {
                value: 430900,

                label: "益阳市",

                children: [{
                        value: 430902,

                        label: "资阳区",
                    },

                    {
                        value: 430903,

                        label: "赫山区",
                    },

                    {
                        value: 430921,

                        label: "南县",
                    },

                    {
                        value: 430922,

                        label: "桃江县",
                    },

                    {
                        value: 430923,

                        label: "安化县",
                    },

                    {
                        value: 430981,

                        label: "沅江市",
                    },
                ],
            },

            {
                value: 431000,

                label: "郴州市",

                children: [{
                        value: 431002,

                        label: "北湖区",
                    },

                    {
                        value: 431003,

                        label: "苏仙区",
                    },

                    {
                        value: 431021,

                        label: "桂阳县",
                    },

                    {
                        value: 431022,

                        label: "宜章县",
                    },

                    {
                        value: 431023,

                        label: "永兴县",
                    },

                    {
                        value: 431024,

                        label: "嘉禾县",
                    },

                    {
                        value: 431025,

                        label: "临武县",
                    },

                    {
                        value: 431026,

                        label: "汝城县",
                    },

                    {
                        value: 431027,

                        label: "桂东县",
                    },

                    {
                        value: 431028,

                        label: "安仁县",
                    },

                    {
                        value: 431081,

                        label: "资兴市",
                    },
                ],
            },

            {
                value: 431100,

                label: "永州市",

                children: [{
                        value: 431102,

                        label: "零陵区",
                    },

                    {
                        value: 431103,

                        label: "冷水滩区",
                    },

                    {
                        value: 431121,

                        label: "祁阳县",
                    },

                    {
                        value: 431122,

                        label: "东安县",
                    },

                    {
                        value: 431123,

                        label: "双牌县",
                    },

                    {
                        value: 431124,

                        label: "道县",
                    },

                    {
                        value: 431125,

                        label: "江永县",
                    },

                    {
                        value: 431126,

                        label: "宁远县",
                    },

                    {
                        value: 431127,

                        label: "蓝山县",
                    },

                    {
                        value: 431128,

                        label: "新田县",
                    },

                    {
                        value: 431129,

                        label: "江华瑶族自治县",
                    },
                ],
            },

            {
                value: 431200,

                label: "怀化市",

                children: [{
                        value: 431202,

                        label: "鹤城区",
                    },

                    {
                        value: 431221,

                        label: "中方县",
                    },

                    {
                        value: 431222,

                        label: "沅陵县",
                    },

                    {
                        value: 431223,

                        label: "辰溪县",
                    },

                    {
                        value: 431224,

                        label: "溆浦县",
                    },

                    {
                        value: 431225,

                        label: "会同县",
                    },

                    {
                        value: 431226,

                        label: "麻阳苗族自治县",
                    },

                    {
                        value: 431227,

                        label: "新晃侗族自治县",
                    },

                    {
                        value: 431228,

                        label: "芷江侗族自治县",
                    },

                    {
                        value: 431229,

                        label: "靖州苗族侗族自治县",
                    },

                    {
                        value: 431230,

                        label: "通道侗族自治县",
                    },

                    {
                        value: 431281,

                        label: "洪江市",
                    },
                ],
            },

            {
                value: 431300,

                label: "娄底市",

                children: [{
                        value: 431302,

                        label: "娄星区",
                    },

                    {
                        value: 431321,

                        label: "双峰县",
                    },

                    {
                        value: 431322,

                        label: "新化县",
                    },

                    {
                        value: 431381,

                        label: "冷水江市",
                    },

                    {
                        value: 431382,

                        label: "涟源市",
                    },
                ],
            },

            {
                value: 433100,

                label: "湘西土家族苗族自治州",

                children: [{
                        value: 433101,

                        label: "吉首市",
                    },

                    {
                        value: 433122,

                        label: "泸溪县",
                    },

                    {
                        value: 433123,

                        label: "凤凰县",
                    },

                    {
                        value: 433124,

                        label: "花垣县",
                    },

                    {
                        value: 433125,

                        label: "保靖县",
                    },

                    {
                        value: 433126,

                        label: "古丈县",
                    },

                    {
                        value: 433127,

                        label: "永顺县",
                    },

                    {
                        value: 433130,

                        label: "龙山县",
                    },
                ],
            },
        ],
    },

    {
        value: 440000,

        label: "广东省",

        children: [{
                value: 440100,

                label: "广州市",

                children: [{
                        value: 440103,

                        label: "荔湾区",
                    },

                    {
                        value: 440104,

                        label: "越秀区",
                    },

                    {
                        value: 440105,

                        label: "海珠区",
                    },

                    {
                        value: 440106,

                        label: "天河区",
                    },

                    {
                        value: 440111,

                        label: "白云区",
                    },

                    {
                        value: 440112,

                        label: "黄埔区",
                    },

                    {
                        value: 440113,

                        label: "番禺区",
                    },

                    {
                        value: 440114,

                        label: "花都区",
                    },

                    {
                        value: 440115,

                        label: "南沙区",
                    },

                    {
                        value: 440117,

                        label: "从化区",
                    },

                    {
                        value: 440118,

                        label: "增城区",
                    },
                ],
            },

            {
                value: 440200,

                label: "韶关市",

                children: [{
                        value: 440203,

                        label: "武江区",
                    },

                    {
                        value: 440204,

                        label: "浈江区",
                    },

                    {
                        value: 440205,

                        label: "曲江区",
                    },

                    {
                        value: 440222,

                        label: "始兴县",
                    },

                    {
                        value: 440224,

                        label: "仁化县",
                    },

                    {
                        value: 440229,

                        label: "翁源县",
                    },

                    {
                        value: 440232,

                        label: "乳源瑶族自治县",
                    },

                    {
                        value: 440233,

                        label: "新丰县",
                    },

                    {
                        value: 440281,

                        label: "乐昌市",
                    },

                    {
                        value: 440282,

                        label: "南雄市",
                    },
                ],
            },

            {
                value: 440300,

                label: "深圳市",

                children: [{
                        value: 440303,

                        label: "罗湖区",
                    },

                    {
                        value: 440304,

                        label: "福田区",
                    },

                    {
                        value: 440305,

                        label: "南山区",
                    },

                    {
                        value: 440306,

                        label: "宝安区",
                    },

                    {
                        value: 440307,

                        label: "龙岗区",
                    },

                    {
                        value: 440308,

                        label: "盐田区",
                    },

                    {
                        value: 440309,

                        label: "龙华区",
                    },

                    {
                        value: 440310,

                        label: "坪山区",
                    },

                    {
                        value: 440311,

                        label: "光明区",
                    },
                ],
            },

            {
                value: 440400,

                label: "珠海市",

                children: [{
                        value: 440402,

                        label: "香洲区",
                    },

                    {
                        value: 440403,

                        label: "斗门区",
                    },

                    {
                        value: 440404,

                        label: "金湾区",
                    },

                    {
                        value: 440499,

                        label: "香洲区澳门大学横琴校区(由澳门特别行政区实施管辖)",
                    },
                ],
            },

            {
                value: 440500,

                label: "汕头市",

                children: [{
                        value: 440507,

                        label: "龙湖区",
                    },

                    {
                        value: 440511,

                        label: "金平区",
                    },

                    {
                        value: 440512,

                        label: "濠江区",
                    },

                    {
                        value: 440513,

                        label: "潮阳区",
                    },

                    {
                        value: 440514,

                        label: "潮南区",
                    },

                    {
                        value: 440515,

                        label: "澄海区",
                    },

                    {
                        value: 440523,

                        label: "南澳县",
                    },
                ],
            },

            {
                value: 440600,

                label: "佛山市",

                children: [{
                        value: 440604,

                        label: "禅城区",
                    },

                    {
                        value: 440605,

                        label: "南海区",
                    },

                    {
                        value: 440606,

                        label: "顺德区",
                    },

                    {
                        value: 440607,

                        label: "三水区",
                    },

                    {
                        value: 440608,

                        label: "高明区",
                    },
                ],
            },

            {
                value: 440700,

                label: "江门市",

                children: [{
                        value: 440703,

                        label: "蓬江区",
                    },

                    {
                        value: 440704,

                        label: "江海区",
                    },

                    {
                        value: 440705,

                        label: "新会区",
                    },

                    {
                        value: 440781,

                        label: "台山市",
                    },

                    {
                        value: 440783,

                        label: "开平市",
                    },

                    {
                        value: 440784,

                        label: "鹤山市",
                    },

                    {
                        value: 440785,

                        label: "恩平市",
                    },
                ],
            },

            {
                value: 440800,

                label: "湛江市",

                children: [{
                        value: 440802,

                        label: "赤坎区",
                    },

                    {
                        value: 440803,

                        label: "霞山区",
                    },

                    {
                        value: 440804,

                        label: "坡头区",
                    },

                    {
                        value: 440811,

                        label: "麻章区",
                    },

                    {
                        value: 440823,

                        label: "遂溪县",
                    },

                    {
                        value: 440825,

                        label: "徐闻县",
                    },

                    {
                        value: 440881,

                        label: "廉江市",
                    },

                    {
                        value: 440882,

                        label: "雷州市",
                    },

                    {
                        value: 440883,

                        label: "吴川市",
                    },
                ],
            },

            {
                value: 440900,

                label: "茂名市",

                children: [{
                        value: 440902,

                        label: "茂南区",
                    },

                    {
                        value: 440904,

                        label: "电白区",
                    },

                    {
                        value: 440981,

                        label: "高州市",
                    },

                    {
                        value: 440982,

                        label: "化州市",
                    },

                    {
                        value: 440983,

                        label: "信宜市",
                    },
                ],
            },

            {
                value: 441200,

                label: "肇庆市",

                children: [{
                        value: 441202,

                        label: "端州区",
                    },

                    {
                        value: 441203,

                        label: "鼎湖区",
                    },

                    {
                        value: 441204,

                        label: "高要区",
                    },

                    {
                        value: 441223,

                        label: "广宁县",
                    },

                    {
                        value: 441224,

                        label: "怀集县",
                    },

                    {
                        value: 441225,

                        label: "封开县",
                    },

                    {
                        value: 441226,

                        label: "德庆县",
                    },

                    {
                        value: 441284,

                        label: "四会市",
                    },
                ],
            },

            {
                value: 441300,

                label: "惠州市",

                children: [{
                        value: 441302,

                        label: "惠城区",
                    },

                    {
                        value: 441303,

                        label: "惠阳区",
                    },

                    {
                        value: 441322,

                        label: "博罗县",
                    },

                    {
                        value: 441323,

                        label: "惠东县",
                    },

                    {
                        value: 441324,

                        label: "龙门县",
                    },
                ],
            },

            {
                value: 441400,

                label: "梅州市",

                children: [{
                        value: 441402,

                        label: "梅江区",
                    },

                    {
                        value: 441403,

                        label: "梅县区",
                    },

                    {
                        value: 441422,

                        label: "大埔县",
                    },

                    {
                        value: 441423,

                        label: "丰顺县",
                    },

                    {
                        value: 441424,

                        label: "五华县",
                    },

                    {
                        value: 441426,

                        label: "平远县",
                    },

                    {
                        value: 441427,

                        label: "蕉岭县",
                    },

                    {
                        value: 441481,

                        label: "兴宁市",
                    },
                ],
            },

            {
                value: 441500,

                label: "汕尾市",

                children: [{
                        value: 441502,

                        label: "城区",
                    },

                    {
                        value: 441521,

                        label: "海丰县",
                    },

                    {
                        value: 441523,

                        label: "陆河县",
                    },

                    {
                        value: 441581,

                        label: "陆丰市",
                    },
                ],
            },

            {
                value: 441600,

                label: "河源市",

                children: [{
                        value: 441602,

                        label: "源城区",
                    },

                    {
                        value: 441621,

                        label: "紫金县",
                    },

                    {
                        value: 441622,

                        label: "龙川县",
                    },

                    {
                        value: 441623,

                        label: "连平县",
                    },

                    {
                        value: 441624,

                        label: "和平县",
                    },

                    {
                        value: 441625,

                        label: "东源县",
                    },
                ],
            },

            {
                value: 441700,

                label: "阳江市",

                children: [{
                        value: 441702,

                        label: "江城区",
                    },

                    {
                        value: 441704,

                        label: "阳东区",
                    },

                    {
                        value: 441721,

                        label: "阳西县",
                    },

                    {
                        value: 441781,

                        label: "阳春市",
                    },
                ],
            },

            {
                value: 441800,

                label: "清远市",

                children: [{
                        value: 441802,

                        label: "清城区",
                    },

                    {
                        value: 441803,

                        label: "清新区",
                    },

                    {
                        value: 441821,

                        label: "佛冈县",
                    },

                    {
                        value: 441823,

                        label: "阳山县",
                    },

                    {
                        value: 441825,

                        label: "连山壮族瑶族自治县",
                    },

                    {
                        value: 441826,

                        label: "连南瑶族自治县",
                    },

                    {
                        value: 441881,

                        label: "英德市",
                    },

                    {
                        value: 441882,

                        label: "连州市",
                    },
                ],
            },

            {
                value: 441900,

                label: "东莞市",

                children: [{
                    value: 441999,

                    label: "东莞市",
                }, ],
            },

            {
                value: 442000,

                label: "中山市",

                children: [{
                    value: 442099,

                    label: "中山市",
                }, ],
            },

            {
                value: 445100,

                label: "潮州市",

                children: [{
                        value: 445102,

                        label: "湘桥区",
                    },

                    {
                        value: 445103,

                        label: "潮安区",
                    },

                    {
                        value: 445122,

                        label: "饶平县",
                    },
                ],
            },

            {
                value: 445200,

                label: "揭阳市",

                children: [{
                        value: 445202,

                        label: "榕城区",
                    },

                    {
                        value: 445203,

                        label: "揭东区",
                    },

                    {
                        value: 445222,

                        label: "揭西县",
                    },

                    {
                        value: 445224,

                        label: "惠来县",
                    },

                    {
                        value: 445281,

                        label: "普宁市",
                    },
                ],
            },

            {
                value: 445300,

                label: "云浮市",

                children: [{
                        value: 445302,

                        label: "云城区",
                    },

                    {
                        value: 445303,

                        label: "云安区",
                    },

                    {
                        value: 445321,

                        label: "新兴县",
                    },

                    {
                        value: 445322,

                        label: "郁南县",
                    },

                    {
                        value: 445381,

                        label: "罗定市",
                    },
                ],
            },
        ],
    },

    {
        value: 450000,

        label: "广西壮族自治区",

        children: [{
                value: 450100,

                label: "南宁市",

                children: [{
                        value: 450102,

                        label: "兴宁区",
                    },

                    {
                        value: 450103,

                        label: "青秀区",
                    },

                    {
                        value: 450105,

                        label: "江南区",
                    },

                    {
                        value: 450107,

                        label: "西乡塘区",
                    },

                    {
                        value: 450108,

                        label: "良庆区",
                    },

                    {
                        value: 450109,

                        label: "邕宁区",
                    },

                    {
                        value: 450110,

                        label: "武鸣区",
                    },

                    {
                        value: 450123,

                        label: "隆安县",
                    },

                    {
                        value: 450124,

                        label: "马山县",
                    },

                    {
                        value: 450125,

                        label: "上林县",
                    },

                    {
                        value: 450126,

                        label: "宾阳县",
                    },

                    {
                        value: 450127,

                        label: "横县",
                    },
                ],
            },

            {
                value: 450200,

                label: "柳州市",

                children: [{
                        value: 450202,

                        label: "城中区",
                    },

                    {
                        value: 450203,

                        label: "鱼峰区",
                    },

                    {
                        value: 450204,

                        label: "柳南区",
                    },

                    {
                        value: 450205,

                        label: "柳北区",
                    },

                    {
                        value: 450206,

                        label: "柳江区",
                    },

                    {
                        value: 450222,

                        label: "柳城县",
                    },

                    {
                        value: 450223,

                        label: "鹿寨县",
                    },

                    {
                        value: 450224,

                        label: "融安县",
                    },

                    {
                        value: 450225,

                        label: "融水苗族自治县",
                    },

                    {
                        value: 450226,

                        label: "三江侗族自治县",
                    },
                ],
            },

            {
                value: 450300,

                label: "桂林市",

                children: [{
                        value: 450302,

                        label: "秀峰区",
                    },

                    {
                        value: 450303,

                        label: "叠彩区",
                    },

                    {
                        value: 450304,

                        label: "象山区",
                    },

                    {
                        value: 450305,

                        label: "七星区",
                    },

                    {
                        value: 450311,

                        label: "雁山区",
                    },

                    {
                        value: 450312,

                        label: "临桂区",
                    },

                    {
                        value: 450321,

                        label: "阳朔县",
                    },

                    {
                        value: 450323,

                        label: "灵川县",
                    },

                    {
                        value: 450324,

                        label: "全州县",
                    },

                    {
                        value: 450325,

                        label: "兴安县",
                    },

                    {
                        value: 450326,

                        label: "永福县",
                    },

                    {
                        value: 450327,

                        label: "灌阳县",
                    },

                    {
                        value: 450328,

                        label: "龙胜各族自治县",
                    },

                    {
                        value: 450329,

                        label: "资源县",
                    },

                    {
                        value: 450330,

                        label: "平乐县",
                    },

                    {
                        value: 450332,

                        label: "恭城瑶族自治县",
                    },

                    {
                        value: 450381,

                        label: "荔浦市",
                    },
                ],
            },

            {
                value: 450400,

                label: "梧州市",

                children: [{
                        value: 450403,

                        label: "万秀区",
                    },

                    {
                        value: 450405,

                        label: "长洲区",
                    },

                    {
                        value: 450406,

                        label: "龙圩区",
                    },

                    {
                        value: 450421,

                        label: "苍梧县",
                    },

                    {
                        value: 450422,

                        label: "藤县",
                    },

                    {
                        value: 450423,

                        label: "蒙山县",
                    },

                    {
                        value: 450481,

                        label: "岑溪市",
                    },
                ],
            },

            {
                value: 450500,

                label: "北海市",

                children: [{
                        value: 450502,

                        label: "海城区",
                    },

                    {
                        value: 450503,

                        label: "银海区",
                    },

                    {
                        value: 450512,

                        label: "铁山港区",
                    },

                    {
                        value: 450521,

                        label: "合浦县",
                    },
                ],
            },

            {
                value: 450600,

                label: "防城港市",

                children: [{
                        value: 450602,

                        label: "港口区",
                    },

                    {
                        value: 450603,

                        label: "防城区",
                    },

                    {
                        value: 450621,

                        label: "上思县",
                    },

                    {
                        value: 450681,

                        label: "东兴市",
                    },
                ],
            },

            {
                value: 450700,

                label: "钦州市",

                children: [{
                        value: 450702,

                        label: "钦南区",
                    },

                    {
                        value: 450703,

                        label: "钦北区",
                    },

                    {
                        value: 450721,

                        label: "灵山县",
                    },

                    {
                        value: 450722,

                        label: "浦北县",
                    },
                ],
            },

            {
                value: 450800,

                label: "贵港市",

                children: [{
                        value: 450802,

                        label: "港北区",
                    },

                    {
                        value: 450803,

                        label: "港南区",
                    },

                    {
                        value: 450804,

                        label: "覃塘区",
                    },

                    {
                        value: 450821,

                        label: "平南县",
                    },

                    {
                        value: 450881,

                        label: "桂平市",
                    },
                ],
            },

            {
                value: 450900,

                label: "玉林市",

                children: [{
                        value: 450902,

                        label: "玉州区",
                    },

                    {
                        value: 450903,

                        label: "福绵区",
                    },

                    {
                        value: 450921,

                        label: "容县",
                    },

                    {
                        value: 450922,

                        label: "陆川县",
                    },

                    {
                        value: 450923,

                        label: "博白县",
                    },

                    {
                        value: 450924,

                        label: "兴业县",
                    },

                    {
                        value: 450981,

                        label: "北流市",
                    },
                ],
            },

            {
                value: 451000,

                label: "百色市",

                children: [{
                        value: 451002,

                        label: "右江区",
                    },

                    {
                        value: 451021,

                        label: "田阳县",
                    },

                    {
                        value: 451022,

                        label: "田东县",
                    },

                    {
                        value: 451023,

                        label: "平果县",
                    },

                    {
                        value: 451024,

                        label: "德保县",
                    },

                    {
                        value: 451026,

                        label: "那坡县",
                    },

                    {
                        value: 451027,

                        label: "凌云县",
                    },

                    {
                        value: 451028,

                        label: "乐业县",
                    },

                    {
                        value: 451029,

                        label: "田林县",
                    },

                    {
                        value: 451030,

                        label: "西林县",
                    },

                    {
                        value: 451031,

                        label: "隆林各族自治县",
                    },

                    {
                        value: 451081,

                        label: "靖西市",
                    },
                ],
            },

            {
                value: 451100,

                label: "贺州市",

                children: [{
                        value: 451102,

                        label: "八步区",
                    },

                    {
                        value: 451103,

                        label: "平桂区",
                    },

                    {
                        value: 451121,

                        label: "昭平县",
                    },

                    {
                        value: 451122,

                        label: "钟山县",
                    },

                    {
                        value: 451123,

                        label: "富川瑶族自治县",
                    },
                ],
            },

            {
                value: 451200,

                label: "河池市",

                children: [{
                        value: 451202,

                        label: "金城江区",
                    },

                    {
                        value: 451203,

                        label: "宜州区",
                    },

                    {
                        value: 451221,

                        label: "南丹县",
                    },

                    {
                        value: 451222,

                        label: "天峨县",
                    },

                    {
                        value: 451223,

                        label: "凤山县",
                    },

                    {
                        value: 451224,

                        label: "东兰县",
                    },

                    {
                        value: 451225,

                        label: "罗城仫佬族自治县",
                    },

                    {
                        value: 451226,

                        label: "环江毛南族自治县",
                    },

                    {
                        value: 451227,

                        label: "巴马瑶族自治县",
                    },

                    {
                        value: 451228,

                        label: "都安瑶族自治县",
                    },

                    {
                        value: 451229,

                        label: "大化瑶族自治县",
                    },
                ],
            },

            {
                value: 451300,

                label: "来宾市",

                children: [{
                        value: 451302,

                        label: "兴宾区",
                    },

                    {
                        value: 451321,

                        label: "忻城县",
                    },

                    {
                        value: 451322,

                        label: "象州县",
                    },

                    {
                        value: 451323,

                        label: "武宣县",
                    },

                    {
                        value: 451324,

                        label: "金秀瑶族自治县",
                    },

                    {
                        value: 451381,

                        label: "合山市",
                    },
                ],
            },

            {
                value: 451400,

                label: "崇左市",

                children: [{
                        value: 451402,

                        label: "江州区",
                    },

                    {
                        value: 451421,

                        label: "扶绥县",
                    },

                    {
                        value: 451422,

                        label: "宁明县",
                    },

                    {
                        value: 451423,

                        label: "龙州县",
                    },

                    {
                        value: 451424,

                        label: "大新县",
                    },

                    {
                        value: 451425,

                        label: "天等县",
                    },

                    {
                        value: 451481,

                        label: "凭祥市",
                    },
                ],
            },
        ],
    },

    {
        value: 460000,

        label: "海南省",

        children: [{
                value: 460100,

                label: "海口市",

                children: [{
                        value: 460105,

                        label: "秀英区",
                    },

                    {
                        value: 460106,

                        label: "龙华区",
                    },

                    {
                        value: 460107,

                        label: "琼山区",
                    },

                    {
                        value: 460108,

                        label: "美兰区",
                    },
                ],
            },

            {
                value: 460200,

                label: "三亚市",

                children: [{
                        value: 460202,

                        label: "海棠区",
                    },

                    {
                        value: 460203,

                        label: "吉阳区",
                    },

                    {
                        value: 460204,

                        label: "天涯区",
                    },

                    {
                        value: 460205,

                        label: "崖州区",
                    },
                ],
            },

            {
                value: 460300,

                label: "三沙市",

                children: [{
                        value: 460321,

                        label: "西沙群岛",
                    },

                    {
                        value: 460322,

                        label: "南沙群岛",
                    },

                    {
                        value: 460323,

                        label: "中沙群岛的岛礁及其海域",
                    },
                ],
            },

            {
                value: 460400,

                label: "儋州市",

                children: [{
                    value: 460499,

                    label: "儋州市",
                }, ],
            },

            {
                value: 469001,

                label: "五指山市",

                children: [{
                    value: 4690010,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469002,

                label: "琼海市",

                children: [{
                    value: 4690020,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469005,

                label: "文昌市",

                children: [{
                    value: 4690050,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469006,

                label: "万宁市",

                children: [{
                    value: 4690060,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469007,

                label: "东方市",

                children: [{
                    value: 4690070,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469021,

                label: "定安县",

                children: [{
                    value: 4690210,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469022,

                label: "屯昌县",

                children: [{
                    value: 4690220,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469023,

                label: "澄迈县",

                children: [{
                    value: 4690230,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469024,

                label: "临高县",

                children: [{
                    value: 4690240,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469025,

                label: "白沙黎族自治县",

                children: [{
                    value: 4690250,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469026,

                label: "昌江黎族自治县",

                children: [{
                    value: 4690260,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469027,

                label: "乐东黎族自治县",

                children: [{
                    value: 4690270,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469028,

                label: "陵水黎族自治县",

                children: [{
                    value: 4690280,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469029,

                label: "保亭黎族苗族自治县",

                children: [{
                    value: 4690290,

                    label: "市辖区",
                }, ],
            },

            {
                value: 469030,

                label: "琼中黎族苗族自治县",

                children: [{
                    value: 4690300,

                    label: "市辖区",
                }, ],
            },
        ],
    },

    {
        value: 510000,

        label: "四川省",

        children: [{
                value: 510100,

                label: "成都市",

                children: [{
                        value: 510104,

                        label: "锦江区",
                    },

                    {
                        value: 510105,

                        label: "青羊区",
                    },

                    {
                        value: 510106,

                        label: "金牛区",
                    },

                    {
                        value: 510107,

                        label: "武侯区",
                    },

                    {
                        value: 510108,

                        label: "成华区",
                    },

                    {
                        value: 510112,

                        label: "龙泉驿区",
                    },

                    {
                        value: 510113,

                        label: "青白江区",
                    },

                    {
                        value: 510114,

                        label: "新都区",
                    },

                    {
                        value: 510115,

                        label: "温江区",
                    },

                    {
                        value: 510116,

                        label: "双流区",
                    },

                    {
                        value: 510117,

                        label: "郫都区",
                    },

                    {
                        value: 510121,

                        label: "金堂县",
                    },

                    {
                        value: 510129,

                        label: "大邑县",
                    },

                    {
                        value: 510131,

                        label: "蒲江县",
                    },

                    {
                        value: 510132,

                        label: "新津县",
                    },

                    {
                        value: 510181,

                        label: "都江堰市",
                    },

                    {
                        value: 510182,

                        label: "彭州市",
                    },

                    {
                        value: 510183,

                        label: "邛崃市",
                    },

                    {
                        value: 510184,

                        label: "崇州市",
                    },

                    {
                        value: 510185,

                        label: "简阳市",
                    },
                ],
            },

            {
                value: 510300,

                label: "自贡市",

                children: [{
                        value: 510302,

                        label: "自流井区",
                    },

                    {
                        value: 510303,

                        label: "贡井区",
                    },

                    {
                        value: 510304,

                        label: "大安区",
                    },

                    {
                        value: 510311,

                        label: "沿滩区",
                    },

                    {
                        value: 510321,

                        label: "荣县",
                    },

                    {
                        value: 510322,

                        label: "富顺县",
                    },
                ],
            },

            {
                value: 510400,

                label: "攀枝花市",

                children: [{
                        value: 510402,

                        label: "东区",
                    },

                    {
                        value: 510403,

                        label: "西区",
                    },

                    {
                        value: 510411,

                        label: "仁和区",
                    },

                    {
                        value: 510421,

                        label: "米易县",
                    },

                    {
                        value: 510422,

                        label: "盐边县",
                    },
                ],
            },

            {
                value: 510500,

                label: "泸州市",

                children: [{
                        value: 510502,

                        label: "江阳区",
                    },

                    {
                        value: 510503,

                        label: "纳溪区",
                    },

                    {
                        value: 510504,

                        label: "龙马潭区",
                    },

                    {
                        value: 510521,

                        label: "泸县",
                    },

                    {
                        value: 510522,

                        label: "合江县",
                    },

                    {
                        value: 510524,

                        label: "叙永县",
                    },

                    {
                        value: 510525,

                        label: "古蔺县",
                    },
                ],
            },

            {
                value: 510600,

                label: "德阳市",

                children: [{
                        value: 510603,

                        label: "旌阳区",
                    },

                    {
                        value: 510604,

                        label: "罗江区",
                    },

                    {
                        value: 510623,

                        label: "中江县",
                    },

                    {
                        value: 510681,

                        label: "广汉市",
                    },

                    {
                        value: 510682,

                        label: "什邡市",
                    },

                    {
                        value: 510683,

                        label: "绵竹市",
                    },
                ],
            },

            {
                value: 510700,

                label: "绵阳市",

                children: [{
                        value: 510703,

                        label: "涪城区",
                    },

                    {
                        value: 510704,

                        label: "游仙区",
                    },

                    {
                        value: 510705,

                        label: "安州区",
                    },

                    {
                        value: 510722,

                        label: "三台县",
                    },

                    {
                        value: 510723,

                        label: "盐亭县",
                    },

                    {
                        value: 510725,

                        label: "梓潼县",
                    },

                    {
                        value: 510726,

                        label: "北川羌族自治县",
                    },

                    {
                        value: 510727,

                        label: "平武县",
                    },

                    {
                        value: 510781,

                        label: "江油市",
                    },
                ],
            },

            {
                value: 510800,

                label: "广元市",

                children: [{
                        value: 510802,

                        label: "利州区",
                    },

                    {
                        value: 510811,

                        label: "昭化区",
                    },

                    {
                        value: 510812,

                        label: "朝天区",
                    },

                    {
                        value: 510821,

                        label: "旺苍县",
                    },

                    {
                        value: 510822,

                        label: "青川县",
                    },

                    {
                        value: 510823,

                        label: "剑阁县",
                    },

                    {
                        value: 510824,

                        label: "苍溪县",
                    },
                ],
            },

            {
                value: 510900,

                label: "遂宁市",

                children: [{
                        value: 510903,

                        label: "船山区",
                    },

                    {
                        value: 510904,

                        label: "安居区",
                    },

                    {
                        value: 510921,

                        label: "蓬溪县",
                    },

                    {
                        value: 510922,

                        label: "射洪县",
                    },

                    {
                        value: 510923,

                        label: "大英县",
                    },
                ],
            },

            {
                value: 511000,

                label: "内江市",

                children: [{
                        value: 511002,

                        label: "市中区",
                    },

                    {
                        value: 511011,

                        label: "东兴区",
                    },

                    {
                        value: 511024,

                        label: "威远县",
                    },

                    {
                        value: 511025,

                        label: "资中县",
                    },

                    {
                        value: 511083,

                        label: "隆昌市",
                    },
                ],
            },

            {
                value: 511100,

                label: "乐山市",

                children: [{
                        value: 511102,

                        label: "市中区",
                    },

                    {
                        value: 511111,

                        label: "沙湾区",
                    },

                    {
                        value: 511112,

                        label: "五通桥区",
                    },

                    {
                        value: 511113,

                        label: "金口河区",
                    },

                    {
                        value: 511123,

                        label: "犍为县",
                    },

                    {
                        value: 511124,

                        label: "井研县",
                    },

                    {
                        value: 511126,

                        label: "夹江县",
                    },

                    {
                        value: 511129,

                        label: "沐川县",
                    },

                    {
                        value: 511132,

                        label: "峨边彝族自治县",
                    },

                    {
                        value: 511133,

                        label: "马边彝族自治县",
                    },

                    {
                        value: 511181,

                        label: "峨眉山市",
                    },
                ],
            },

            {
                value: 511300,

                label: "南充市",

                children: [{
                        value: 511302,

                        label: "顺庆区",
                    },

                    {
                        value: 511303,

                        label: "高坪区",
                    },

                    {
                        value: 511304,

                        label: "嘉陵区",
                    },

                    {
                        value: 511321,

                        label: "南部县",
                    },

                    {
                        value: 511322,

                        label: "营山县",
                    },

                    {
                        value: 511323,

                        label: "蓬安县",
                    },

                    {
                        value: 511324,

                        label: "仪陇县",
                    },

                    {
                        value: 511325,

                        label: "西充县",
                    },

                    {
                        value: 511381,

                        label: "阆中市",
                    },
                ],
            },

            {
                value: 511400,

                label: "眉山市",

                children: [{
                        value: 511402,

                        label: "东坡区",
                    },

                    {
                        value: 511403,

                        label: "彭山区",
                    },

                    {
                        value: 511421,

                        label: "仁寿县",
                    },

                    {
                        value: 511423,

                        label: "洪雅县",
                    },

                    {
                        value: 511424,

                        label: "丹棱县",
                    },

                    {
                        value: 511425,

                        label: "青神县",
                    },
                ],
            },

            {
                value: 511500,

                label: "宜宾市",

                children: [{
                        value: 511502,

                        label: "翠屏区",
                    },

                    {
                        value: 511503,

                        label: "南溪区",
                    },

                    {
                        value: 511504,

                        label: "叙州区",
                    },

                    {
                        value: 511523,

                        label: "江安县",
                    },

                    {
                        value: 511524,

                        label: "长宁县",
                    },

                    {
                        value: 511525,

                        label: "高县",
                    },

                    {
                        value: 511526,

                        label: "珙县",
                    },

                    {
                        value: 511527,

                        label: "筠连县",
                    },

                    {
                        value: 511528,

                        label: "兴文县",
                    },

                    {
                        value: 511529,

                        label: "屏山县",
                    },
                ],
            },

            {
                value: 511600,

                label: "广安市",

                children: [{
                        value: 511602,

                        label: "广安区",
                    },

                    {
                        value: 511603,

                        label: "前锋区",
                    },

                    {
                        value: 511621,

                        label: "岳池县",
                    },

                    {
                        value: 511622,

                        label: "武胜县",
                    },

                    {
                        value: 511623,

                        label: "邻水县",
                    },

                    {
                        value: 511681,

                        label: "华蓥市",
                    },
                ],
            },

            {
                value: 511700,

                label: "达州市",

                children: [{
                        value: 511702,

                        label: "通川区",
                    },

                    {
                        value: 511703,

                        label: "达川区",
                    },

                    {
                        value: 511722,

                        label: "宣汉县",
                    },

                    {
                        value: 511723,

                        label: "开江县",
                    },

                    {
                        value: 511724,

                        label: "大竹县",
                    },

                    {
                        value: 511725,

                        label: "渠县",
                    },

                    {
                        value: 511781,

                        label: "万源市",
                    },
                ],
            },

            {
                value: 511800,

                label: "雅安市",

                children: [{
                        value: 511802,

                        label: "雨城区",
                    },

                    {
                        value: 511803,

                        label: "名山区",
                    },

                    {
                        value: 511822,

                        label: "荥经县",
                    },

                    {
                        value: 511823,

                        label: "汉源县",
                    },

                    {
                        value: 511824,

                        label: "石棉县",
                    },

                    {
                        value: 511825,

                        label: "天全县",
                    },

                    {
                        value: 511826,

                        label: "芦山县",
                    },

                    {
                        value: 511827,

                        label: "宝兴县",
                    },
                ],
            },

            {
                value: 511900,

                label: "巴中市",

                children: [{
                        value: 511902,

                        label: "巴州区",
                    },

                    {
                        value: 511903,

                        label: "恩阳区",
                    },

                    {
                        value: 511921,

                        label: "通江县",
                    },

                    {
                        value: 511922,

                        label: "南江县",
                    },

                    {
                        value: 511923,

                        label: "平昌县",
                    },
                ],
            },

            {
                value: 512000,

                label: "资阳市",

                children: [{
                        value: 512002,

                        label: "雁江区",
                    },

                    {
                        value: 512021,

                        label: "安岳县",
                    },

                    {
                        value: 512022,

                        label: "乐至县",
                    },
                ],
            },

            {
                value: 513200,

                label: "阿坝藏族羌族自治州",

                children: [{
                        value: 513201,

                        label: "马尔康市",
                    },

                    {
                        value: 513221,

                        label: "汶川县",
                    },

                    {
                        value: 513222,

                        label: "理县",
                    },

                    {
                        value: 513223,

                        label: "茂县",
                    },

                    {
                        value: 513224,

                        label: "松潘县",
                    },

                    {
                        value: 513225,

                        label: "九寨沟县",
                    },

                    {
                        value: 513226,

                        label: "金川县",
                    },

                    {
                        value: 513227,

                        label: "小金县",
                    },

                    {
                        value: 513228,

                        label: "黑水县",
                    },

                    {
                        value: 513230,

                        label: "壤塘县",
                    },

                    {
                        value: 513231,

                        label: "阿坝县",
                    },

                    {
                        value: 513232,

                        label: "若尔盖县",
                    },

                    {
                        value: 513233,

                        label: "红原县",
                    },
                ],
            },

            {
                value: 513300,

                label: "甘孜藏族自治州",

                children: [{
                        value: 513301,

                        label: "康定市",
                    },

                    {
                        value: 513322,

                        label: "泸定县",
                    },

                    {
                        value: 513323,

                        label: "丹巴县",
                    },

                    {
                        value: 513324,

                        label: "九龙县",
                    },

                    {
                        value: 513325,

                        label: "雅江县",
                    },

                    {
                        value: 513326,

                        label: "道孚县",
                    },

                    {
                        value: 513327,

                        label: "炉霍县",
                    },

                    {
                        value: 513328,

                        label: "甘孜县",
                    },

                    {
                        value: 513329,

                        label: "新龙县",
                    },

                    {
                        value: 513330,

                        label: "德格县",
                    },

                    {
                        value: 513331,

                        label: "白玉县",
                    },

                    {
                        value: 513332,

                        label: "石渠县",
                    },

                    {
                        value: 513333,

                        label: "色达县",
                    },

                    {
                        value: 513334,

                        label: "理塘县",
                    },

                    {
                        value: 513335,

                        label: "巴塘县",
                    },

                    {
                        value: 513336,

                        label: "乡城县",
                    },

                    {
                        value: 513337,

                        label: "稻城县",
                    },

                    {
                        value: 513338,

                        label: "得荣县",
                    },
                ],
            },

            {
                value: 513400,

                label: "凉山彝族自治州",

                children: [{
                        value: 513401,

                        label: "西昌市",
                    },

                    {
                        value: 513422,

                        label: "木里藏族自治县",
                    },

                    {
                        value: 513423,

                        label: "盐源县",
                    },

                    {
                        value: 513424,

                        label: "德昌县",
                    },

                    {
                        value: 513425,

                        label: "会理县",
                    },

                    {
                        value: 513426,

                        label: "会东县",
                    },

                    {
                        value: 513427,

                        label: "宁南县",
                    },

                    {
                        value: 513428,

                        label: "普格县",
                    },

                    {
                        value: 513429,

                        label: "布拖县",
                    },

                    {
                        value: 513430,

                        label: "金阳县",
                    },

                    {
                        value: 513431,

                        label: "昭觉县",
                    },

                    {
                        value: 513432,

                        label: "喜德县",
                    },

                    {
                        value: 513433,

                        label: "冕宁县",
                    },

                    {
                        value: 513434,

                        label: "越西县",
                    },

                    {
                        value: 513435,

                        label: "甘洛县",
                    },

                    {
                        value: 513436,

                        label: "美姑县",
                    },

                    {
                        value: 513437,

                        label: "雷波县",
                    },
                ],
            },
        ],
    },

    {
        value: 520000,

        label: "贵州省",

        children: [{
                value: 520100,

                label: "贵阳市",

                children: [{
                        value: 520102,

                        label: "南明区",
                    },

                    {
                        value: 520103,

                        label: "云岩区",
                    },

                    {
                        value: 520111,

                        label: "花溪区",
                    },

                    {
                        value: 520112,

                        label: "乌当区",
                    },

                    {
                        value: 520113,

                        label: "白云区",
                    },

                    {
                        value: 520115,

                        label: "观山湖区",
                    },

                    {
                        value: 520121,

                        label: "开阳县",
                    },

                    {
                        value: 520122,

                        label: "息烽县",
                    },

                    {
                        value: 520123,

                        label: "修文县",
                    },

                    {
                        value: 520181,

                        label: "清镇市",
                    },
                ],
            },

            {
                value: 520200,

                label: "六盘水市",

                children: [{
                        value: 520201,

                        label: "钟山区",
                    },

                    {
                        value: 520203,

                        label: "六枝特区",
                    },

                    {
                        value: 520221,

                        label: "水城县",
                    },

                    {
                        value: 520281,

                        label: "盘州市",
                    },
                ],
            },

            {
                value: 520300,

                label: "遵义市",

                children: [{
                        value: 520302,

                        label: "红花岗区",
                    },

                    {
                        value: 520303,

                        label: "汇川区",
                    },

                    {
                        value: 520304,

                        label: "播州区",
                    },

                    {
                        value: 520322,

                        label: "桐梓县",
                    },

                    {
                        value: 520323,

                        label: "绥阳县",
                    },

                    {
                        value: 520324,

                        label: "正安县",
                    },

                    {
                        value: 520325,

                        label: "道真仡佬族苗族自治县",
                    },

                    {
                        value: 520326,

                        label: "务川仡佬族苗族自治县",
                    },

                    {
                        value: 520327,

                        label: "凤冈县",
                    },

                    {
                        value: 520328,

                        label: "湄潭县",
                    },

                    {
                        value: 520329,

                        label: "余庆县",
                    },

                    {
                        value: 520330,

                        label: "习水县",
                    },

                    {
                        value: 520381,

                        label: "赤水市",
                    },

                    {
                        value: 520382,

                        label: "仁怀市",
                    },
                ],
            },

            {
                value: 520400,

                label: "安顺市",

                children: [{
                        value: 520402,

                        label: "西秀区",
                    },

                    {
                        value: 520403,

                        label: "平坝区",
                    },

                    {
                        value: 520422,

                        label: "普定县",
                    },

                    {
                        value: 520423,

                        label: "镇宁布依族苗族自治县",
                    },

                    {
                        value: 520424,

                        label: "关岭布依族苗族自治县",
                    },

                    {
                        value: 520425,

                        label: "紫云苗族布依族自治县",
                    },
                ],
            },

            {
                value: 520500,

                label: "毕节市",

                children: [{
                        value: 520502,

                        label: "七星关区",
                    },

                    {
                        value: 520521,

                        label: "大方县",
                    },

                    {
                        value: 520522,

                        label: "黔西县",
                    },

                    {
                        value: 520523,

                        label: "金沙县",
                    },

                    {
                        value: 520524,

                        label: "织金县",
                    },

                    {
                        value: 520525,

                        label: "纳雍县",
                    },

                    {
                        value: 520526,

                        label: "威宁彝族回族苗族自治县",
                    },

                    {
                        value: 520527,

                        label: "赫章县",
                    },
                ],
            },

            {
                value: 520600,

                label: "铜仁市",

                children: [{
                        value: 520602,

                        label: "碧江区",
                    },

                    {
                        value: 520603,

                        label: "万山区",
                    },

                    {
                        value: 520621,

                        label: "江口县",
                    },

                    {
                        value: 520622,

                        label: "玉屏侗族自治县",
                    },

                    {
                        value: 520623,

                        label: "石阡县",
                    },

                    {
                        value: 520624,

                        label: "思南县",
                    },

                    {
                        value: 520625,

                        label: "印江土家族苗族自治县",
                    },

                    {
                        value: 520626,

                        label: "德江县",
                    },

                    {
                        value: 520627,

                        label: "沿河土家族自治县",
                    },

                    {
                        value: 520628,

                        label: "松桃苗族自治县",
                    },
                ],
            },

            {
                value: 522300,

                label: "黔西南布依族苗族自治州",

                children: [{
                        value: 522301,

                        label: "兴义市",
                    },

                    {
                        value: 522302,

                        label: "兴仁市",
                    },

                    {
                        value: 522323,

                        label: "普安县",
                    },

                    {
                        value: 522324,

                        label: "晴隆县",
                    },

                    {
                        value: 522325,

                        label: "贞丰县",
                    },

                    {
                        value: 522326,

                        label: "望谟县",
                    },

                    {
                        value: 522327,

                        label: "册亨县",
                    },

                    {
                        value: 522328,

                        label: "安龙县",
                    },
                ],
            },

            {
                value: 522600,

                label: "黔东南苗族侗族自治州",

                children: [{
                        value: 522601,

                        label: "凯里市",
                    },

                    {
                        value: 522622,

                        label: "黄平县",
                    },

                    {
                        value: 522623,

                        label: "施秉县",
                    },

                    {
                        value: 522624,

                        label: "三穗县",
                    },

                    {
                        value: 522625,

                        label: "镇远县",
                    },

                    {
                        value: 522626,

                        label: "岑巩县",
                    },

                    {
                        value: 522627,

                        label: "天柱县",
                    },

                    {
                        value: 522628,

                        label: "锦屏县",
                    },

                    {
                        value: 522629,

                        label: "剑河县",
                    },

                    {
                        value: 522630,

                        label: "台江县",
                    },

                    {
                        value: 522631,

                        label: "黎平县",
                    },

                    {
                        value: 522632,

                        label: "榕江县",
                    },

                    {
                        value: 522633,

                        label: "从江县",
                    },

                    {
                        value: 522634,

                        label: "雷山县",
                    },

                    {
                        value: 522635,

                        label: "麻江县",
                    },

                    {
                        value: 522636,

                        label: "丹寨县",
                    },
                ],
            },

            {
                value: 522700,

                label: "黔南布依族苗族自治州",

                children: [{
                        value: 522701,

                        label: "都匀市",
                    },

                    {
                        value: 522702,

                        label: "福泉市",
                    },

                    {
                        value: 522722,

                        label: "荔波县",
                    },

                    {
                        value: 522723,

                        label: "贵定县",
                    },

                    {
                        value: 522725,

                        label: "瓮安县",
                    },

                    {
                        value: 522726,

                        label: "独山县",
                    },

                    {
                        value: 522727,

                        label: "平塘县",
                    },

                    {
                        value: 522728,

                        label: "罗甸县",
                    },

                    {
                        value: 522729,

                        label: "长顺县",
                    },

                    {
                        value: 522730,

                        label: "龙里县",
                    },

                    {
                        value: 522731,

                        label: "惠水县",
                    },

                    {
                        value: 522732,

                        label: "三都水族自治县",
                    },
                ],
            },
        ],
    },

    {
        value: 530000,

        label: "云南省",

        children: [{
                value: 530100,

                label: "昆明市",

                children: [{
                        value: 530102,

                        label: "五华区",
                    },

                    {
                        value: 530103,

                        label: "盘龙区",
                    },

                    {
                        value: 530111,

                        label: "官渡区",
                    },

                    {
                        value: 530112,

                        label: "西山区",
                    },

                    {
                        value: 530113,

                        label: "东川区",
                    },

                    {
                        value: 530114,

                        label: "呈贡区",
                    },

                    {
                        value: 530115,

                        label: "晋宁区",
                    },

                    {
                        value: 530124,

                        label: "富民县",
                    },

                    {
                        value: 530125,

                        label: "宜良县",
                    },

                    {
                        value: 530126,

                        label: "石林彝族自治县",
                    },

                    {
                        value: 530127,

                        label: "嵩明县",
                    },

                    {
                        value: 530128,

                        label: "禄劝彝族苗族自治县",
                    },

                    {
                        value: 530129,

                        label: "寻甸回族彝族自治县",
                    },

                    {
                        value: 530181,

                        label: "安宁市",
                    },
                ],
            },

            {
                value: 530300,

                label: "曲靖市",

                children: [{
                        value: 530302,

                        label: "麒麟区",
                    },

                    {
                        value: 530303,

                        label: "沾益区",
                    },

                    {
                        value: 530304,

                        label: "马龙区",
                    },

                    {
                        value: 530322,

                        label: "陆良县",
                    },

                    {
                        value: 530323,

                        label: "师宗县",
                    },

                    {
                        value: 530324,

                        label: "罗平县",
                    },

                    {
                        value: 530325,

                        label: "富源县",
                    },

                    {
                        value: 530326,

                        label: "会泽县",
                    },

                    {
                        value: 530381,

                        label: "宣威市",
                    },
                ],
            },

            {
                value: 530400,

                label: "玉溪市",

                children: [{
                        value: 530402,

                        label: "红塔区",
                    },

                    {
                        value: 530403,

                        label: "江川区",
                    },

                    {
                        value: 530422,

                        label: "澄江县",
                    },

                    {
                        value: 530423,

                        label: "通海县",
                    },

                    {
                        value: 530424,

                        label: "华宁县",
                    },

                    {
                        value: 530425,

                        label: "易门县",
                    },

                    {
                        value: 530426,

                        label: "峨山彝族自治县",
                    },

                    {
                        value: 530427,

                        label: "新平彝族傣族自治县",
                    },

                    {
                        value: 530428,

                        label: "元江县",
                    },
                ],
            },

            {
                value: 530500,

                label: "保山市",

                children: [{
                        value: 530502,

                        label: "隆阳区",
                    },

                    {
                        value: 530521,

                        label: "施甸县",
                    },

                    {
                        value: 530523,

                        label: "龙陵县",
                    },

                    {
                        value: 530524,

                        label: "昌宁县",
                    },

                    {
                        value: 530581,

                        label: "腾冲市",
                    },
                ],
            },

            {
                value: 530600,

                label: "昭通市",

                children: [{
                        value: 530602,

                        label: "昭阳区",
                    },

                    {
                        value: 530621,

                        label: "鲁甸县",
                    },

                    {
                        value: 530622,

                        label: "巧家县",
                    },

                    {
                        value: 530623,

                        label: "盐津县",
                    },

                    {
                        value: 530624,

                        label: "大关县",
                    },

                    {
                        value: 530625,

                        label: "永善县",
                    },

                    {
                        value: 530626,

                        label: "绥江县",
                    },

                    {
                        value: 530627,

                        label: "镇雄县",
                    },

                    {
                        value: 530628,

                        label: "彝良县",
                    },

                    {
                        value: 530629,

                        label: "威信县",
                    },

                    {
                        value: 530681,

                        label: "水富市",
                    },
                ],
            },

            {
                value: 530700,

                label: "丽江市",

                children: [{
                        value: 530702,

                        label: "古城区",
                    },

                    {
                        value: 530721,

                        label: "玉龙纳西族自治县",
                    },

                    {
                        value: 530722,

                        label: "永胜县",
                    },

                    {
                        value: 530723,

                        label: "华坪县",
                    },

                    {
                        value: 530724,

                        label: "宁蒗彝族自治县",
                    },
                ],
            },

            {
                value: 530800,

                label: "普洱市",

                children: [{
                        value: 530802,

                        label: "思茅区",
                    },

                    {
                        value: 530821,

                        label: "宁洱哈尼族彝族自治县",
                    },

                    {
                        value: 530822,

                        label: "墨江哈尼族自治县",
                    },

                    {
                        value: 530823,

                        label: "景东彝族自治县",
                    },

                    {
                        value: 530824,

                        label: "景谷傣族彝族自治县",
                    },

                    {
                        value: 530825,

                        label: "镇沅县",
                    },

                    {
                        value: 530826,

                        label: "江城哈尼族彝族自治县",
                    },

                    {
                        value: 530827,

                        label: "孟连县",
                    },

                    {
                        value: 530828,

                        label: "澜沧拉祜族自治县",
                    },

                    {
                        value: 530829,

                        label: "西盟佤族自治县",
                    },
                ],
            },

            {
                value: 530900,

                label: "临沧市",

                children: [{
                        value: 530902,

                        label: "临翔区",
                    },

                    {
                        value: 530921,

                        label: "凤庆县",
                    },

                    {
                        value: 530922,

                        label: "云县",
                    },

                    {
                        value: 530923,

                        label: "永德县",
                    },

                    {
                        value: 530924,

                        label: "镇康县",
                    },

                    {
                        value: 530925,

                        label: "双江县",
                    },

                    {
                        value: 530926,

                        label: "耿马傣族佤族自治县",
                    },

                    {
                        value: 530927,

                        label: "沧源佤族自治县",
                    },
                ],
            },

            {
                value: 532300,

                label: "楚雄彝族自治州",

                children: [{
                        value: 532301,

                        label: "楚雄市",
                    },

                    {
                        value: 532322,

                        label: "双柏县",
                    },

                    {
                        value: 532323,

                        label: "牟定县",
                    },

                    {
                        value: 532324,

                        label: "南华县",
                    },

                    {
                        value: 532325,

                        label: "姚安县",
                    },

                    {
                        value: 532326,

                        label: "大姚县",
                    },

                    {
                        value: 532327,

                        label: "永仁县",
                    },

                    {
                        value: 532328,

                        label: "元谋县",
                    },

                    {
                        value: 532329,

                        label: "武定县",
                    },

                    {
                        value: 532331,

                        label: "禄丰县",
                    },
                ],
            },

            {
                value: 532500,

                label: "红河哈尼族彝族自治州",

                children: [{
                        value: 532501,

                        label: "个旧市",
                    },

                    {
                        value: 532502,

                        label: "开远市",
                    },

                    {
                        value: 532503,

                        label: "蒙自市",
                    },

                    {
                        value: 532504,

                        label: "弥勒市",
                    },

                    {
                        value: 532523,

                        label: "屏边苗族自治县",
                    },

                    {
                        value: 532524,

                        label: "建水县",
                    },

                    {
                        value: 532525,

                        label: "石屏县",
                    },

                    {
                        value: 532527,

                        label: "泸西县",
                    },

                    {
                        value: 532528,

                        label: "元阳县",
                    },

                    {
                        value: 532529,

                        label: "红河县",
                    },

                    {
                        value: 532530,

                        label: "金平苗族瑶族傣族自治县",
                    },

                    {
                        value: 532531,

                        label: "绿春县",
                    },

                    {
                        value: 532532,

                        label: "河口瑶族自治县",
                    },
                ],
            },

            {
                value: 532600,

                label: "文山壮族苗族自治州",

                children: [{
                        value: 532601,

                        label: "文山市",
                    },

                    {
                        value: 532622,

                        label: "砚山县",
                    },

                    {
                        value: 532623,

                        label: "西畴县",
                    },

                    {
                        value: 532624,

                        label: "麻栗坡县",
                    },

                    {
                        value: 532625,

                        label: "马关县",
                    },

                    {
                        value: 532626,

                        label: "丘北县",
                    },

                    {
                        value: 532627,

                        label: "广南县",
                    },

                    {
                        value: 532628,

                        label: "富宁县",
                    },
                ],
            },

            {
                value: 532800,

                label: "西双版纳傣族自治州",

                children: [{
                        value: 532801,

                        label: "景洪市",
                    },

                    {
                        value: 532822,

                        label: "勐海县",
                    },

                    {
                        value: 532823,

                        label: "勐腊县",
                    },
                ],
            },

            {
                value: 532900,

                label: "大理白族自治州",

                children: [{
                        value: 532901,

                        label: "大理市",
                    },

                    {
                        value: 532922,

                        label: "漾濞彝族自治县",
                    },

                    {
                        value: 532923,

                        label: "祥云县",
                    },

                    {
                        value: 532924,

                        label: "宾川县",
                    },

                    {
                        value: 532925,

                        label: "弥渡县",
                    },

                    {
                        value: 532926,

                        label: "南涧彝族自治县",
                    },

                    {
                        value: 532927,

                        label: "巍山彝族回族自治县",
                    },

                    {
                        value: 532928,

                        label: "永平县",
                    },

                    {
                        value: 532929,

                        label: "云龙县",
                    },

                    {
                        value: 532930,

                        label: "洱源县",
                    },

                    {
                        value: 532931,

                        label: "剑川县",
                    },

                    {
                        value: 532932,

                        label: "鹤庆县",
                    },
                ],
            },

            {
                value: 533100,

                label: "德宏傣族景颇族自治州",

                children: [{
                        value: 533102,

                        label: "瑞丽市",
                    },

                    {
                        value: 533103,

                        label: "芒市",
                    },

                    {
                        value: 533122,

                        label: "梁河县",
                    },

                    {
                        value: 533123,

                        label: "盈江县",
                    },

                    {
                        value: 533124,

                        label: "陇川县",
                    },
                ],
            },

            {
                value: 533300,

                label: "怒江傈僳族自治州",

                children: [{
                        value: 533301,

                        label: "泸水市",
                    },

                    {
                        value: 533323,

                        label: "福贡县",
                    },

                    {
                        value: 533324,

                        label: "贡山独龙族怒族自治县",
                    },

                    {
                        value: 533325,

                        label: "兰坪白族普米族自治县",
                    },
                ],
            },

            {
                value: 533400,

                label: "迪庆藏族自治州",

                children: [{
                        value: 533401,

                        label: "香格里拉市",
                    },

                    {
                        value: 533422,

                        label: "德钦县",
                    },

                    {
                        value: 533423,

                        label: "维西傈僳族自治县",
                    },
                ],
            },
        ],
    },

    {
        value: 540000,

        label: "西藏自治区",

        children: [{
                value: 540100,

                label: "拉萨市",

                children: [{
                        value: 540102,

                        label: "城关区",
                    },

                    {
                        value: 540103,

                        label: "堆龙德庆区",
                    },

                    {
                        value: 540104,

                        label: "达孜区",
                    },

                    {
                        value: 540121,

                        label: "林周县",
                    },

                    {
                        value: 540122,

                        label: "当雄县",
                    },

                    {
                        value: 540123,

                        label: "尼木县",
                    },

                    {
                        value: 540124,

                        label: "曲水县",
                    },

                    {
                        value: 540127,

                        label: "墨竹工卡县",
                    },
                ],
            },

            {
                value: 540200,

                label: "日喀则市",

                children: [{
                        value: 540202,

                        label: "桑珠孜区",
                    },

                    {
                        value: 540221,

                        label: "南木林县",
                    },

                    {
                        value: 540222,

                        label: "江孜县",
                    },

                    {
                        value: 540223,

                        label: "定日县",
                    },

                    {
                        value: 540224,

                        label: "萨迦县",
                    },

                    {
                        value: 540225,

                        label: "拉孜县",
                    },

                    {
                        value: 540226,

                        label: "昂仁县",
                    },

                    {
                        value: 540227,

                        label: "谢通门县",
                    },

                    {
                        value: 540228,

                        label: "白朗县",
                    },

                    {
                        value: 540229,

                        label: "仁布县",
                    },

                    {
                        value: 540230,

                        label: "康马县",
                    },

                    {
                        value: 540231,

                        label: "定结县",
                    },

                    {
                        value: 540232,

                        label: "仲巴县",
                    },

                    {
                        value: 540233,

                        label: "亚东县",
                    },

                    {
                        value: 540234,

                        label: "吉隆县",
                    },

                    {
                        value: 540235,

                        label: "聂拉木县",
                    },

                    {
                        value: 540236,

                        label: "萨嘎县",
                    },

                    {
                        value: 540237,

                        label: "岗巴县",
                    },
                ],
            },

            {
                value: 540300,

                label: "昌都市",

                children: [{
                        value: 540302,

                        label: "卡若区",
                    },

                    {
                        value: 540321,

                        label: "江达县",
                    },

                    {
                        value: 540322,

                        label: "贡觉县",
                    },

                    {
                        value: 540323,

                        label: "类乌齐县",
                    },

                    {
                        value: 540324,

                        label: "丁青县",
                    },

                    {
                        value: 540325,

                        label: "察雅县",
                    },

                    {
                        value: 540326,

                        label: "八宿县",
                    },

                    {
                        value: 540327,

                        label: "左贡县",
                    },

                    {
                        value: 540328,

                        label: "芒康县",
                    },

                    {
                        value: 540329,

                        label: "洛隆县",
                    },

                    {
                        value: 540330,

                        label: "边坝县",
                    },
                ],
            },

            {
                value: 540400,

                label: "林芝市",

                children: [{
                        value: 540402,

                        label: "巴宜区",
                    },

                    {
                        value: 540421,

                        label: "工布江达县",
                    },

                    {
                        value: 540422,

                        label: "米林县",
                    },

                    {
                        value: 540423,

                        label: "墨脱县",
                    },

                    {
                        value: 540424,

                        label: "波密县",
                    },

                    {
                        value: 540425,

                        label: "察隅县",
                    },

                    {
                        value: 540426,

                        label: "朗县",
                    },
                ],
            },

            {
                value: 540500,

                label: "山南市",

                children: [{
                        value: 540502,

                        label: "乃东区",
                    },

                    {
                        value: 540521,

                        label: "扎囊县",
                    },

                    {
                        value: 540522,

                        label: "贡嘎县",
                    },

                    {
                        value: 540523,

                        label: "桑日县",
                    },

                    {
                        value: 540524,

                        label: "琼结县",
                    },

                    {
                        value: 540525,

                        label: "曲松县",
                    },

                    {
                        value: 540526,

                        label: "措美县",
                    },

                    {
                        value: 540527,

                        label: "洛扎县",
                    },

                    {
                        value: 540528,

                        label: "加查县",
                    },

                    {
                        value: 540529,

                        label: "隆子县",
                    },

                    {
                        value: 540530,

                        label: "错那县",
                    },

                    {
                        value: 540531,

                        label: "浪卡子县",
                    },
                ],
            },

            {
                value: 540600,

                label: "那曲市",

                children: [{
                        value: 540602,

                        label: "色尼区",
                    },

                    {
                        value: 540621,

                        label: "嘉黎县",
                    },

                    {
                        value: 540622,

                        label: "比如县",
                    },

                    {
                        value: 540623,

                        label: "聂荣县",
                    },

                    {
                        value: 540624,

                        label: "安多县",
                    },

                    {
                        value: 540625,

                        label: "申扎县",
                    },

                    {
                        value: 540626,

                        label: "索县",
                    },

                    {
                        value: 540627,

                        label: "班戈县",
                    },

                    {
                        value: 540628,

                        label: "巴青县",
                    },

                    {
                        value: 540629,

                        label: "尼玛县",
                    },

                    {
                        value: 540630,

                        label: "双湖县",
                    },
                ],
            },

            {
                value: 542500,

                label: "阿里地区",

                children: [{
                        value: 542521,

                        label: "普兰县",
                    },

                    {
                        value: 542522,

                        label: "札达县",
                    },

                    {
                        value: 542523,

                        label: "噶尔县",
                    },

                    {
                        value: 542524,

                        label: "日土县",
                    },

                    {
                        value: 542525,

                        label: "革吉县",
                    },

                    {
                        value: 542526,

                        label: "改则县",
                    },

                    {
                        value: 542527,

                        label: "措勤县",
                    },
                ],
            },
        ],
    },

    {
        value: 610000,

        label: "陕西省",

        children: [{
                value: 610100,

                label: "西安市",

                children: [{
                        value: 610102,

                        label: "新城区",
                    },

                    {
                        value: 610103,

                        label: "碑林区",
                    },

                    {
                        value: 610104,

                        label: "莲湖区",
                    },

                    {
                        value: 610111,

                        label: "灞桥区",
                    },

                    {
                        value: 610112,

                        label: "未央区",
                    },

                    {
                        value: 610113,

                        label: "雁塔区",
                    },

                    {
                        value: 610114,

                        label: "阎良区",
                    },

                    {
                        value: 610115,

                        label: "临潼区",
                    },

                    {
                        value: 610116,

                        label: "长安区",
                    },

                    {
                        value: 610117,

                        label: "高陵区",
                    },

                    {
                        value: 610118,

                        label: "鄠邑区",
                    },

                    {
                        value: 610122,

                        label: "蓝田县",
                    },

                    {
                        value: 610124,

                        label: "周至县",
                    },
                ],
            },

            {
                value: 610200,

                label: "铜川市",

                children: [{
                        value: 610202,

                        label: "王益区",
                    },

                    {
                        value: 610203,

                        label: "印台区",
                    },

                    {
                        value: 610204,

                        label: "耀州区",
                    },

                    {
                        value: 610222,

                        label: "宜君县",
                    },
                ],
            },

            {
                value: 610300,

                label: "宝鸡市",

                children: [{
                        value: 610302,

                        label: "渭滨区",
                    },

                    {
                        value: 610303,

                        label: "金台区",
                    },

                    {
                        value: 610304,

                        label: "陈仓区",
                    },

                    {
                        value: 610322,

                        label: "凤翔县",
                    },

                    {
                        value: 610323,

                        label: "岐山县",
                    },

                    {
                        value: 610324,

                        label: "扶风县",
                    },

                    {
                        value: 610326,

                        label: "眉县",
                    },

                    {
                        value: 610327,

                        label: "陇县",
                    },

                    {
                        value: 610328,

                        label: "千阳县",
                    },

                    {
                        value: 610329,

                        label: "麟游县",
                    },

                    {
                        value: 610330,

                        label: "凤县",
                    },

                    {
                        value: 610331,

                        label: "太白县",
                    },
                ],
            },

            {
                value: 610400,

                label: "咸阳市",

                children: [{
                        value: 610402,

                        label: "秦都区",
                    },

                    {
                        value: 610403,

                        label: "杨陵区",
                    },

                    {
                        value: 610404,

                        label: "渭城区",
                    },

                    {
                        value: 610422,

                        label: "三原县",
                    },

                    {
                        value: 610423,

                        label: "泾阳县",
                    },

                    {
                        value: 610424,

                        label: "乾县",
                    },

                    {
                        value: 610425,

                        label: "礼泉县",
                    },

                    {
                        value: 610426,

                        label: "永寿县",
                    },

                    {
                        value: 610428,

                        label: "长武县",
                    },

                    {
                        value: 610429,

                        label: "旬邑县",
                    },

                    {
                        value: 610430,

                        label: "淳化县",
                    },

                    {
                        value: 610431,

                        label: "武功县",
                    },

                    {
                        value: 610481,

                        label: "兴平市",
                    },

                    {
                        value: 610482,

                        label: "彬州市",
                    },
                ],
            },

            {
                value: 610500,

                label: "渭南市",

                children: [{
                        value: 610502,

                        label: "临渭区",
                    },

                    {
                        value: 610503,

                        label: "华州区",
                    },

                    {
                        value: 610522,

                        label: "潼关县",
                    },

                    {
                        value: 610523,

                        label: "大荔县",
                    },

                    {
                        value: 610524,

                        label: "合阳县",
                    },

                    {
                        value: 610525,

                        label: "澄城县",
                    },

                    {
                        value: 610526,

                        label: "蒲城县",
                    },

                    {
                        value: 610527,

                        label: "白水县",
                    },

                    {
                        value: 610528,

                        label: "富平县",
                    },

                    {
                        value: 610581,

                        label: "韩城市",
                    },

                    {
                        value: 610582,

                        label: "华阴市",
                    },
                ],
            },

            {
                value: 610600,

                label: "延安市",

                children: [{
                        value: 610602,

                        label: "宝塔区",
                    },

                    {
                        value: 610603,

                        label: "安塞区",
                    },

                    {
                        value: 610621,

                        label: "延长县",
                    },

                    {
                        value: 610622,

                        label: "延川县",
                    },

                    {
                        value: 610623,

                        label: "子长县",
                    },

                    {
                        value: 610625,

                        label: "志丹县",
                    },

                    {
                        value: 610626,

                        label: "吴起县",
                    },

                    {
                        value: 610627,

                        label: "甘泉县",
                    },

                    {
                        value: 610628,

                        label: "富县",
                    },

                    {
                        value: 610629,

                        label: "洛川县",
                    },

                    {
                        value: 610630,

                        label: "宜川县",
                    },

                    {
                        value: 610631,

                        label: "黄龙县",
                    },

                    {
                        value: 610632,

                        label: "黄陵县",
                    },
                ],
            },

            {
                value: 610700,

                label: "汉中市",

                children: [{
                        value: 610702,

                        label: "汉台区",
                    },

                    {
                        value: 610703,

                        label: "南郑区",
                    },

                    {
                        value: 610722,

                        label: "城固县",
                    },

                    {
                        value: 610723,

                        label: "洋县",
                    },

                    {
                        value: 610724,

                        label: "西乡县",
                    },

                    {
                        value: 610725,

                        label: "勉县",
                    },

                    {
                        value: 610726,

                        label: "宁强县",
                    },

                    {
                        value: 610727,

                        label: "略阳县",
                    },

                    {
                        value: 610728,

                        label: "镇巴县",
                    },

                    {
                        value: 610729,

                        label: "留坝县",
                    },

                    {
                        value: 610730,

                        label: "佛坪县",
                    },
                ],
            },

            {
                value: 610800,

                label: "榆林市",

                children: [{
                        value: 610802,

                        label: "榆阳区",
                    },

                    {
                        value: 610803,

                        label: "横山区",
                    },

                    {
                        value: 610822,

                        label: "府谷县",
                    },

                    {
                        value: 610824,

                        label: "靖边县",
                    },

                    {
                        value: 610825,

                        label: "定边县",
                    },

                    {
                        value: 610826,

                        label: "绥德县",
                    },

                    {
                        value: 610827,

                        label: "米脂县",
                    },

                    {
                        value: 610828,

                        label: "佳县",
                    },

                    {
                        value: 610829,

                        label: "吴堡县",
                    },

                    {
                        value: 610830,

                        label: "清涧县",
                    },

                    {
                        value: 610831,

                        label: "子洲县",
                    },

                    {
                        value: 610881,

                        label: "神木市",
                    },
                ],
            },

            {
                value: 610900,

                label: "安康市",

                children: [{
                        value: 610902,

                        label: "汉滨区",
                    },

                    {
                        value: 610921,

                        label: "汉阴县",
                    },

                    {
                        value: 610922,

                        label: "石泉县",
                    },

                    {
                        value: 610923,

                        label: "宁陕县",
                    },

                    {
                        value: 610924,

                        label: "紫阳县",
                    },

                    {
                        value: 610925,

                        label: "岚皋县",
                    },

                    {
                        value: 610926,

                        label: "平利县",
                    },

                    {
                        value: 610927,

                        label: "镇坪县",
                    },

                    {
                        value: 610928,

                        label: "旬阳县",
                    },

                    {
                        value: 610929,

                        label: "白河县",
                    },
                ],
            },

            {
                value: 611000,

                label: "商洛市",

                children: [{
                        value: 611002,

                        label: "商州区",
                    },

                    {
                        value: 611021,

                        label: "洛南县",
                    },

                    {
                        value: 611022,

                        label: "丹凤县",
                    },

                    {
                        value: 611023,

                        label: "商南县",
                    },

                    {
                        value: 611024,

                        label: "山阳县",
                    },

                    {
                        value: 611025,

                        label: "镇安县",
                    },

                    {
                        value: 611026,

                        label: "柞水县",
                    },
                ],
            },
        ],
    },

    {
        value: 620000,

        label: "甘肃省",

        children: [{
                value: 620100,

                label: "兰州市",

                children: [{
                        value: 620102,

                        label: "城关区",
                    },

                    {
                        value: 620103,

                        label: "七里河区",
                    },

                    {
                        value: 620104,

                        label: "西固区",
                    },

                    {
                        value: 620105,

                        label: "安宁区",
                    },

                    {
                        value: 620111,

                        label: "红古区",
                    },

                    {
                        value: 620121,

                        label: "永登县",
                    },

                    {
                        value: 620122,

                        label: "皋兰县",
                    },

                    {
                        value: 620123,

                        label: "榆中县",
                    },
                ],
            },

            {
                value: 620200,

                label: "嘉峪关市",

                children: [{
                    value: 620299,

                    label: "嘉峪关市",
                }, ],
            },

            {
                value: 620300,

                label: "金昌市",

                children: [{
                        value: 620302,

                        label: "金川区",
                    },

                    {
                        value: 620321,

                        label: "永昌县",
                    },
                ],
            },

            {
                value: 620400,

                label: "白银市",

                children: [{
                        value: 620402,

                        label: "白银区",
                    },

                    {
                        value: 620403,

                        label: "平川区",
                    },

                    {
                        value: 620421,

                        label: "靖远县",
                    },

                    {
                        value: 620422,

                        label: "会宁县",
                    },

                    {
                        value: 620423,

                        label: "景泰县",
                    },
                ],
            },

            {
                value: 620500,

                label: "天水市",

                children: [{
                        value: 620502,

                        label: "秦州区",
                    },

                    {
                        value: 620503,

                        label: "麦积区",
                    },

                    {
                        value: 620521,

                        label: "清水县",
                    },

                    {
                        value: 620522,

                        label: "秦安县",
                    },

                    {
                        value: 620523,

                        label: "甘谷县",
                    },

                    {
                        value: 620524,

                        label: "武山县",
                    },

                    {
                        value: 620525,

                        label: "张家川回族自治县",
                    },
                ],
            },

            {
                value: 620600,

                label: "武威市",

                children: [{
                        value: 620602,

                        label: "凉州区",
                    },

                    {
                        value: 620621,

                        label: "民勤县",
                    },

                    {
                        value: 620622,

                        label: "古浪县",
                    },

                    {
                        value: 620623,

                        label: "天祝藏族自治县",
                    },
                ],
            },

            {
                value: 620700,

                label: "张掖市",

                children: [{
                        value: 620702,

                        label: "甘州区",
                    },

                    {
                        value: 620721,

                        label: "肃南裕固族自治县",
                    },

                    {
                        value: 620722,

                        label: "民乐县",
                    },

                    {
                        value: 620723,

                        label: "临泽县",
                    },

                    {
                        value: 620724,

                        label: "高台县",
                    },

                    {
                        value: 620725,

                        label: "山丹县",
                    },
                ],
            },

            {
                value: 620800,

                label: "平凉市",

                children: [{
                        value: 620802,

                        label: "崆峒区",
                    },

                    {
                        value: 620821,

                        label: "泾川县",
                    },

                    {
                        value: 620822,

                        label: "灵台县",
                    },

                    {
                        value: 620823,

                        label: "崇信县",
                    },

                    {
                        value: 620825,

                        label: "庄浪县",
                    },

                    {
                        value: 620826,

                        label: "静宁县",
                    },

                    {
                        value: 620881,

                        label: "华亭市",
                    },
                ],
            },

            {
                value: 620900,

                label: "酒泉市",

                children: [{
                        value: 620902,

                        label: "肃州区",
                    },

                    {
                        value: 620921,

                        label: "金塔县",
                    },

                    {
                        value: 620922,

                        label: "瓜州县",
                    },

                    {
                        value: 620923,

                        label: "肃北蒙古族自治县",
                    },

                    {
                        value: 620924,

                        label: "阿克塞哈萨克族自治县",
                    },

                    {
                        value: 620981,

                        label: "玉门市",
                    },

                    {
                        value: 620982,

                        label: "敦煌市",
                    },
                ],
            },

            {
                value: 621000,

                label: "庆阳市",

                children: [{
                        value: 621002,

                        label: "西峰区",
                    },

                    {
                        value: 621021,

                        label: "庆城县",
                    },

                    {
                        value: 621022,

                        label: "环县",
                    },

                    {
                        value: 621023,

                        label: "华池县",
                    },

                    {
                        value: 621024,

                        label: "合水县",
                    },

                    {
                        value: 621025,

                        label: "正宁县",
                    },

                    {
                        value: 621026,

                        label: "宁县",
                    },

                    {
                        value: 621027,

                        label: "镇原县",
                    },
                ],
            },

            {
                value: 621100,

                label: "定西市",

                children: [{
                        value: 621102,

                        label: "安定区",
                    },

                    {
                        value: 621121,

                        label: "通渭县",
                    },

                    {
                        value: 621122,

                        label: "陇西县",
                    },

                    {
                        value: 621123,

                        label: "渭源县",
                    },

                    {
                        value: 621124,

                        label: "临洮县",
                    },

                    {
                        value: 621125,

                        label: "漳县",
                    },

                    {
                        value: 621126,

                        label: "岷县",
                    },
                ],
            },

            {
                value: 621200,

                label: "陇南市",

                children: [{
                        value: 621202,

                        label: "武都区",
                    },

                    {
                        value: 621221,

                        label: "成县",
                    },

                    {
                        value: 621222,

                        label: "文县",
                    },

                    {
                        value: 621223,

                        label: "宕昌县",
                    },

                    {
                        value: 621224,

                        label: "康县",
                    },

                    {
                        value: 621225,

                        label: "西和县",
                    },

                    {
                        value: 621226,

                        label: "礼县",
                    },

                    {
                        value: 621227,

                        label: "徽县",
                    },

                    {
                        value: 621228,

                        label: "两当县",
                    },
                ],
            },

            {
                value: 622900,

                label: "临夏回族自治州",

                children: [{
                        value: 622901,

                        label: "临夏市",
                    },

                    {
                        value: 622921,

                        label: "临夏县",
                    },

                    {
                        value: 622922,

                        label: "康乐县",
                    },

                    {
                        value: 622923,

                        label: "永靖县",
                    },

                    {
                        value: 622924,

                        label: "广河县",
                    },

                    {
                        value: 622925,

                        label: "和政县",
                    },

                    {
                        value: 622926,

                        label: "东乡族自治县",
                    },

                    {
                        value: 622927,

                        label: "积石山县",
                    },
                ],
            },

            {
                value: 623000,

                label: "甘南藏族自治州",

                children: [{
                        value: 623001,

                        label: "合作市",
                    },

                    {
                        value: 623021,

                        label: "临潭县",
                    },

                    {
                        value: 623022,

                        label: "卓尼县",
                    },

                    {
                        value: 623023,

                        label: "舟曲县",
                    },

                    {
                        value: 623024,

                        label: "迭部县",
                    },

                    {
                        value: 623025,

                        label: "玛曲县",
                    },

                    {
                        value: 623026,

                        label: "碌曲县",
                    },

                    {
                        value: 623027,

                        label: "夏河县",
                    },
                ],
            },
        ],
    },

    {
        value: 630000,

        label: "青海省",

        children: [{
                value: 630100,

                label: "西宁市",

                children: [{
                        value: 630102,

                        label: "城东区",
                    },

                    {
                        value: 630103,

                        label: "城中区",
                    },

                    {
                        value: 630104,

                        label: "城西区",
                    },

                    {
                        value: 630105,

                        label: "城北区",
                    },

                    {
                        value: 630121,

                        label: "大通回族土族自治县",
                    },

                    {
                        value: 630122,

                        label: "湟中县",
                    },

                    {
                        value: 630123,

                        label: "湟源县",
                    },
                ],
            },

            {
                value: 630200,

                label: "海东市",

                children: [{
                        value: 630202,

                        label: "乐都区",
                    },

                    {
                        value: 630203,

                        label: "平安区",
                    },

                    {
                        value: 630222,

                        label: "民和回族土族自治县",
                    },

                    {
                        value: 630223,

                        label: "互助土族自治县",
                    },

                    {
                        value: 630224,

                        label: "化隆回族自治县",
                    },

                    {
                        value: 630225,

                        label: "循化撒拉族自治县",
                    },
                ],
            },

            {
                value: 632200,

                label: "海北藏族自治州",

                children: [{
                        value: 632221,

                        label: "门源回族自治县",
                    },

                    {
                        value: 632222,

                        label: "祁连县",
                    },

                    {
                        value: 632223,

                        label: "海晏县",
                    },

                    {
                        value: 632224,

                        label: "刚察县",
                    },
                ],
            },

            {
                value: 632300,

                label: "黄南藏族自治州",

                children: [{
                        value: 632321,

                        label: "同仁县",
                    },

                    {
                        value: 632322,

                        label: "尖扎县",
                    },

                    {
                        value: 632323,

                        label: "泽库县",
                    },

                    {
                        value: 632324,

                        label: "河南蒙古族自治县",
                    },
                ],
            },

            {
                value: 632500,

                label: "海南藏族自治州",

                children: [{
                        value: 632521,

                        label: "共和县",
                    },

                    {
                        value: 632522,

                        label: "同德县",
                    },

                    {
                        value: 632523,

                        label: "贵德县",
                    },

                    {
                        value: 632524,

                        label: "兴海县",
                    },

                    {
                        value: 632525,

                        label: "贵南县",
                    },
                ],
            },

            {
                value: 632600,

                label: "果洛藏族自治州",

                children: [{
                        value: 632621,

                        label: "玛沁县",
                    },

                    {
                        value: 632622,

                        label: "班玛县",
                    },

                    {
                        value: 632623,

                        label: "甘德县",
                    },

                    {
                        value: 632624,

                        label: "达日县",
                    },

                    {
                        value: 632625,

                        label: "久治县",
                    },

                    {
                        value: 632626,

                        label: "玛多县",
                    },
                ],
            },

            {
                value: 632700,

                label: "玉树藏族自治州",

                children: [{
                        value: 632701,

                        label: "玉树市",
                    },

                    {
                        value: 632722,

                        label: "杂多县",
                    },

                    {
                        value: 632723,

                        label: "称多县",
                    },

                    {
                        value: 632724,

                        label: "治多县",
                    },

                    {
                        value: 632725,

                        label: "囊谦县",
                    },

                    {
                        value: 632726,

                        label: "曲麻莱县",
                    },
                ],
            },

            {
                value: 632800,

                label: "海西蒙古族藏族自治州",

                children: [{
                        value: 632801,

                        label: "格尔木市",
                    },

                    {
                        value: 632802,

                        label: "德令哈市",
                    },

                    {
                        value: 632803,

                        label: "茫崖市",
                    },

                    {
                        value: 632821,

                        label: "乌兰县",
                    },

                    {
                        value: 632822,

                        label: "都兰县",
                    },

                    {
                        value: 632823,

                        label: "天峻县",
                    },

                    {
                        value: 632825,

                        label: "大柴旦行政委员会",
                    },
                ],
            },
        ],
    },

    {
        value: 640000,

        label: "宁夏回族自治区",

        children: [{
                value: 640100,

                label: "银川市",

                children: [{
                        value: 640104,

                        label: "兴庆区",
                    },

                    {
                        value: 640105,

                        label: "西夏区",
                    },

                    {
                        value: 640106,

                        label: "金凤区",
                    },

                    {
                        value: 640121,

                        label: "永宁县",
                    },

                    {
                        value: 640122,

                        label: "贺兰县",
                    },

                    {
                        value: 640181,

                        label: "灵武市",
                    },
                ],
            },

            {
                value: 640200,

                label: "石嘴山市",

                children: [{
                        value: 640202,

                        label: "大武口区",
                    },

                    {
                        value: 640205,

                        label: "惠农区",
                    },

                    {
                        value: 640221,

                        label: "平罗县",
                    },
                ],
            },

            {
                value: 640300,

                label: "吴忠市",

                children: [{
                        value: 640302,

                        label: "利通区",
                    },

                    {
                        value: 640303,

                        label: "红寺堡区",
                    },

                    {
                        value: 640323,

                        label: "盐池县",
                    },

                    {
                        value: 640324,

                        label: "同心县",
                    },

                    {
                        value: 640381,

                        label: "青铜峡市",
                    },
                ],
            },

            {
                value: 640400,

                label: "固原市",

                children: [{
                        value: 640402,

                        label: "原州区",
                    },

                    {
                        value: 640422,

                        label: "西吉县",
                    },

                    {
                        value: 640423,

                        label: "隆德县",
                    },

                    {
                        value: 640424,

                        label: "泾源县",
                    },

                    {
                        value: 640425,

                        label: "彭阳县",
                    },
                ],
            },

            {
                value: 640500,

                label: "中卫市",

                children: [{
                        value: 640502,

                        label: "沙坡头区",
                    },

                    {
                        value: 640521,

                        label: "中宁县",
                    },

                    {
                        value: 640522,

                        label: "海原县",
                    },
                ],
            },
        ],
    },

    {
        value: 650000,

        label: "新疆维吾尔自治区",

        children: [{
                value: 650100,

                label: "乌鲁木齐市",

                children: [{
                        value: 650102,

                        label: "天山区",
                    },

                    {
                        value: 650103,

                        label: "沙依巴克区",
                    },

                    {
                        value: 650104,

                        label: "新市区",
                    },

                    {
                        value: 650105,

                        label: "水磨沟区",
                    },

                    {
                        value: 650106,

                        label: "头屯河区",
                    },

                    {
                        value: 650107,

                        label: "达坂城区",
                    },

                    {
                        value: 650109,

                        label: "米东区",
                    },

                    {
                        value: 650121,

                        label: "乌鲁木齐县",
                    },
                ],
            },

            {
                value: 650200,

                label: "克拉玛依市",

                children: [{
                        value: 650202,

                        label: "独山子区",
                    },

                    {
                        value: 650203,

                        label: "克拉玛依区",
                    },

                    {
                        value: 650204,

                        label: "白碱滩区",
                    },

                    {
                        value: 650205,

                        label: "乌尔禾区",
                    },
                ],
            },

            {
                value: 650400,

                label: "吐鲁番市",

                children: [{
                        value: 650402,

                        label: "高昌区",
                    },

                    {
                        value: 650421,

                        label: "鄯善县",
                    },

                    {
                        value: 650422,

                        label: "托克逊县",
                    },
                ],
            },

            {
                value: 650500,

                label: "哈密市",

                children: [{
                        value: 650502,

                        label: "伊州区",
                    },

                    {
                        value: 650521,

                        label: "巴里坤哈萨克自治县",
                    },

                    {
                        value: 650522,

                        label: "伊吾县",
                    },
                ],
            },

            {
                value: 652300,

                label: "昌吉回族自治州",

                children: [{
                        value: 652301,

                        label: "昌吉市",
                    },

                    {
                        value: 652302,

                        label: "阜康市",
                    },

                    {
                        value: 652323,

                        label: "呼图壁县",
                    },

                    {
                        value: 652324,

                        label: "玛纳斯县",
                    },

                    {
                        value: 652325,

                        label: "奇台县",
                    },

                    {
                        value: 652327,

                        label: "吉木萨尔县",
                    },

                    {
                        value: 652328,

                        label: "木垒哈萨克自治县",
                    },
                ],
            },

            {
                value: 652700,

                label: "博尔塔拉蒙古自治州",

                children: [{
                        value: 652701,

                        label: "博乐市",
                    },

                    {
                        value: 652702,

                        label: "阿拉山口市",
                    },

                    {
                        value: 652722,

                        label: "精河县",
                    },

                    {
                        value: 652723,

                        label: "温泉县",
                    },
                ],
            },

            {
                value: 652800,

                label: "巴音郭楞蒙古自治州",

                children: [{
                        value: 652801,

                        label: "库尔勒市",
                    },

                    {
                        value: 652822,

                        label: "轮台县",
                    },

                    {
                        value: 652823,

                        label: "尉犁县",
                    },

                    {
                        value: 652824,

                        label: "若羌县",
                    },

                    {
                        value: 652825,

                        label: "且末县",
                    },

                    {
                        value: 652826,

                        label: "焉耆回族自治县",
                    },

                    {
                        value: 652827,

                        label: "和静县",
                    },

                    {
                        value: 652828,

                        label: "和硕县",
                    },

                    {
                        value: 652829,

                        label: "博湖县",
                    },
                ],
            },

            {
                value: 652900,

                label: "阿克苏地区",

                children: [{
                        value: 652901,

                        label: "阿克苏市",
                    },

                    {
                        value: 652922,

                        label: "温宿县",
                    },

                    {
                        value: 652923,

                        label: "库车县",
                    },

                    {
                        value: 652924,

                        label: "沙雅县",
                    },

                    {
                        value: 652925,

                        label: "新和县",
                    },

                    {
                        value: 652926,

                        label: "拜城县",
                    },

                    {
                        value: 652927,

                        label: "乌什县",
                    },

                    {
                        value: 652928,

                        label: "阿瓦提县",
                    },

                    {
                        value: 652929,

                        label: "柯坪县",
                    },
                ],
            },

            {
                value: 653000,

                label: "克孜勒苏柯尔克孜自治州",

                children: [{
                        value: 653001,

                        label: "阿图什市",
                    },

                    {
                        value: 653022,

                        label: "阿克陶县",
                    },

                    {
                        value: 653023,

                        label: "阿合奇县",
                    },

                    {
                        value: 653024,

                        label: "乌恰县",
                    },
                ],
            },

            {
                value: 653100,

                label: "喀什地区",

                children: [{
                        value: 653101,

                        label: "喀什市",
                    },

                    {
                        value: 653121,

                        label: "疏附县",
                    },

                    {
                        value: 653122,

                        label: "疏勒县",
                    },

                    {
                        value: 653123,

                        label: "英吉沙县",
                    },

                    {
                        value: 653124,

                        label: "泽普县",
                    },

                    {
                        value: 653125,

                        label: "莎车县",
                    },

                    {
                        value: 653126,

                        label: "叶城县",
                    },

                    {
                        value: 653127,

                        label: "麦盖提县",
                    },

                    {
                        value: 653128,

                        label: "岳普湖县",
                    },

                    {
                        value: 653129,

                        label: "伽师县",
                    },

                    {
                        value: 653130,

                        label: "巴楚县",
                    },

                    {
                        value: 653131,

                        label: "塔什库尔干塔吉克自治县",
                    },
                ],
            },

            {
                value: 653200,

                label: "和田地区",

                children: [{
                        value: 653201,

                        label: "和田市",
                    },

                    {
                        value: 653221,

                        label: "和田县",
                    },

                    {
                        value: 653222,

                        label: "墨玉县",
                    },

                    {
                        value: 653223,

                        label: "皮山县",
                    },

                    {
                        value: 653224,

                        label: "洛浦县",
                    },

                    {
                        value: 653225,

                        label: "策勒县",
                    },

                    {
                        value: 653226,

                        label: "于田县",
                    },

                    {
                        value: 653227,

                        label: "民丰县",
                    },
                ],
            },

            {
                value: 654000,

                label: "伊犁哈萨克自治州",

                children: [{
                        value: 654002,

                        label: "伊宁市",
                    },

                    {
                        value: 654003,

                        label: "奎屯市",
                    },

                    {
                        value: 654004,

                        label: "霍尔果斯市",
                    },

                    {
                        value: 654021,

                        label: "伊宁县",
                    },

                    {
                        value: 654022,

                        label: "察布查尔锡伯自治县",
                    },

                    {
                        value: 654023,

                        label: "霍城县",
                    },

                    {
                        value: 654024,

                        label: "巩留县",
                    },

                    {
                        value: 654025,

                        label: "新源县",
                    },

                    {
                        value: 654026,

                        label: "昭苏县",
                    },

                    {
                        value: 654027,

                        label: "特克斯县",
                    },

                    {
                        value: 654028,

                        label: "尼勒克县",
                    },
                ],
            },

            {
                value: 654200,

                label: "塔城地区",

                children: [{
                        value: 654201,

                        label: "塔城市",
                    },

                    {
                        value: 654202,

                        label: "乌苏市",
                    },

                    {
                        value: 654221,

                        label: "额敏县",
                    },

                    {
                        value: 654223,

                        label: "沙湾县",
                    },

                    {
                        value: 654224,

                        label: "托里县",
                    },

                    {
                        value: 654225,

                        label: "裕民县",
                    },

                    {
                        value: 654226,

                        label: "和布克赛尔蒙古自治县",
                    },
                ],
            },

            {
                value: 654300,

                label: "阿勒泰地区",

                children: [{
                        value: 654301,

                        label: "阿勒泰市",
                    },

                    {
                        value: 654321,

                        label: "布尔津县",
                    },

                    {
                        value: 654322,

                        label: "富蕴县",
                    },

                    {
                        value: 654323,

                        label: "福海县",
                    },

                    {
                        value: 654324,

                        label: "哈巴河县",
                    },

                    {
                        value: 654325,

                        label: "青河县",
                    },

                    {
                        value: 654326,

                        label: "吉木乃县",
                    },
                ],
            },

            {
                value: 659001,

                label: "石河子市",

                children: [{
                    value: 6590010,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659002,

                label: "阿拉尔市",

                children: [{
                    value: 6590020,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659003,

                label: "图木舒克市",

                children: [{
                    value: 6590030,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659004,

                label: "五家渠市",

                children: [{
                    value: 6590040,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659005,

                label: "北屯市",

                children: [{
                    value: 6590050,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659006,

                label: "铁门关市",

                children: [{
                    value: 6590060,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659007,

                label: "双河市",

                children: [{
                    value: 6590070,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659008,

                label: "可克达拉市",

                children: [{
                    value: 6590080,

                    label: "市辖区",
                }, ],
            },

            {
                value: 659009,

                label: "昆玉市",

                children: [{
                    value: 6590090,

                    label: "市辖区",
                }, ],
            },
        ],
    },

    {
        value: 710000,

        label: "台湾省",

        children: [{
                value: 710100,

                label: "台北市",

                children: [{
                        value: 710101,

                        label: "中正区",
                    },

                    {
                        value: 710102,

                        label: "大同区",
                    },

                    {
                        value: 710103,

                        label: "中山区",
                    },

                    {
                        value: 710104,

                        label: "松山区",
                    },

                    {
                        value: 710105,

                        label: "大安区",
                    },

                    {
                        value: 710106,

                        label: "万华区",
                    },

                    {
                        value: 710107,

                        label: "信义区",
                    },

                    {
                        value: 710108,

                        label: "士林区",
                    },

                    {
                        value: 710109,

                        label: "北投区",
                    },

                    {
                        value: 710110,

                        label: "内湖区",
                    },

                    {
                        value: 710111,

                        label: "南港区",
                    },

                    {
                        value: 710112,

                        label: "文山区",
                    },
                ],
            },

            {
                value: 710200,

                label: "高雄市",

                children: [{
                        value: 710201,

                        label: "新兴区",
                    },

                    {
                        value: 710202,

                        label: "前金区",
                    },

                    {
                        value: 710203,

                        label: "苓雅区",
                    },

                    {
                        value: 710204,

                        label: "盐埕区",
                    },

                    {
                        value: 710205,

                        label: "鼓山区",
                    },

                    {
                        value: 710206,

                        label: "旗津区",
                    },

                    {
                        value: 710207,

                        label: "前镇区",
                    },

                    {
                        value: 710208,

                        label: "三民区",
                    },

                    {
                        value: 710209,

                        label: "左营区",
                    },

                    {
                        value: 710210,

                        label: "楠梓区",
                    },

                    {
                        value: 710211,

                        label: "小港区",
                    },

                    {
                        value: 710242,

                        label: "仁武区",
                    },

                    {
                        value: 710243,

                        label: "大社区",
                    },

                    {
                        value: 710244,

                        label: "冈山区",
                    },

                    {
                        value: 710245,

                        label: "路竹区",
                    },

                    {
                        value: 710246,

                        label: "阿莲区",
                    },

                    {
                        value: 710247,

                        label: "田寮区",
                    },

                    {
                        value: 710248,

                        label: "燕巢区",
                    },

                    {
                        value: 710249,

                        label: "桥头区",
                    },

                    {
                        value: 710250,

                        label: "梓官区",
                    },

                    {
                        value: 710251,

                        label: "弥陀区",
                    },

                    {
                        value: 710252,

                        label: "永安区",
                    },

                    {
                        value: 710253,

                        label: "湖内区",
                    },

                    {
                        value: 710254,

                        label: "凤山区",
                    },

                    {
                        value: 710255,

                        label: "大寮区",
                    },

                    {
                        value: 710256,

                        label: "林园区",
                    },

                    {
                        value: 710257,

                        label: "鸟松区",
                    },

                    {
                        value: 710258,

                        label: "大树区",
                    },

                    {
                        value: 710259,

                        label: "旗山区",
                    },

                    {
                        value: 710260,

                        label: "美浓区",
                    },

                    {
                        value: 710261,

                        label: "六龟区",
                    },

                    {
                        value: 710262,

                        label: "内门区",
                    },

                    {
                        value: 710263,

                        label: "杉林区",
                    },

                    {
                        value: 710264,

                        label: "甲仙区",
                    },

                    {
                        value: 710265,

                        label: "桃源区",
                    },

                    {
                        value: 710266,

                        label: "那玛夏区",
                    },

                    {
                        value: 710267,

                        label: "茂林区",
                    },

                    {
                        value: 710268,

                        label: "茄萣区",
                    },
                ],
            },

            {
                value: 710300,

                label: "台南市",

                children: [{
                        value: 710301,

                        label: "中西区",
                    },

                    {
                        value: 710302,

                        label: "东区",
                    },

                    {
                        value: 710303,

                        label: "南区",
                    },

                    {
                        value: 710304,

                        label: "北区",
                    },

                    {
                        value: 710305,

                        label: "安平区",
                    },

                    {
                        value: 710306,

                        label: "安南区",
                    },

                    {
                        value: 710339,

                        label: "永康区",
                    },

                    {
                        value: 710340,

                        label: "归仁区",
                    },

                    {
                        value: 710341,

                        label: "新化区",
                    },

                    {
                        value: 710342,

                        label: "左镇区",
                    },

                    {
                        value: 710343,

                        label: "玉井区",
                    },

                    {
                        value: 710344,

                        label: "楠西区",
                    },

                    {
                        value: 710345,

                        label: "南化区",
                    },

                    {
                        value: 710346,

                        label: "仁德区",
                    },

                    {
                        value: 710347,

                        label: "关庙区",
                    },

                    {
                        value: 710348,

                        label: "龙崎区",
                    },

                    {
                        value: 710349,

                        label: "官田区",
                    },

                    {
                        value: 710350,

                        label: "麻豆区",
                    },

                    {
                        value: 710351,

                        label: "佳里区",
                    },

                    {
                        value: 710352,

                        label: "西港区",
                    },

                    {
                        value: 710353,

                        label: "七股区",
                    },

                    {
                        value: 710354,

                        label: "将军区",
                    },

                    {
                        value: 710355,

                        label: "学甲区",
                    },

                    {
                        value: 710356,

                        label: "北门区",
                    },

                    {
                        value: 710357,

                        label: "新营区",
                    },

                    {
                        value: 710358,

                        label: "后壁区",
                    },

                    {
                        value: 710359,

                        label: "白河区",
                    },

                    {
                        value: 710360,

                        label: "东山区",
                    },

                    {
                        value: 710361,

                        label: "六甲区",
                    },

                    {
                        value: 710362,

                        label: "下营区",
                    },

                    {
                        value: 710363,

                        label: "柳营区",
                    },

                    {
                        value: 710364,

                        label: "盐水区",
                    },

                    {
                        value: 710365,

                        label: "善化区",
                    },

                    {
                        value: 710366,

                        label: "大内区",
                    },

                    {
                        value: 710367,

                        label: "山上区",
                    },

                    {
                        value: 710368,

                        label: "新市区",
                    },

                    {
                        value: 710369,

                        label: "安定区",
                    },
                ],
            },

            {
                value: 710400,

                label: "台中市",

                children: [{
                        value: 710401,

                        label: "中区",
                    },

                    {
                        value: 710402,

                        label: "东区",
                    },

                    {
                        value: 710403,

                        label: "南区",
                    },

                    {
                        value: 710404,

                        label: "西区",
                    },

                    {
                        value: 710405,

                        label: "北区",
                    },

                    {
                        value: 710406,

                        label: "北屯区",
                    },

                    {
                        value: 710407,

                        label: "西屯区",
                    },

                    {
                        value: 710408,

                        label: "南屯区",
                    },

                    {
                        value: 710431,

                        label: "太平区",
                    },

                    {
                        value: 710432,

                        label: "大里区",
                    },

                    {
                        value: 710433,

                        label: "雾峰区",
                    },

                    {
                        value: 710434,

                        label: "乌日区",
                    },

                    {
                        value: 710435,

                        label: "丰原区",
                    },

                    {
                        value: 710436,

                        label: "后里区",
                    },

                    {
                        value: 710437,

                        label: "石冈区",
                    },

                    {
                        value: 710438,

                        label: "东势区",
                    },

                    {
                        value: 710439,

                        label: "和平区",
                    },

                    {
                        value: 710440,

                        label: "新社区",
                    },

                    {
                        value: 710441,

                        label: "潭子区",
                    },

                    {
                        value: 710442,

                        label: "大雅区",
                    },

                    {
                        value: 710443,

                        label: "神冈区",
                    },

                    {
                        value: 710444,

                        label: "大肚区",
                    },

                    {
                        value: 710445,

                        label: "沙鹿区",
                    },

                    {
                        value: 710446,

                        label: "龙井区",
                    },

                    {
                        value: 710447,

                        label: "梧栖区",
                    },

                    {
                        value: 710448,

                        label: "清水区",
                    },

                    {
                        value: 710449,

                        label: "大甲区",
                    },

                    {
                        value: 710450,

                        label: "外埔区",
                    },

                    {
                        value: 710451,

                        label: "大安区",
                    },
                ],
            },

            {
                value: 710600,

                label: "南投县",

                children: [{
                        value: 710614,

                        label: "南投市",
                    },

                    {
                        value: 710615,

                        label: "中寮乡",
                    },

                    {
                        value: 710616,

                        label: "草屯镇",
                    },

                    {
                        value: 710617,

                        label: "国姓乡",
                    },

                    {
                        value: 710618,

                        label: "埔里镇",
                    },

                    {
                        value: 710619,

                        label: "仁爱乡",
                    },

                    {
                        value: 710620,

                        label: "名间乡",
                    },

                    {
                        value: 710621,

                        label: "集集镇",
                    },

                    {
                        value: 710622,

                        label: "水里乡",
                    },

                    {
                        value: 710623,

                        label: "鱼池乡",
                    },

                    {
                        value: 710624,

                        label: "信义乡",
                    },

                    {
                        value: 710625,

                        label: "竹山镇",
                    },

                    {
                        value: 710626,

                        label: "鹿谷乡",
                    },
                ],
            },

            {
                value: 710700,

                label: "基隆市",

                children: [{
                        value: 710701,

                        label: "仁爱区",
                    },

                    {
                        value: 710702,

                        label: "信义区",
                    },

                    {
                        value: 710703,

                        label: "中正区",
                    },

                    {
                        value: 710704,

                        label: "中山区",
                    },

                    {
                        value: 710705,

                        label: "安乐区",
                    },

                    {
                        value: 710706,

                        label: "暖暖区",
                    },

                    {
                        value: 710707,

                        label: "七堵区",
                    },
                ],
            },

            {
                value: 710800,

                label: "新竹市",

                children: [{
                        value: 710801,

                        label: "东区",
                    },

                    {
                        value: 710802,

                        label: "北区",
                    },

                    {
                        value: 710803,

                        label: "香山区",
                    },
                ],
            },

            {
                value: 710900,

                label: "嘉义市",

                children: [{
                        value: 710901,

                        label: "东区",
                    },

                    {
                        value: 710902,

                        label: "西区",
                    },
                ],
            },

            {
                value: 711100,

                label: "新北市",

                children: [{
                        value: 711130,

                        label: "万里区",
                    },

                    {
                        value: 711131,

                        label: "金山区",
                    },

                    {
                        value: 711132,

                        label: "板桥区",
                    },

                    {
                        value: 711133,

                        label: "汐止区",
                    },

                    {
                        value: 711134,

                        label: "深坑区",
                    },

                    {
                        value: 711135,

                        label: "石碇区",
                    },

                    {
                        value: 711136,

                        label: "瑞芳区",
                    },

                    {
                        value: 711137,

                        label: "平溪区",
                    },

                    {
                        value: 711138,

                        label: "双溪区",
                    },

                    {
                        value: 711139,

                        label: "贡寮区",
                    },

                    {
                        value: 711140,

                        label: "新店区",
                    },

                    {
                        value: 711141,

                        label: "坪林区",
                    },

                    {
                        value: 711142,

                        label: "乌来区",
                    },

                    {
                        value: 711143,

                        label: "永和区",
                    },

                    {
                        value: 711144,

                        label: "中和区",
                    },

                    {
                        value: 711145,

                        label: "土城区",
                    },

                    {
                        value: 711146,

                        label: "三峡区",
                    },

                    {
                        value: 711147,

                        label: "树林区",
                    },

                    {
                        value: 711148,

                        label: "莺歌区",
                    },

                    {
                        value: 711149,

                        label: "三重区",
                    },

                    {
                        value: 711150,

                        label: "新庄区",
                    },

                    {
                        value: 711151,

                        label: "泰山区",
                    },

                    {
                        value: 711152,

                        label: "林口区",
                    },

                    {
                        value: 711153,

                        label: "芦洲区",
                    },

                    {
                        value: 711154,

                        label: "五股区",
                    },

                    {
                        value: 711155,

                        label: "八里区",
                    },

                    {
                        value: 711156,

                        label: "淡水区",
                    },

                    {
                        value: 711157,

                        label: "三芝区",
                    },

                    {
                        value: 711158,

                        label: "石门区",
                    },
                ],
            },

            {
                value: 711200,

                label: "宜兰县",

                children: [{
                        value: 711214,

                        label: "宜兰市",
                    },

                    {
                        value: 711215,

                        label: "头城镇",
                    },

                    {
                        value: 711216,

                        label: "礁溪乡",
                    },

                    {
                        value: 711217,

                        label: "壮围乡",
                    },

                    {
                        value: 711218,

                        label: "员山乡",
                    },

                    {
                        value: 711219,

                        label: "罗东镇",
                    },

                    {
                        value: 711220,

                        label: "三星乡",
                    },

                    {
                        value: 711221,

                        label: "大同乡",
                    },

                    {
                        value: 711222,

                        label: "五结乡",
                    },

                    {
                        value: 711223,

                        label: "冬山乡",
                    },

                    {
                        value: 711224,

                        label: "苏澳镇",
                    },

                    {
                        value: 711225,

                        label: "南澳乡",
                    },
                ],
            },

            {
                value: 711300,

                label: "新竹县",

                children: [{
                        value: 711314,

                        label: "竹北市",
                    },

                    {
                        value: 711315,

                        label: "湖口乡",
                    },

                    {
                        value: 711316,

                        label: "新丰乡",
                    },

                    {
                        value: 711317,

                        label: "新埔镇",
                    },

                    {
                        value: 711318,

                        label: "关西镇",
                    },

                    {
                        value: 711319,

                        label: "芎林乡",
                    },

                    {
                        value: 711320,

                        label: "宝山乡",
                    },

                    {
                        value: 711321,

                        label: "竹东镇",
                    },

                    {
                        value: 711322,

                        label: "五峰乡",
                    },

                    {
                        value: 711323,

                        label: "横山乡",
                    },

                    {
                        value: 711324,

                        label: "尖石乡",
                    },

                    {
                        value: 711325,

                        label: "北埔乡",
                    },

                    {
                        value: 711326,

                        label: "峨眉乡",
                    },
                ],
            },

            {
                value: 711400,

                label: "桃园市",

                children: [{
                        value: 711414,

                        label: "中坜区",
                    },

                    {
                        value: 711415,

                        label: "平镇区",
                    },

                    {
                        value: 711416,

                        label: "龙潭区",
                    },

                    {
                        value: 711417,

                        label: "杨梅区",
                    },

                    {
                        value: 711418,

                        label: "新屋区",
                    },

                    {
                        value: 711419,

                        label: "观音区",
                    },

                    {
                        value: 711420,

                        label: "桃园区",
                    },

                    {
                        value: 711421,

                        label: "龟山区",
                    },

                    {
                        value: 711422,

                        label: "八德区",
                    },

                    {
                        value: 711423,

                        label: "大溪区",
                    },

                    {
                        value: 711424,

                        label: "复兴区",
                    },

                    {
                        value: 711425,

                        label: "大园区",
                    },

                    {
                        value: 711426,

                        label: "芦竹区",
                    },
                ],
            },

            {
                value: 711500,

                label: "苗栗县",

                children: [{
                        value: 711519,

                        label: "竹南镇",
                    },

                    {
                        value: 711520,

                        label: "头份市",
                    },

                    {
                        value: 711521,

                        label: "三湾乡",
                    },

                    {
                        value: 711522,

                        label: "南庄乡",
                    },

                    {
                        value: 711523,

                        label: "狮潭乡",
                    },

                    {
                        value: 711524,

                        label: "后龙镇",
                    },

                    {
                        value: 711525,

                        label: "通霄镇",
                    },

                    {
                        value: 711526,

                        label: "苑里镇",
                    },

                    {
                        value: 711527,

                        label: "苗栗市",
                    },

                    {
                        value: 711528,

                        label: "造桥乡",
                    },

                    {
                        value: 711529,

                        label: "头屋乡",
                    },

                    {
                        value: 711530,

                        label: "公馆乡",
                    },

                    {
                        value: 711531,

                        label: "大湖乡",
                    },

                    {
                        value: 711532,

                        label: "泰安乡",
                    },

                    {
                        value: 711533,

                        label: "铜锣乡",
                    },

                    {
                        value: 711534,

                        label: "三义乡",
                    },

                    {
                        value: 711535,

                        label: "西湖乡",
                    },

                    {
                        value: 711536,

                        label: "卓兰镇",
                    },
                ],
            },

            {
                value: 711700,

                label: "彰化县",

                children: [{
                        value: 711727,

                        label: "彰化市",
                    },

                    {
                        value: 711728,

                        label: "芬园乡",
                    },

                    {
                        value: 711729,

                        label: "花坛乡",
                    },

                    {
                        value: 711730,

                        label: "秀水乡",
                    },

                    {
                        value: 711731,

                        label: "鹿港镇",
                    },

                    {
                        value: 711732,

                        label: "福兴乡",
                    },

                    {
                        value: 711733,

                        label: "线西乡",
                    },

                    {
                        value: 711734,

                        label: "和美镇",
                    },

                    {
                        value: 711735,

                        label: "伸港乡",
                    },

                    {
                        value: 711736,

                        label: "员林市",
                    },

                    {
                        value: 711737,

                        label: "社头乡",
                    },

                    {
                        value: 711738,

                        label: "永靖乡",
                    },

                    {
                        value: 711739,

                        label: "埔心乡",
                    },

                    {
                        value: 711740,

                        label: "溪湖镇",
                    },

                    {
                        value: 711741,

                        label: "大村乡",
                    },

                    {
                        value: 711742,

                        label: "埔盐乡",
                    },

                    {
                        value: 711743,

                        label: "田中镇",
                    },

                    {
                        value: 711744,

                        label: "北斗镇",
                    },

                    {
                        value: 711745,

                        label: "田尾乡",
                    },

                    {
                        value: 711746,

                        label: "埤头乡",
                    },

                    {
                        value: 711747,

                        label: "溪州乡",
                    },

                    {
                        value: 711748,

                        label: "竹塘乡",
                    },

                    {
                        value: 711749,

                        label: "二林镇",
                    },

                    {
                        value: 711750,

                        label: "大城乡",
                    },

                    {
                        value: 711751,

                        label: "芳苑乡",
                    },

                    {
                        value: 711752,

                        label: "二水乡",
                    },
                ],
            },

            {
                value: 711900,

                label: "嘉义县",

                children: [{
                        value: 711919,

                        label: "番路乡",
                    },

                    {
                        value: 711920,

                        label: "梅山乡",
                    },

                    {
                        value: 711921,

                        label: "竹崎乡",
                    },

                    {
                        value: 711922,

                        label: "阿里山乡",
                    },

                    {
                        value: 711923,

                        label: "中埔乡",
                    },

                    {
                        value: 711924,

                        label: "大埔乡",
                    },

                    {
                        value: 711925,

                        label: "水上乡",
                    },

                    {
                        value: 711926,

                        label: "鹿草乡",
                    },

                    {
                        value: 711927,

                        label: "太保市",
                    },

                    {
                        value: 711928,

                        label: "朴子市",
                    },

                    {
                        value: 711929,

                        label: "东石乡",
                    },

                    {
                        value: 711930,

                        label: "六脚乡",
                    },

                    {
                        value: 711931,

                        label: "新港乡",
                    },

                    {
                        value: 711932,

                        label: "民雄乡",
                    },

                    {
                        value: 711933,

                        label: "大林镇",
                    },

                    {
                        value: 711934,

                        label: "溪口乡",
                    },

                    {
                        value: 711935,

                        label: "义竹乡",
                    },

                    {
                        value: 711936,

                        label: "布袋镇",
                    },
                ],
            },

            {
                value: 712100,

                label: "云林县",

                children: [{
                        value: 712121,

                        label: "斗南镇",
                    },

                    {
                        value: 712122,

                        label: "大埤乡",
                    },

                    {
                        value: 712123,

                        label: "虎尾镇",
                    },

                    {
                        value: 712124,

                        label: "土库镇",
                    },

                    {
                        value: 712125,

                        label: "褒忠乡",
                    },

                    {
                        value: 712126,

                        label: "东势乡",
                    },

                    {
                        value: 712127,

                        label: "台西乡",
                    },

                    {
                        value: 712128,

                        label: "仑背乡",
                    },

                    {
                        value: 712129,

                        label: "麦寮乡",
                    },

                    {
                        value: 712130,

                        label: "斗六市",
                    },

                    {
                        value: 712131,

                        label: "林内乡",
                    },

                    {
                        value: 712132,

                        label: "古坑乡",
                    },

                    {
                        value: 712133,

                        label: "莿桐乡",
                    },

                    {
                        value: 712134,

                        label: "西螺镇",
                    },

                    {
                        value: 712135,

                        label: "二仑乡",
                    },

                    {
                        value: 712136,

                        label: "北港镇",
                    },

                    {
                        value: 712137,

                        label: "水林乡",
                    },

                    {
                        value: 712138,

                        label: "口湖乡",
                    },

                    {
                        value: 712139,

                        label: "四湖乡",
                    },

                    {
                        value: 712140,

                        label: "元长乡",
                    },
                ],
            },

            {
                value: 712400,

                label: "屏东县",

                children: [{
                        value: 712434,

                        label: "屏东市",
                    },

                    {
                        value: 712435,

                        label: "三地门乡",
                    },

                    {
                        value: 712436,

                        label: "雾台乡",
                    },

                    {
                        value: 712437,

                        label: "玛家乡",
                    },

                    {
                        value: 712438,

                        label: "九如乡",
                    },

                    {
                        value: 712439,

                        label: "里港乡",
                    },

                    {
                        value: 712440,

                        label: "高树乡",
                    },

                    {
                        value: 712441,

                        label: "盐埔乡",
                    },

                    {
                        value: 712442,

                        label: "长治乡",
                    },

                    {
                        value: 712443,

                        label: "麟洛乡",
                    },

                    {
                        value: 712444,

                        label: "竹田乡",
                    },

                    {
                        value: 712445,

                        label: "内埔乡",
                    },

                    {
                        value: 712446,

                        label: "万丹乡",
                    },

                    {
                        value: 712447,

                        label: "潮州镇",
                    },

                    {
                        value: 712448,

                        label: "泰武乡",
                    },

                    {
                        value: 712449,

                        label: "来义乡",
                    },

                    {
                        value: 712450,

                        label: "万峦乡",
                    },

                    {
                        value: 712451,

                        label: "崁顶乡",
                    },

                    {
                        value: 712452,

                        label: "新埤乡",
                    },

                    {
                        value: 712453,

                        label: "南州乡",
                    },

                    {
                        value: 712454,

                        label: "林边乡",
                    },

                    {
                        value: 712455,

                        label: "东港镇",
                    },

                    {
                        value: 712456,

                        label: "琉球乡",
                    },

                    {
                        value: 712457,

                        label: "佳冬乡",
                    },

                    {
                        value: 712458,

                        label: "新园乡",
                    },

                    {
                        value: 712459,

                        label: "枋寮乡",
                    },

                    {
                        value: 712460,

                        label: "枋山乡",
                    },

                    {
                        value: 712461,

                        label: "春日乡",
                    },

                    {
                        value: 712462,

                        label: "狮子乡",
                    },

                    {
                        value: 712463,

                        label: "车城乡",
                    },

                    {
                        value: 712464,

                        label: "牡丹乡",
                    },

                    {
                        value: 712465,

                        label: "恒春镇",
                    },

                    {
                        value: 712466,

                        label: "满州乡",
                    },
                ],
            },

            {
                value: 712500,

                label: "台东县",

                children: [{
                        value: 712517,

                        label: "台东市",
                    },

                    {
                        value: 712518,

                        label: "绿岛乡",
                    },

                    {
                        value: 712519,

                        label: "兰屿乡",
                    },

                    {
                        value: 712520,

                        label: "延平乡",
                    },

                    {
                        value: 712521,

                        label: "卑南乡",
                    },

                    {
                        value: 712522,

                        label: "鹿野乡",
                    },

                    {
                        value: 712523,

                        label: "关山镇",
                    },

                    {
                        value: 712524,

                        label: "海端乡",
                    },

                    {
                        value: 712525,

                        label: "池上乡",
                    },

                    {
                        value: 712526,

                        label: "东河乡",
                    },

                    {
                        value: 712527,

                        label: "成功镇",
                    },

                    {
                        value: 712528,

                        label: "长滨乡",
                    },

                    {
                        value: 712529,

                        label: "金峰乡",
                    },

                    {
                        value: 712530,

                        label: "大武乡",
                    },

                    {
                        value: 712531,

                        label: "达仁乡",
                    },

                    {
                        value: 712532,

                        label: "太麻里乡",
                    },
                ],
            },

            {
                value: 712600,

                label: "花莲县",

                children: [{
                        value: 712615,

                        label: "花莲市",
                    },

                    {
                        value: 712616,

                        label: "新城乡",
                    },

                    {
                        value: 712618,

                        label: "秀林乡",
                    },

                    {
                        value: 712619,

                        label: "吉安乡",
                    },

                    {
                        value: 712620,

                        label: "寿丰乡",
                    },

                    {
                        value: 712621,

                        label: "凤林镇",
                    },

                    {
                        value: 712622,

                        label: "光复乡",
                    },

                    {
                        value: 712623,

                        label: "丰滨乡",
                    },

                    {
                        value: 712624,

                        label: "瑞穗乡",
                    },

                    {
                        value: 712625,

                        label: "万荣乡",
                    },

                    {
                        value: 712626,

                        label: "玉里镇",
                    },

                    {
                        value: 712627,

                        label: "卓溪乡",
                    },

                    {
                        value: 712628,

                        label: "富里乡",
                    },
                ],
            },

            {
                value: 712700,

                label: "澎湖县",

                children: [{
                        value: 712707,

                        label: "马公市",
                    },

                    {
                        value: 712708,

                        label: "西屿乡",
                    },

                    {
                        value: 712709,

                        label: "望安乡",
                    },

                    {
                        value: 712710,

                        label: "七美乡",
                    },

                    {
                        value: 712711,

                        label: "白沙乡",
                    },

                    {
                        value: 712712,

                        label: "湖西乡",
                    },
                ],
            },
        ],
    },

    {
        value: 810000,

        label: "香港特别行政区",

        children: [{
                value: 810101,

                label: "中西区",

                children: [{
                    value: 8101010,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810102,

                label: "东区",

                children: [{
                    value: 8101020,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810103,

                label: "九龙城区",

                children: [{
                    value: 8101030,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810104,

                label: "观塘区",

                children: [{
                    value: 8101040,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810105,

                label: "南区",

                children: [{
                    value: 8101050,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810106,

                label: "深水埗区",

                children: [{
                    value: 8101060,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810107,

                label: "湾仔区",

                children: [{
                    value: 8101070,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810108,

                label: "黄大仙区",

                children: [{
                    value: 8101080,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810109,

                label: "油尖旺区",

                children: [{
                    value: 8101090,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810110,

                label: "离岛区",

                children: [{
                    value: 8101100,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810111,

                label: "葵青区",

                children: [{
                    value: 8101110,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810112,

                label: "北区",

                children: [{
                    value: 8101120,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810113,

                label: "西贡区",

                children: [{
                    value: 8101130,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810114,

                label: "沙田区",

                children: [{
                    value: 8101140,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810115,

                label: "屯门区",

                children: [{
                    value: 8101150,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810116,

                label: "大埔区",

                children: [{
                    value: 8101160,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810117,

                label: "荃湾区",

                children: [{
                    value: 8101170,

                    label: "市辖区",
                }, ],
            },

            {
                value: 810118,

                label: "元朗区",

                children: [{
                    value: 8101180,

                    label: "市辖区",
                }, ],
            },
        ],
    },

    {
        value: 820000,

        label: "澳门特别行政区",

        children: [{
                value: 820101,

                label: "澳门半岛",

                children: [{
                    value: 8201010,

                    label: "市辖区",
                }, ],
            },

            {
                value: 820102,

                label: "凼仔",

                children: [{
                    value: 8201020,

                    label: "市辖区",
                }, ],
            },

            {
                value: 820103,

                label: "路凼城",

                children: [{
                    value: 8201030,

                    label: "市辖区",
                }, ],
            },

            {
                value: 820104,
                label: "路环",
                children: [{
                    value: 8201040,
                    label: "市辖区",
                }, ],
            },
        ],
    },
];
export default area;