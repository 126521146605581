<template>
  <div class="potential">
    <img src="../../assets/potential-bg.png" alt=""/>
    <div class="form">
      <div class="info">
        <div class="form-title">
          <div class="title">欢迎您申请入驻企修通</div>
        </div>
        <div class="form-content">
          <el-form ref="form" :rules="rules" :model="form" label-width="100px">
            <el-form-item label="使用业务" prop="use_type">
              <el-radio-group v-model="form.use_type">
                <el-radio :label="1">全选</el-radio>
                <el-radio :label="2">租赁</el-radio>
                <el-radio :label="3">维保</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="企业性质">
              <el-select
                  v-model="form.type"
                  placeholder="请选择企业性质"
                  style="width:100%"
              >
                <el-option
                    v-for="item in cptypearr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业地址">
              <el-cascader
                  style="width:100%"
                  v-model="form.area"
                  :options="options"
                  :props="{ expandTrigger: 'hover' }"
                  clearable
                  placeholder="请选择企业地址"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="contract_name">
              <el-input v-model="form.contract_name"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="contacter_mobile">
              <el-input
                  v-model="form.contacter_mobile"
                  placeholder="手机、电话二选一"
              ></el-input>
            </el-form-item>
            <el-form-item label="固定电话">
              <el-input
                  v-model="form.contacter_phone"
                  placeholder="手机、电话二选一"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" @click="onSubmit('form')"
              >立即申请
              </el-button
              >
            </el-form-item>
            <el-form-item>
              <div class="reg-potential">
                <el-checkbox v-model="checked"></el-checkbox>
                <label style="margin-left: -10px">我已经阅读并同意</label>
                <router-link :to="'/ruler?index=1'" target="_blank">《服务协议》</router-link>
                <label>、</label>
                <router-link :to="'/ruler?index=2'" target="_blank">《隐私政策》</router-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import area from "@/assets/js/china.js";

const cptypearr = [
  {
    value: 10,
    label: "事业单位",
  },
  {
    value: 1,
    label: "国有企业",
  },
  {
    value: 6,
    label: "私营企业",
  },
  {
    value: 3,
    label: "合资企业",
  },
  {
    value: 11,
    label: "外资企业",
  },
  {
    value: 7,
    label: "个体户",
  },
  {
    value: 9,
    label: "其他",
  },
];
export default {
  data () {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[3-9]\d{9}$/;
      if (value == "" || !value) {
        callback();
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查手机格式"));
        } else {
          callback();
        }
      }
    };
    var validateEmail = (rule, value, callback) => {
      let reg = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
      if (value == "" || !value) {
        callback();
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查邮箱格式"));
        } else {
          callback();
        }
      }
    };
    return {
      checked: false,
      code: "",
      form: {
        type: [],
        use_type: "",
        area: [],
        address: "",
        name: "",
        contract_name: "",
        contacter_phone: "",
        contacter_mobile: "",
        email: "",
      },
      options: area,
      cptypearr: cptypearr,
      rules: {
        use_type: [
          {
            required: true,
            message: "请至少选择一种使用业务",
            trigger: "change",
          },
        ],
        name: [{required: true, message: "请输入企业名称", trigger: "blur"}],
        contract_name: [
          {required: true, message: "请输入联系人姓名", trigger: "blur"},
        ],
        contacter_mobile: [{validator: validateMobile, trigger: "blur"}],
        email: [{validator: validateEmail, trigger: "blur"}],
      },
      imageList: []
    };
  },
  created () {
    this.code = this.$route.query.code;
    // let osCode = ["iPhone", "Android", "Windows Phone"];
    // let os = window.navigator.userAgent;
    // let i = 0;
    // while (i < osCode.length) {
    //   if (os.indexOf(osCode[i]) != -1) {
    //     window.location.href =
    //       "https://m.matefix.cn/#/potential?code=" + this.code;
    //     return;
    //   }
    //   i++;
    // }
    // for (var i = 0; i < osCode.length; i++) {
    //   if (os.indexOf(osCode[i]) != -1) {
    //     window.location.href =
    //       "https://m.matefix.cn/#/potential?code=" + this.code;
    //     return;
    //   } else {
    //     return;
    //   }
    // }
  },
  methods: {
    onSubmit (formName) {
      this.$confirm("入驻请拨打电话：023-86193648", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {

        })
        .catch(() => {

        });
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     let params = {
      //       ...this.form,
      //       province: this.form.area[0],
      //       city: this.form.area[1],
      //       town: this.form.area[2],
      //       code: this.code,
      //     };
      //     delete params.area;
      //     if(this.checked==false){
      //       this.$message.warning("请勾选服务协议、隐私政策");
      //       return
      //     }
      //     this.$api.getPotential(params).then((res) => {
      //       if (res.code == 200) {
      //         this.$router.push({ path: "success" });
      //       } else {
      //         this.$message({
      //           message: res.msg,
      //           type: "warning",
      //         });
      //       }
      //     });
      //   } else {
      //     this.$message({
      //       message: "提交失败！！",
      //       type: "warning",
      //     });
      //     return false;
      //   }
      // });
    },
  },
};
</script>

<style scoped>
  .potential {
    position: relative;
    /* width: 100%; */
    height: 950px;
    background: #f7f7f7;
  }

  .form {
    width: 100%;
    height: 950px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    border: 1px solid #fff;
    width: 770px;
    height: 800px;
    /* margin: 120px auto; */
    background: #ffffff;
    border-radius: 10px;
  }

  .form-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0;
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
  }

  .form-content {
    margin: 0 141px 40px 126px;
  }

  .el-button--warning {
    width: 100%;
    height: 48px;
    color: #fff;
    background-color: #1F5FFF;
    border-color: #1F5FFF;
  }

  .reg-potential {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    height: 60px;
  }

  .reg-potential a {
    text-decoration: none;
    color: blue;
    cursor: pointer;
  }
</style>
