<template>
  <div class="teamwork">
    <div class="title">
      合作流程
    </div>
    <img class="teamwork-img" src="../../assets/client/teamwork.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "teamwork",
};
</script>

<style scoped>
.teamwork {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teamwork-img {
  width: 1200px;
  height: 410px;
}
.title {
  margin: 80px auto;
  width: 163px;
  height: 41px;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #333333;
}
</style>
