<template>
  <div class="highlights">
    <div class="top">
      <div class="title">产品亮点</div>
    </div>
    <div class="swiper">
      <el-carousel :interval="4000" type="card" height="300px">
        <el-carousel-item v-for="(item, index) in info" :key="index">
          <div class="swiper-item">
            <img class="item-img" :src="item.icon" alt="" />
            <div class="item-title">{{ item.title }}</div>
            <div class="item-dec">{{ item.dec }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/client/highlights1.png";
import img2 from "@/assets/client/highlights2.png";
import img3 from "@/assets/client/highlights3.png";
import img4 from "@/assets/client/highlights4.png";
import img5 from "@/assets/client/highlights5.png";
export default {
  name: "highlights",
  data() {
    return {
      info: [
        {
          icon: img1,
          title: "员工管理",
          dec: "客户可进行组织架构及报修员工管理。",
        },
        {
          icon: img2,
          title: "设备管理",
          dec: "客户设备及设备码管理。 ",
        },
        {
          icon: img3,
          title: "订单管理",
          dec:
            "服务商设备及统计订单历史，产出自有与租赁设备的采购、租赁与全包成本、收支报表。设备码管理。 ",
        },
        {
          icon: img4,
          title: "报修方式",
          dec: "员工可使用微信扫一扫报修、在线扫码、电话报修。 ",
        },
        {
          icon: img5,
          title: "报修审核",
          dec: "可对员工所提交的维保订单进行审核。",
        },
      ],
    };
  },
};
</script>

<style scoped>
.highlights {
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
  background-color: #f8f8f8;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.swiper {
  margin: 80px auto;
  margin-bottom: 0;
  width: 1200px;
}
.swiper-item {
  /* width: 342px; */
  height: 292px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1), -6px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.item-img {
  width: 80px;
  height: 80px;
  margin: 40px 131px;
}
.item-title {
  max-width: 285px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin: 0 auto;
}
.item-dec {
  margin: 20px auto;
  max-width: 242px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
}

/deep/ .el-carousel__arrow {
  background: #dddddd;
  border-radius: 4px;
  width: 50px;
  height: 50px;
}
/deep/ .el-carousel__arrow:hover {
  background: #1F5FFF;
}

/deep/ .el-carousel__button {
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border-radius: 50%;
}
/deep/ .el-carousel__indicator.is-active button {
  background: #1F5FFF;
}
</style>
