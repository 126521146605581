<template>
  <div class="success">
    <img src="../../assets/potential-bg.png" alt=""/>
    <div class="box">
      <div class="info">
        <div class="title">欢迎您申请入驻企修通</div>
        <div class="tips">
          入驻申请提交完成后，稍后几个工作日，我们会联系您。
        </div>
        <div class="code">
          <img class="code-img" src="../../assets/publicCode.png" alt=""/>
        </div>
        <div class="code-title">更多信息请关注“企修通”公众号</div>
        <el-button type="warning" @click="sureToClose">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    sureToClose () {
      this.$confirm("确认关闭当前页面吗？(如未自动关闭网页，请手动关闭)")
        .then((_) => {
          if (navigator.userAgent.indexOf("MSIE") > 0) {
            // IE
            if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
              // IE6
              window.opener = null;
              window.close();
            } else {
              // IE6+
              window.open("", "_top");
              window.top.close();
            }
          } else if (
            navigator.userAgent.indexOf("Firefox") > 0 ||
            navigator.userAgent.indexOf("Presto") > 0
          ) {
            // FF和Opera
            window.location.href = "about:blank";
            window.close(); // 火狐默认状态非window.open的页面window.close是无效的
          } else {
            window.opener = null;
            window.open("", "_self", "");
            window.close();
          }
        })
        .catch((_) => {
        });
    },
  },
};
</script>

<style scoped>
  .success {
    position: relative;
    width: 100%;
    height: 950px;
    background: #f7f7f7;
  }

  .el-button--warning {
    width: 172px;
    height: 48px;
    color: #fff;
    background-color: #1F5FFF;
    border-color: #1F5FFF;
  }

  .box {
    width: 1920px;
    height: 950px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .info {
    width: 765px;
    height: 588px;
    background-color: #fff;
    margin: 180px auto;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 46px 0 32px 0;
  }

  .tips {
    font-size: 16px;
    text-align: right;
    color: #333333;
  }

  .code {
    margin: 48px 0 24px 0;
    width: 220px;
    height: 220px;
  }

  .code-img {
    width: 100%;
    height: 100%;
  }

  .code-title {
    font-size: 16px;
    line-height: 16px;
    text-align: right;
    color: #333333;
    margin-bottom: 58px;
  }
</style>
