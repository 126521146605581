<template>
  <div class="partner">
    <div class="top">
      <div class="title">
        入驻对象
      </div>
    </div>
    <img
      class="partner-img"
      src="../../assets/serve/partner.png"
      alt="合作伙伴"
    />
  </div>
</template>

<script>
export default {
  name: "partner",
};
</script>

<style scoped>
.partner {
  width: 100%;
  margin: 120px auto;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #333333;
}
.partner-img {
  width: 1920px;
  height: 620px;
  margin-top: 80px;
}
</style>
