<template>
  <div class="process">
    <div class="top">
      <div class="title">
        报修服务流程图
      </div>
      <img
        class="process-img"
        src="../../assets/serve/process-img.png"
        alt=""
      />
      <img
        class="client-qrcode"
        src="../../assets/serve/client-qrcode.png"
        alt=""
      />
      <img
        class="enginer-qrcode"
        src="../../assets/serve/engineer.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name:'process'
};
</script>

<style scoped>
.process {
  width: 100%;
  margin: 120px auto;
}
.top {
  position: relative;
  width: 1200px;
  height: 645px;
  margin: 0 auto;
  margin-top: 95px;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 32px;
  text-align: center;
  color: #333333;
}
.process-img {
  position: absolute;
  left: 0px;
  top: 135px;
  width: 974px;
  height: 510px;
}
.client-qrcode {
  position: absolute;
  right: 0px;
  top: 170px;
  width: 148px;
  height: 182px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
}
.enginer-qrcode {
  position: absolute;
  right: 0px;
  bottom: 64px;
  width: 148px;
  height: 182px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
}
</style>
