<template>
  <div class="serve">
    <div class="top">
      <div class="title">
        服务商合作
      </div>
    </div>
    <div class="box">
      <img class="servce-icon" src="../../assets/serve/serve-icon.png" alt="" />
      <div class="info">
        企修通依托集团（欧菲斯办公伙伴控股有限公司）资质，通过全国的维修、维保、租赁、全包业务投标，进行获客。招募维修、维保、租赁、全包服务商企业，共建
        B2B大办公物资智能服务平台。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serve",
};
</script>

<style scoped>
.serve {
  width: 100%;
  margin: 0 auto;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 32px;
  text-align: center;
  color: #333333;
}
.box {
  position: relative;
  margin: 120px auto;
  width: 1190px;
  height: 178px;
  background: #ffffff;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.05),
    -6px 0px 10px rgba(0, 0, 0, 0.05);
}
.servce-icon {
  position: absolute;
  left: 109px;
  bottom: 26px;
  width: 260px;
  height: 200px;
}
.info {
  position: absolute;
  right: 110px;
  top: 48px;
  width: 637px;
  height: 81px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #666666;
}
</style>
