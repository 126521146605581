<template>
  <div class="header">
    <div class="header-bottom">
      <div class="bottom-line">
        <div class="line-nav">
          <img src="@/assets/img/logo.png" alt="logo" class="logo comimg"/>
          <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              background-color="#185CFF"
              text-color="#fff"
              active-text-color="#fff"
              router
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/productIntroduction">产品介绍</el-menu-item>
            <el-menu-item index="#">
              <el-dropdown placement="bottom" @command="handleCommand">
                <span class="el-dropdown-link">
                  场景化解决方案
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">运维场景解决方案</el-dropdown-item>
                  <el-dropdown-item :command="2">智能资产盘点场景解决方案</el-dropdown-item>
                  <el-dropdown-item :command="3">全行业专业资产处置场景解决方案</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="/solution">关于我们</el-menu-item>
            <el-menu-item index="/about">招商联系</el-menu-item>
          </el-menu>
        </div>
        <div class="line-nav">
          <div class="line-nav-btn" style="margin-right: 10px;width: 150px"><i class="el-icon-phone"></i>
            023-861903648
          </div>
          <div class="line-nav-btn"><a class="loginBtn" style="color: #185CFF" href="https://qxt.matefix.cn/#/login ">登录管理后台</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data () {
    return {
      activeIndex: "/",
    };
  },
  created () {
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleCommand (val) {
      // console.log(val)
      if (val == 1) {
        this.$router.push('/solution')
      } else if (val == 2) {
        this.$router.push('/pdSoluting')
      } else {
        this.$router.push('/zcSolution')
      }
    },
    goback () {
      window.location = "https://matefix.cn/";
    },
    gotoRegister () {
      this.$alert('注册请拨打电话：023-86193648', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          console.log(action)
        }
      });
    },
    handleSelect (val) {
      //
    },
  },
  watch: {
    $route (to, from) {
      this.activeIndex = this.$route.path;
    },
  },
};
</script>

<style scoped>
  .loginBtn {
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 12px;
  }

  .header {
    width: 1920px;
    padding: 0;
  }

  .header-top {
    width: 100%;
    height: 40px;
    background-color: #fff;
  }

  .top-line {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .line-right {
    display: flex;
    font-size: 14px;
  }

  .line-left {
    display: flex;
    font-size: 14px;
    align-items: center;
    line-height: 1;
  }

  .line {
    margin: 0 16px;
    width: 1px;
    background: #ff8b03;
  }

  .header-bottom {
    width: 100%;
    height: 76px;
    background: #185CFF;
    mix-blend-mode: normal;
    display: flex;
    align-items: center;
  }

  .bottom-line {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .line-nav {
    display: flex;
  }

  .line-nav .line-nav-btn {
    text-align: center;
    color: #ffffff;
    width: 108px;
    height: 76px;
    font-size: 14px;
    line-height: 76px;
    cursor: pointer;
  }

  .line-nav .line-nav-btn:hover {
    background: rgba(0, 0, 0, 0);
  }

  .line-nav .line-nav-btn a {
    width: 100%;
    height: 100%;
    color: #ffffff;
    text-decoration: none;
  }

  .logo {
    width: 166px;
    height: 48px;
    margin-top: 14px;
  }

  .el-button--text {
    font-size: 14px;
    color: #000;
    margin-left: 52px;
  }

  .el-menu-demo {
    margin-left: 62px;
  }

  .el-dropdown-link {
    height: 60px;
    display: flex;
    align-items: center;
    color: #fff;
  }
</style>
