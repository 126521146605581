<template>
  <div class="product">
    <div class="top">
      <div class="title">核心产品</div>
      <div class="dec">企修通服务商端</div>
    </div>
    <div class="info">
      企修通服务商端作为管理终端，支持电脑端和手机移动式（移动式办公后续版本更新）办公管理，进行调度和管理，可对维修/维保订单进行监管，对工程师、客户设备和自有设备进行管理，产出业绩与报表统计。
    </div>
    <img class="qxt-serve" src="../../assets/serve/qxt-serve.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "product",
};
</script>

<style scoped>
.product {
  width: 100%;
  margin: 0 auto;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.dec {
  font-weight: normal;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
  color: #666666;
}
.info {
  width: 942px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #666666;
  margin: 80px auto;
}
.qxt-serve {
  display: block;
  width: 900px;
  height: 470px;
  margin: 0 auto;
  margin-bottom: 80px;
}
</style>
