<template>
  <div class="layout">
    <!--    已弃用-->
    <Header></Header>
    <div class="qxt_cont" id="div-nav">
      <keep-alive>
        <router-view/>
      </keep-alive>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped>
  .layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow: hidden;
  }

  .qxt_cont {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;

  }
</style>
