<template>
  <div class="platform">
    <div class="top">
      <div class="title">
        平台赋能
      </div>
    </div>
    <div class="box">
      <div
        v-for="(ele, index) in info"
        :key="index"
        class="item"
        @mouseover="mouseOver(index)"
        @mouseout="mouseOut(index)"
        :style="active[index]"
      >
        <img class="img" ref="img" :src="imgList[index]" alt="" />
        <div class="item-title" ref="title">{{ ele.title }}</div>
        <div class="item-dec" ref="dec">
          {{ ele.dec }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/client/platform1.png";
import img2 from "@/assets/client/platform2.png";
import img3 from "@/assets/client/platform3.png";
import img4 from "@/assets/client/platform4.png";
import img5 from "@/assets/client/platform5.png";
import img6 from "@/assets/client/platform6.png";
import hover1 from "@/assets/client/platform-hover1.png";
import hover2 from "@/assets/client/platform-hover2.png";
import hover3 from "@/assets/client/platform-hover3.png";
import hover4 from "@/assets/client/platform-hover4.png";
import hover5 from "@/assets/client/platform-hover5.png";
import hover6 from "@/assets/client/platform-hover6.png";
export default {
  name: "platform",
  data() {
    return {
      active: ["", "", "", "", "", ""],
      imgList: [img1, img2, img3, img4, img5, img6],
      hoverList: [hover1, hover2, hover3, hover4, hover5, hover6],
      recordList: [img1, img2, img3, img4, img5, img6],
      info: [
        {
          title: "业务赋能",
          dec: "中标项目潜在合作伙伴、当地唯一服务商、 本地服务。",
        },
        {
          title: "管理流程规范 ",
          dec: "设备报修、管理流程规范，让设备维修/维保更简单。",
        },
        {
          title: "维护成本可视化",
          dec:
            "通过维保订单可清晰了解设备维护成本、问题成因、维保手段，辅助客户判断设备状况，更易控制成本。",
        },
        {
          title: "智能化管理",
          dec:
            "SAAS、极简报修工具、IOT等工具型服务，帮助企业升级办公设备，让企业简化管理程序、提高工作效率。",
        },
        {
          title: "闲废资产再利用",
          dec:
            "对闲置资产、报废资产进行回收、撮合交易，让客户资产合理利用，最大限度地使闲置资产转化为优质资源，达到降本增效的目的。",
        },
        {
          title: "合规与灵活性",
          dec:
            "本产品已通过IPO审计的数据与流程合规性，兼顾合规、灵活性、产品交互体验。",
        },
      ],
    };
  },
  methods: {
    mouseOver(index) {
      this.active = this.active.map((ele, ind) => {
        if (ind == index) {
          ele = "background-color: #1F5FFF;";
          this.imgList[index] = this.hoverList[index];
          this.$refs.title[index].style.color = "#fff";
          this.$refs.dec[index].style.color = "#fff";
        }
        return ele;
      });
    },

    mouseOut(index) {
      this.active = this.active.map((ele, ind) => {
        if (ind == index) {
          ele = "background-color: #fff;";
          this.imgList[index] = this.recordList[index];
          this.$refs.title[index].style.color = "#333333";
          this.$refs.dec[index].style.color = "#666666";
        }
        return ele;
      });
    },
  },
};
</script>

<style scoped>
.platform {
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
  background-color: #f8f8f8;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #333333;
}
.box {
  width: 1220px;
  height: 700px;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.item {
  width: 383px;
  height: 340px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 20px 10px;
}
.img {
  width: 100px;
  height: 100px;
  margin: 48px 0 36px 0;
}

.item-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: justify;
  color: #333333;
  margin-bottom: 24px;
}
.item-dec {
  max-width: 290px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #666666;
}
</style>
