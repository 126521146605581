<template>
  <div class="product">
    <div class="top">
      <div class="title">核心产品</div>
      <div class="dec">企修通客户端</div>
      <div class="info">
        企修通客户端作为管理终端，支持电脑端和手机移动式（移动式办公后续版本更新）办公管理，可对维修/维保订单监管，对自有员工、设备进行管理，产出业绩与报表统计。
      </div>
      <img class="qxt-client" src="../../assets/client/qxt-client.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
};
</script>

<style scoped>
.product {
  width: 100%;
  margin: 0 auto;
}
.top {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.dec {
  font-weight: normal;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
  color: #666666;
}
.info {
  margin: 80px auto;
  width: 942px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #666666;
}
.qxt-client {
  margin: 0 auto;
  width: 900px;
  height: 470px;
}
</style>
