<template>
  <div class="layout">
    <common-header></common-header>
    <div class="qxt_cont" ref="qxtcont">
      <router-view/>
      <Footer></Footer>
      <div class="give-msg">
        <img style="width: 100%;" @click="giveContract" src="@/assets/img/btn_01_01.png">
        <img style="width: 100%;" @click="gotoTop" src="@/assets/img/btn_top_03.png">
      </div>
    </div>
  </div>
</template>
<script>
import CommonHeader from "./CommonHeader.vue";
import Footer from "./Footer.vue";

export default {
  name: "Layout",
  components: {
    CommonHeader,
    Footer,
  },
  methods: {
    giveContract () {
      //  留下信息
      this.$router.push('/free')
    },
    gotoTop () {
      this.$refs.qxtcont.scrollTop = 0
    }
  }
};
</script>
<style scoped>
  .give-msg {
    position: fixed;
    right: 0px;
    top: 60vh;
    width: 59px;
    height: 235px;
    z-index: 999;
    cursor: pointer;
  }

  .layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow: hidden;
  }

  .qxt_cont {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
  }
</style>
