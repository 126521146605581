<template>
  <div class="client">
    <div class="top">
      <div class="title">客户合作</div>
    </div>
    <div class="box">
      <img
        class="client-icon"
        src="../../assets/client/client-icon.png"
        alt=""
      />
      <div class="info">
        企修通依托集团（欧菲斯办公伙伴控股有限公司）全国3000+服务网点，与行业专家合作，搭建B2B大办公物资智能服务平台。
        <br />
        <br />
        企修通作为产业互联网平台，核心品类为集团从业27年的办公设备，与合作伙伴扩展电器/家具/场所等品类，为提供客户专业的服务；以SAAS、物联网、小程序作为高效易用的业务与管理工具，提升服务效率、提高客户管理能力。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "client",
};
</script>

<style scoped>
.client {
  width: 100%;
  margin: 120px auto;
}
.top {
  width: 100%;
  text-align: center;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.box {
  position: relative;
  margin: 120px auto;
  width: 1190px;
  height: 240px;
  background: #ffffff;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.05),
    -6px 0px 10px rgba(0, 0, 0, 0.05);
}
.client-icon {
  position: absolute;
  left: 110px;
  bottom: 82px;
  width: 270px;
  height: 207px;
}
.info {
  position: absolute;
  right: 110px;
  top: 48px;
  width: 630px;
  height: 144px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #666666;
}
</style>
