<template>
  <div class="features">
    <div class="top">
      <div class="title">多项功能支撑</div>
      <div class="dec">给服务商提供各类解决方案</div>
    </div>
    <div class="box1">
      <el-card style="width: 24.4%; height: 19.75vw" shadow="hover" v-for="(item, index) in info" :key="index">
        <div
          class="item"
          @mouseover="mouseOver(index)"
          @mouseout="mouseOut(index)"
          :style="active[index]"
        >
          <img class="img" :src="imgList[index]" alt="" />
          <div class="item-title">{{ item.title }}</div>
          <div class="item-dec">
            {{ item.dec }}
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import hover1 from "@/assets/serve/features-hover1.png";
import hover2 from "@/assets/serve/features-hover2.png";
import hover3 from "@/assets/serve/features-hover3.png";
import hover4 from "@/assets/serve/features-hover4.png";
import hover5 from "@/assets/serve/features-hover5.png";
import hover6 from "@/assets/serve/features-hover6.png";
import hover7 from "@/assets/serve/features-hover7.png";
import hover8 from "@/assets/serve/features-hover8.png";
import img1 from "@/assets/serve/features1.png";
import img2 from "@/assets/serve/features2.png";
import img3 from "@/assets/serve/features3.png";
import img4 from "@/assets/serve/features4.png";
import img5 from "@/assets/serve/features5.png";
import img6 from "@/assets/serve/features6.png";
import img7 from "@/assets/serve/features7.png";
import img8 from "@/assets/serve/features8.png";
export default {
  name: "features",
  data() {
    return {
      active: ["", "", "", "", "", "", "", ""],
      imgList: [img1, img2, img3, img4, img5, img6, img7, img8],
      hoverList: [
        hover1,
        hover2,
        hover3,
        hover4,
        hover5,
        hover6,
        hover7,
        hover8,
      ],
      recordList: [img1, img2, img3, img4, img5, img6, img7, img8],
      info: [
        {
          title: "全国性维修/保养/租赁服务",
          dec:
            "办公设备的本地化安装、维修、维保、配件更换、耗材补充服务，包括极简报修工具与调度平台；租赁客户的风险评估信息。",
        },
        {
          title: "投标支持",
          dec: "凭借企修通的专业性与雄厚的实力对服务商进行投标支持。",
        },
        {
          title: "闲置资产",
          dec:
            "在平台上可进行闲置资产、报废资产、拆机配件的回收、撮合交易、共享，平台上的设备可进行一键转卖、回收；闲置工程师亦可共享。降低服务商的租借、购买成本。",
        },
        {
          title: "一机一码报修",
          dec:
            "每台设备提供二维码，管理设备，可进行扫码报修、在线报修、电话报修多种报修方式进行报修。",
        },
        {
          title: "办公物资SAAS与固定资产管理",
          dec:
            "业务全生命周期、全业务节点的管理，包含维保、租赁、全包业务的配件、耗材、报价；固定资产管理（包含折旧、成本分摊、售价等）；统计各订单历史数据，产出设备、工程师的成本/收益/业绩报表。",
        },
        {
          title: "业务/管理与报表一体化",
          dec:
            "办公设备的本地化安装、维修、维保、配件更换、耗材补充服务，包括极简报修工具与调度平台；租赁客户的风险评估信息。",
        },
        {
          title: "全国性维修/保养/租赁服务",
          dec:
            "提供极简报修工具、租赁/全包合同管理、维修/保养/租赁/全包业务管理、IOT、数据统计与报表。",
        },
        {
          title: "数据产生/储存/应用/指导优化的数据闭环",
          dec:
            "从业务的数据产生，到数据落地，再到数据统计、报表的数据运用。形成办公物资数据的产生、落地与运用的数据闭环。",
        },
      ],
    };
  },
  methods: {
    mouseOver(index) {
      this.active = this.active.map((ele, ind) => {
        if (ind == index) {
          this.imgList[index] = this.hoverList[index];
        }
        return ele;
      });
    },

    mouseOut(index) {
      this.active = this.active.map((ele, ind) => {
        if (ind == index) {
          this.imgList[index] = this.recordList[index];
        }
        return ele;
      });
    },
  },
};
</script>

<style scoped>
.features {
  width: 100%;
  margin: 0 auto;
}
.top {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
}
.title {
  font-weight: normal;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #333333;
}
.dec {
  font-weight: normal;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
  color: #666666;
}
.box1 {
  width: 1200px;
  height: 720px;
  margin: 0 auto;
  margin-bottom: 120px;
  display: flex;
  flex-wrap: wrap;
}
.item {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.img {
  width: 70px;
  height: 70px;
  margin: 40px 115px;
}
.item-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin: 0 auto;
}
.item-dec {
  margin: 24px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
}

/deep/ .el-card {
  width: 298px;
  height: 360px;
  border-radius: 8px;
}
/deep/ .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1), -6px 0px 10px rgba(0, 0, 0, 0.1);
}
</style>
