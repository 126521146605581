<template>
  <div>
    <img class="banner" src="../../assets/serve/serve-banner.png" alt="" />
    <serve />
    <features />
    <product />
    <highlights />
    <process />
    <platform />
    <partner />
    <teamwork />
<!--    <apply />-->
  </div>
</template>

<script>
import serve from "@/views/serve/serve";
import features from "@/views/serve/features";
import product from "@/views/serve/product";
import highlights from "@/views/serve/highlights";
import process from "@/views/serve/process";
import platform from "@/views/serve/platform";
import partner from "@/views/serve/partner";
import teamwork from "@/views/serve/teamwork";
import apply from "@/views/serve/apply";
export default {
  components: {
    serve,
    features,
    product,
    highlights,
    process,
    platform,
    partner,
    teamwork,
    apply,
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 500px;
  margin-bottom: 120px;
}
</style>
