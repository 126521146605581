<template>
  <div class="header">
    <!--    已弃用-->
    <div class="header-top">
      <div class="top-line">
        <div>欢迎来到企修通</div>
        <div class="line-right">
          <div>服务热线：023-88236608</div>
          <div class="line"></div>
          <div>工作时间：9:00-18:00</div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="top-line">
        <img style="width: 166px;height: 48px" src="@/assets/img/logo.png" alt="logo" class="logo comimg"/>
        <!--        <el-button type="text" @click="goback">返回</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goback () {
      // window.location = "https://matefix.cn/";
    },
  },
};
</script>

<style scoped>
  .header {
    width: 1920px;
    padding: 0;
  }

  .header-top {
    width: 100%;
    height: 40px;
    background-color: #fff;
  }

  .top-line {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .line-right {
    display: flex;
    font-size: 14px;
  }

  .line {
    margin: 0 16px;
    width: 1px;
    background: #ff8b03;
  }

  .header-bottom {
    width: 100%;
    height: 60px;
    background: #252525;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .el-button--text {
    font-size: 18px;
    color: #fff;
  }
</style>
