<template>
  <div>
    <img class="banner" src="../../assets/client/client-banner.png" alt="" />
    <client />
    <features />
    <product />
    <highlights />
    <process />
    <platform />
    <teamwork />
<!--    <apply />-->
  </div>
</template>
<script>
import client from "@/views/client/client";
import features from "@/views/client/features";
import product from "@/views/client/product";
import highlights from "@/views/client/highlights";
import process from "@/views/client/process";
import platform from "@/views/client/platform";
import teamwork from "@/views/client/teamwork";
import apply from "@/views/client/apply";
export default {
  components: {
    client,
    features,
    product,
    highlights,
    process,
    platform,
    teamwork,
    apply,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 500px;
}
</style>
