<template>
  <div style="width: 100vw;height: 100vh" class="res">
    <img class="comimg" style="width: 100%;height: 100%" src="@/assets/use/usebg.png">
    <div class="abs user-view">
      <img style="width: 17.5vw;height: 15.56vh;" src="@/assets/use/uselogo.png">
      <div class="usercon cflex">
        <div class="user-tit">马上试用企修通</div>
        <div class="user-sub">感谢您留下宝贵的信息，稍后我们会有工作人员联系您</div>
        <el-input style="width: 20.8vw;" v-model="form.companyName" placeholder="公司名称"></el-input>
        <el-input style="width: 20.8vw;margin: 20px 0" v-model="form.contactorName" placeholder="联系人"></el-input>
        <el-input style="width: 20.8vw;" v-model="form.contactorPhone" placeholder="联系人电话"></el-input>
        <div class="user-submit cflex" @click="onSubmit">提交</div>
      </div>
      <div class="user-buttom">渝ICP备20000612号-1 渝公网安备 50010302003058号</div>
    </div>
  </div>

</template>

<script>
export default {
  name: "free",
  data () {
    return {
      form: {
        companyName: '',
        contactorName: '',
        contactorPhone: ''
      },
    }
  },
  methods: {
    onSubmit () {
      let that = this
      if (this.form.companyName.length == 0) {
        this.$message.warning('请输入公司名称')
        return
      }
      if (this.form.contactorName.length == 0) {
        this.$message.warning('请输入联系人')
        return
      }
      if (this.form.contactorPhone.length == 0) {
        this.$message.warning('请输入联系人电话')
        return
      }
      this.$api.savemessage(that.form).then((res) => {
        if (res.code == 200) {
          this.$router.push({path: "/success"});
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  }
}
</script>

<style scoped>
  .user-view {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .usercon {
    width: 35.7vw;
    height: 55.27vh;
    box-sizing: border-box;
    background: linear-gradient(180deg, #F9FCFF 0%, #D4E2FF 100%);
    box-shadow: 0px 0px 20px 1px rgba(31, 95, 255, 0.1);
    border-radius: 18px 18px 18px 18px;
    border: 2px solid #FFFFFF;
    flex-direction: column;
  }

  .user-tit {
    font-size: 32px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 36px;
  }

  .user-sub {
    font-size: 16px;
    font-weight: 400;
    color: #717477;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 42px;
  }

  .user-submit {
    width: 20.8vw;
    height: 52px;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border-radius: 26px 26px 26px 26px;
    color: white;
    margin-top: 40px;
    cursor: pointer;
  }

  .user-buttom {
    font-size: 12px;
    font-weight: 400;
    color: #717477;
    line-height: 18px;
    text-align: center;
    margin-top: 12vh;
  }
</style>
